import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import UploadImage from "../utils/UploadImage";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addCoupon,updateCoupon,getCoupon } from "../../actions/homeAction";

class CouponAdd extends React.Component {
  state = {
    "name": "",
    "couponCode": "",
    "description": "",
    "discountType": "",
    "discountValue": "",
    "minPurchaseAmount": "",
    "validFrom": "",
    "validTo": "",
    "maxUsers": "",
    "perUserLimit": "",
    "status": true,
    "display": true
  };
componentDidMount(){
  this.props.getCoupon();
}
  componentWillReceiveProps(nextProps) {
    
    if (this.props.coupon_id) {
      const matchItem = nextProps?.home?.couponList?.filter(itm => itm.id === this.props?.coupon_id);

      if (matchItem?.length) {
        const itm = matchItem[0];
        this.setState({
          ...itm
        })
      }
    }
  }
  onSaveData = () => {
    var d = new Date(this.state.validFrom).getTime();
    var d1= new Date(this.state.validTo).getTime();
    console.log(d)
    const data = {
      "name": this.state.name,
      "couponCode": this.state.couponCode,
      "description": this.state.description,
      "discountType": this.state.discountType,
      "discountValue": this.state.discountValue,
      "minPurchaseAmount": this.state.minPurchaseAmount,
      "validFrom": d,
      "validTo":d1,
      "maxUsers": this.state.maxUsers,
      "perUserLimit": this.state.perUserLimit,
      "status": this.state.status,
      "display": this.state.display
    };
    this.props.addCoupon(data);
  };
  updateCouponData =()=>{
    var d = new Date(this.state.validFrom).getTime();
    var d1= new Date(this.state.validTo).getTime();
    console.log(d)
    const data = {
      "couponId":this.props.coupon_id,
      "name": this.state.name,
      "couponCode": this.state.couponCode,
      "description": this.state.description,
      "discountType": this.state.discountType,
      "discountValue": this.state.discountValue,
      "minPurchaseAmount": this.state.minPurchaseAmount,
      "validFrom": d,
      "validTo":d1,
      "maxUsers": this.state.maxUsers,
      "perUserLimit": this.state.perUserLimit,
      "status": this.state.status,
      "display": this.state.display
    };
    this.props.updateCoupon(data);
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Coupon Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      placeholder="Coupon Name"
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Coupon Code
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="couponCode"
                      id="code"
                      placeholder="Coupon Code"
                      onChange={this.handleChange}
                      value={this.state.couponCode}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Description
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      id="code"
                      placeholder="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Discount Type
                  </label>
                  <div className="col-sm-9">
                    <textarea
                      type="text"
                      className="form-control"
                      name="discountType"
                      id="code"
                      placeholder="discount Type"
                      onChange={this.handleChange}
                      value={this.state.discountType}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Discount Value
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="discountValue"
                      id="value"
                      placeholder="discount Value"
                      onChange={this.handleChange}
                      value={this.state.discountValue}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Minimum Purchase Amount
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="minPurchaseAmount"
                      id="value"
                      placeholder="amount"
                      onChange={this.handleChange}
                      value={this.state.minPurchaseAmount}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  valid From
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      className="form-control"
                      name="validFrom"
                      id="value"
                      placeholder="valid From"
                      onChange={this.handleChange}
                      value={this.state.validFrom}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  valid To
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      className="form-control"
                      name="validTo"
                      id="value"
                      placeholder="validTo"
                      onChange={this.handleChange}
                      value={this.state.validTo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  maximum Users 
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="maxUsers"
                      id="value"
                      placeholder="max Users"
                      onChange={this.handleChange}
                      value={this.state.maxUsers}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Per UserLimit
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="perUserLimit"
                      id="value"
                      placeholder="perUser Limit"
                      onChange={this.handleChange}
                      value={this.state.perUserLimit}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Display Image</label>
                  <UploadImage id={"customFile"} uploadGalleryImage={this.uploadImage}></UploadImage>
                  <label class="custom-file-label" for="customFile">
                    {this.state.label}

                  </label>
                </div>
              </div> */}

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Status</label>
                  <div className="col-sm-9">
                    <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                      <option value="true" name="active">Active</option>
                      <option value="false" name="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Display</label>
                  <div className="col-sm-9">
                    <select name="display" className="form-control" value={this.state.display} onChange={this.handleChange}>
                      <option value="true" name="active">Active</option>
                      <option value="false" name="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div id="id_image_section_label" className="pt-2">
                  {this.state.imageurl ?
                    this.state.imageurl !== undefined
                      ? (
                        <img
                          src={`http://204.48.26.50:8060/${this.state.imageurl}`}
                          alt=""
                          className="img-100"
                          onError={(e) => {
                            e.target.src = "";
                          }}
                        />
                      ) : (
                        ""

                      ) : (
                      ""
                    )}
                </div>
              </div> */}
            </div>

            <div className="card-footer">
              <div className="row float-right p-3">
              {this.props.coupon_id ? (
              this.state.isLoading ? (
                <button className="btn btn-grd-disabled mr-2" disabled>
                  Saving...!
                </button>
              ) : (
                <button
                  onClick={this.updateCouponData}
                  className="btn btn-grd-disabled mr-2 "
                >
                  Update
                </button>
              )
            ) : this.props.isLoading ? (
              <button className="btn btn-grd-disabled mr-2" disabled>
                Saving...!
              </button>
            ) : (
              <button
                onClick={this.onSaveData}
                className="btn btn-grd-disabled mr-2"
              >
                <i className="icofont icofont-save"></i> Save
              </button>
            )}
            <Link to={"/coupon"} className="btn btn-outline-dark">
              Cancel
            </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CouponAdd.propsTypes = {
  addCoupon: PropTypes.func.isRequired,
  updateCoupon:PropTypes.func.isRequired,
  getCoupon:PropTypes.func.isRequired,
  home: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, {addCoupon,updateCoupon,getCoupon})(
  CouponAdd
);
