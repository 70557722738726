import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from 'axios'
import Constant from "../../Constant.js";
import ReactQuill from "react-quill";

class AboutUsAdd extends React.Component {
  state = {
    name: "About Us",
    languageCode: "",
    value: ""
  };

  componentDidMount() {
    this.AboutUs();
  }
  AboutUs = async () => {
    var that = this;
    var data = {
      name: "About Us",
      languageCode: "",
    }
    await axios.post(Constant.getAPI() + `/config/getConfig`, data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('loged_In_auth')}`
      }
    }).then(res => {
      that.setState(res.data?.data);
    }).catch((err) => {
      this.setState({ isData: false })
      if (err?.response?.status === 401) {
        Constant.getAuthLogout();
      }
      else {
        Swal.fire({
          title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      }
    });
  }

  handleChange = (value) => {
    this.setState({ value: value, value_ar: value });
  };
  componentDidUpdate(prevProps) {
    if (prevProps.language_id !== this.props.language_id) {
      this.AboutUs();
    }
  }
  onSave = () => {
    var data;
    if (this.props.language_id === "en") {
      data = {
        "value": this.state.value,
        "name": this.state.name,
      }
    }
    else {
      data = {
        "value": this.state.value_ar,
        "name": this.state.name,
        "languageCode": this.props.language_id
      }
    }
    this.setState({ isData: true })

    debugger
    axios
      .post(Constant.getAPI() + `/config/editConfig/${this.state.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.getItem("loged_In_auth")
        },
      })
      .then((res) => {
        this.setState({ isData: false })
        if (res.status) {
          Swal.fire({
            title: "AboutUs Updated Successfully!",
            icon: "success",
            text: "",
            type: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      }).catch((err) => {
        this.setState({ isData: false })
        if (err?.response?.status === 401) {
          Constant.getAuthLogout();
        }
        else {
          Swal.fire({
            title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
            icon: "error",
            text: "",
            type: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      });
  };
  render() {
    return (
      <div className="row">
        <div className="col-12 grid-margin">
          {this.state.isloading ? (
            ""
          ) : (

            <div className="">
              <div className="">
                {this.props.language_id === "en" ?
                  <ReactQuill
                    value={this.state.value}
                    onChange={this.handleChange}
                  /> :
                  <ReactQuill
                    value={this.state.value_ar}
                    onChange={this.handleChange}
                  />
                }
                <div className="row float-right p-3">
                  {this.state.isSaving ? (
                    <button className="btn btn-grd-disabled mr-2" disabled>
                      Saving...!
                    </button>
                  ) : (
                    <button
                      onClick={this.onSave}
                      className="btn btn-grd-disabled mr-2"
                    >
                      Save
                    </button>
                  )}

                  <Link to={"/refund_policy"} className="btn btn-outline-dark">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AboutUsAdd;
