import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {  updateState, 
          getState,
          addState,
          getCountry, } from "../../../actions/homeAction"

class StateAdd extends React.Component {
  state = {
    status:true,
    name:"",
    countryId:"",
  }
  
  componentDidMount(){
    if(this.state.state_id !== null)
    {
      this.getStateDetails(this.state.state_id);
    }
    this.props.getState();
    this.props.getCountry();
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      state:nextProps.home?.state,
      country:nextProps.home?.country
    });
   console.log(this.state.country)
  };

   
  componentDidUpdate(prevProps, prevState) {
    if (this.props.language_id !== undefined) {
      if (prevProps.state_id !== this.props.state_id) {
        this.setState({ state_id: this.props.state_id });
        this.getStateDetails();
      }
      if (this.props.language_id !== prevProps.language_id) {
        if (this.props.state_id !== undefined) {
          this.getStateDetails();
        }
        
      }
      if (this.state.country_id !== prevState.country_id) {
       
      }
    }
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getStateDetails = () => {
    var state=this.state.state
  
    if(state !== undefined){
      
    for(let i=0;i<state.length;i++)
    {
      if(state[i].id == this.props.state_id){
         this.fillInputs(state[i])
         console.log(state[i])
      }
    }}
  };

  updateStateData = () => {
    var data={
      StateId:this.props.state_id,
      name:this.state.name,
      status:this.state.status,
      // stateCode:this.state.state_short_code,
      // pinCode:this.state.pincode, 
      countryId:this.state.countryId,
      // languageCode:this.props.language_id
    }
   this.props.updateState(data)
 };

  addState = () => {
    var data={
      name:this.state.name,
      // stateCode:this.state.state_short_code,
      // pinCode:this.state.pincode, 
      countryId:this.state.countryId,
      status:this.state.status,
      // languageCode:this.props.language_id
    }
   this.props.addState(data)
  };
  
  fillInputs(state){
   debugger
    this.setState({
      name:state.name,
      status:state.status,
      // state_short_code:state.stateCode,
      // pincode:state.pinCode,
      countryId:state.CountryId,
    })
  };

  
  onSaveData = () => {
    var that = this;
    // Swal.fire("Added !", "State has been Added", "success");
    if (that.props.state_id !== undefined) {
      
        that.updateStateData();
      }
     else {
      that.addState();
    }
  };
  // getCountryList = () => {
    
  // };
  // getGovernorateList = () => {
    
  // };
  render() {
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">State Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="State Name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">state Code</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="state_short_code"
                    id="state_short_code"
                    placeholder="state Code"
                    onChange={this.handleChange}
                    defaultValue={this.state.state_short_code}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Pincode</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="pincode"
                    id="pincode"
                    placeholder="Pincode"
                    onChange={this.handleChange}
                    value={this.state.pincode}
                  />
                </div>
              </div>
            </div> */}
            <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Status</label>
                  <div className="col-sm-9">
                    <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                      <option value="true" name="active">Active</option>
                      <option value="false" name="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
{/* 
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Select Country</label>
                <div className="col-sm-9">
                  <select name="country_id" className="form-control form-control-inverse" onChange={this.handleChange} value={this.state.country_id}>
                    <option value="0">Select Country</option>
                    {
                      this.state.country_data !== undefined && this.state.country_data !== null && this.state.country_data !== [] && this.state.country_data.length > 0
                        ?
                        this.state.country_data.map(country_list =>
                          <option value={country_list.id}>{country_list.name}</option>
                        )
                        :
                        null
                    }
                  </select>
                </div>
              </div>
            </div> */}
            
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Select country
                </label>
                <div className="col-sm-9">
                  <select
                    name="countryId"
                    className="form-control form-control-inverse"
                    onChange={this.handleChange}
                    value={this.state.countryId}
                  >
                    <option value="">Select country</option>
                    {this.state.country !== undefined &&
                    this.state.country !== null &&
                    this.state.country !== [] &&
                    this.state.country.length > 0
                      ? this.state.country.map((country) => (
                          <option
                            value={country.id}
                            key={country.id}
                          >
                            {country.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                  <select
                    name="status"
                    className="form-control"
                    value={this.state.status}
                    onChange={this.handleChange}
                  >
                    <option value="AVAILABLE" name="AVAILABLE">
                      AVAILABLE
                    </option>
                    <option value="NOT_AVAILABLE" name="NOT_AVAILABLE">
                      NOT AVAILABLE
                    </option>
                    <option value="BUSY" name="BUSY">
                      BUSY
                    </option>
                  </select>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Delivery Charge</label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="deliveryCharge"
                    id="deliveryCharge"
                    value={this.state.deliveryCharge}
                    placeholder="Delivery Charge"
                    aria-label="DeliveryCharge"
                    aria-describedby="basic-addon1" />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="form-group ">
                <div className="input-group mb-3">
                  <div className="input-group-addon">
                    <span className="input-group-text" id="basic-addon1">Latitude</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    onChange={this.handleChange}
                    name="lat"
                    id="lat"
                    value={this.state.lat}
                    placeholder="Latitude"
                    aria-label="Username"
                    aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <div className="input-group mb-3">
                  <div className="input-group-addon">
                    <span className="input-group-text" id="basic-addon1">Longitude</span>
                  </div>
                  <input
                    type="number"
                    onChange={this.handleChange}
                    name="lng"
                    id="lng"
                    placeholder="Longitude"
                    value={this.state.lng}
                    className="form-control"
                    aria-label="Longitude" aria-describedby="basic-addon1" />
                </div>
              </div>
            </div>
          */}
          </div>
          <div className="row float-right p-3">
            {this.state.isSaving ? (
              <button className="btn btn-grd-disabled mr-2" disabled>
                Saving...!
              </button>
            ) : (
              <button
                onClick={this.onSaveData}
                className="btn btn-grd-disabled mr-2"
              >
                <i className="icofont icofont-save"></i> Save
              </button>
            )}
            <Link to={"/state"} className="btn btn-outline-dark">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

StateAdd.propsTypes = {
 
  getCountry:PropTypes.func.isRequired,
  getState:PropTypes.func.isRequired, 
  addState:PropTypes.func.isRequired,
  updateState:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state
});

export default connect(mapStateToProps, {

  getCountry, 
  getState,
  addState,
  updateState
  
  })(StateAdd);

