import React from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Recaptcha from "react-recaptcha";
import { Link } from "react-router-dom";
import { loginUser } from "../actions/homeAction";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "admin",
      loading: false,
      isVerifed: false,
      openReset: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.onResolved = this.onResolved.bind(this);
  }

  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onResolved = () => {
    this.setState({
      isVerifed: true,
    });
  };

  verifyCallback = (response) => {
    // Here you will get the final recaptchaToken!!!
    console.log(response, "<= your recaptcha token");
  };

  recaptchaLoaded = () => {
    // you will get a new token in verifyCallback
    // this.recaptcha.execute();
    console.log("your recaptcha token");
  };

  // onLogin = (event) => {
  //   event.preventDefault();
  //   var that = this;
  //   var data = new URLSearchParams();
  //   this.setState({ isSaving: true });
  //   var email = that.state.username;
  //   var password = that.state.password;
  //   console.log("here", email, password);
  //   if (this.state.role !== "seller") {
  //     this.props.loginUser(data);
  //     localStorage.setItem("loged_In", true);
  //     localStorage.setItem(
  //       "loged_In_auth",
  //       "eyJhbGciOiJIUzI1NiJ9.YTUwYWI1M2EtZDZlNC00NjAxLWE0ZmQtODU4MGQzZjgwMTRh.iHbL208m6TqZHNQZZ-a7rk25TsuLhaEyMtIiopfUYhk"
  //     );
  //     localStorage.setItem("ad_name", "Super Admin");
  //     localStorage.setItem("ad_email", "admin@admin.com");
  //     Swal.fire({
  //       title: "Login Successfully ",
  //       // position: 'top-end',
  //       icon: "success",
  //       text: "",
  //       // type: "success",
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Ok",
  //     });
  //     window.location.reload();
  //   } else {
  //     Swal.fire({
  //       title: "Incorrect Credentials",
  //       // position: 'top-end',
  //       icon: "error",
  //       text: "",
  //       // type: "success",
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Ok",
  //     });
  //   }
  //   return false;
  // };

  onLogin = (e) => {
    e.preventDefault();

    var userData = {
      role: this.state.role,
      email: this.state.email,
      password: this.state.password,
    };

    this.setState({
      loading: true,
    });

    console.log(userData);
    this.props.loginUser(userData);
  };

  render() {
    console.log("ROLE", this.state.role);
    return (
      // <section className="login-block">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-sm-12">
      //         <form className="md-float-material form-material">
      //           <div className="text-center">
      //             <h1 className="display-4 text-white"> ZEED WAREHOUSE</h1>
      //           </div>

      //           <div className="auth-box card">
      //             <div className="card-block">
      //               <div className="row m-b-20">
      //                 <div className="col-md-12">
      //                   <div className="d-flex justify-content-center">
      //                     <img
      //                       style={{ height: "100px", width: "100px" }}
      //                       src="./logotest.png"
      //                       alt="logo"
      //                     />
      //                   </div>
      //                   <h3 className="text-center">Sign In</h3>
      //                 </div>
      //               </div>
      //               {/* <div className="form-group form-primary">
      //                 <select
      //                   name="role"
      //                   className="form-control"
      //                   onChange={this.onInputChange}
      //                   value={this.state.role}
      //                 >
      //                   <option value="admin">Admin</option>
      //                   <option value="seller">Vendor</option>
      //                 </select>
      //               </div> */}
      //               <div className="form-group form-primary">
      //                 <input
      //                   type="text"
      //                   name="email"
      //                   className="form-control"
      //                   required=""
      //                   placeholder="Email"
      //                   onChange={this.onInputChange}
      //                 />
      //                 <span className="form-bar"></span>
      //               </div>
      //               <div className="form-group form-primary">
      //                 <input
      //                   type="password"
      //                   name="password"
      //                   className="form-control"
      //                   required=""
      //                   placeholder="Password"
      //                   onChange={this.onInputChange}
      //                 />
      //                 <span className="form-bar"></span>
      //               </div>
      //               <div className="row m-t-30">
      //                 <div className="col-md-12">
      //                   <button
      //                     type="button"
      //                     onClick={this.onLogin}
      //                     className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20"
      //                   >
      //                     {this.state.loading ? "Loading .." : "Sign in"}
      //                   </button>
      //                   <div className="d-flex justify-content-center">
      //                     {/* <Recaptcha
      //                       ref={(ref) => (this.recaptcha = ref)}
      //                       sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
      //                       onloadCallback={this.recaptchaLoaded.bind(this)}
      //                       onResolved={this.onResolved.bind(this)}
      //                       onLoaded={this.recaptchaLoaded.bind(this)}
      //                     /> */}
      //                   </div>
      //                 </div>
      //               </div>
      //             </div>
      //           </div>
      //         </form>
      //       </div>
      //     </div>
      //   </div>
      // </section>
      <section>
        <div className="accountbg"></div>
        <div className="wrapper-page">
          <div className="card">
            <div className="card-body">
              <h3 className="text-center m-0">
                <Link to="/" className="logo logo-admin">
                  <img
                    src="assets/images/favicon-32x32.png"
                    height="30"
                    alt="logo"
                  />
                </Link>
              </h3>

              <div className="p-3">
                <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
                <p className="text-muted text-center">
                  Sign in to continue to Zeed Warehouse.
                </p>

                <form className="form-horizontal m-t-30" action="/">
                  <div className="form-group">
                    <label for="username">Email</label>
                    <input
                      type="email"
                      name="email"
                      onChange={this.onInputChange}
                      className="form-control"
                      id="username"
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="form-group">
                    <label for="userpassword">Password</label>
                    <input
                      type="password"
                      name="password"
                      onChange={this.onInputChange}
                      className="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                    />
                  </div>

                  <div className="form-group row m-t-20">
                    <div className="col-sm-6">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          for="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 text-right">
                      <button
                        onClick={this.onLogin}
                        className="btn btn-primary w-md waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20">
                      <Link to="pages_recoverpw" className="text-muted">
                        <i className="mdi mdi-lock"></i> Forgot your password?
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="m-t-40 text-center">
            <p className="text-white">
              Don't have an account ?{" "}
              <Link
                to="pages_register"
                className="font-500 font-14 text-white font-secondary"
              >
                {" "}
                Signup Now{" "}
              </Link>{" "}
            </p>
            <p className="text-white">
              © {new Date().getFullYear() - 1} - {new Date().getFullYear()} Zeed
            </p>
          </div>
        </div>
      </section>
    );
  }
}

Login.propsTypes = {
  loginUser: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, { loginUser })(Login);
