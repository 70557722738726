import React from "react";
import Constant from "../Constant";
import Swal from "sweetalert2";
import axios from "axios";
import { TbTruckDelivery } from "react-icons/tb";
import { FaTruckLoading } from "react-icons/fa";
import { } from "react-icons/ai";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { AiFillShopping } from "react-icons/ai";



var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};
class AdminDashboard extends React.Component {
  state = {
    DashBoard:[]
}
  componentWillMount() {
    var DateStamp = new Date().getTime()
    var data = {
      startDate:DateStamp,
      endDate:DateStamp
    }
    this.dashbord(data);
  }

  dashbord = async (data) => {
    var that = this;
    await axios.post(Constant.getAPI() + "/seller/dashboard",data, config
    ).then(res => {
        that.setState({DashBoard:res?.data?.data});
    }).catch((err) => {
        Swal.fire({
          title: err?.response?.data?.data?.details?.[0]?.message || err?.response?.data?.message,
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
    });
  }
  render() {
    console.log("DASH", this?.state?.DashBoard);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Dashboard</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              {/* <h3 className="text-primary"></h3> */}
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-green f-w-600">{this.state.DashBoard?.totalSales}{" "}KWD</h4>
                          <h6 className="text-muted m-b-0">Total Sales Today</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-coins f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-green"></div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-yellow f-w-600">{this.state.DashBoard?.orderCount}</h4>
                          <h6 className="text-muted m-b-0">Total Orders Today</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="icofont icofont-shopping-cart f-28"></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-yellow"></div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-danger f-w-600">{this.state.DashBoard?.orderOnTheWayToBuyersCount}</h4>
                          <h6 className="text-muted m-b-0">Orders On route to buyers</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather icon-truck f-28"><TbTruckDelivery/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-pink"></div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-danger f-w-600">{this.state.DashBoard?.orderOnRouteToWarehouseCount}</h4>
                          <h6 className="text-muted m-b-0">Orders On route to warehouse</h6>
                        </div>
                        <div className="col-4 text-right">
                        <i className="feather icon-truck f-28"><TbTruckDelivery/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue"></div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-danger f-w-600">{this.state.DashBoard?.orderPendingPickupFromStoreCount}</h4>
                          <h6 className="text-muted m-b-0">Orders pending pickup from store</h6>
                        </div>
                        <div className="col-4 text-right">
                        <i className="feather icon-truck f-28"><FaTruckLoading/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-orenge"></div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-danger f-w-600">{this.state.DashBoard?.orderPendingPickupFromWarehouseCount}</h4>
                          <h6 className="text-muted m-b-0">Orders pending pickup from warehouse</h6>
                        </div>
                        <div className="col-4 text-right">
                        <i className="feather icon-truck f-28"><FaTruckLoading/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue"></div>
                  </div>
                </div>

               <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-orenge f-w-600">{this.state.DashBoard?.activeAuctionCount}</h4>
                          <h6 className="text-muted m-b-0">Total Active Auction</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather  f-28"><HiOutlineStatusOnline/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-green"></div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6">
                  <div className="card">
                    <div className="card-block">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4 className="text-c-blue f-w-600">{this.state.DashBoard?.productCount}</h4>
                          <h6 className="text-muted m-b-0">Total New Products</h6>
                        </div>
                        <div className="col-4 text-right">
                          <i className="feather f-28"><AiFillShopping/></i>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer bg-c-blue"></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}

export default AdminDashboard;
