import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getWarehouseOrderDetails, getOrderStatus, getDriverList } from '../../actions/homeAction'
import Swal from "sweetalert2";
import Constant from "../../Constant";
import axios from 'axios';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

export class OrderDetailsByCutomer extends Component {

    state = {
        orderStatusList:[],
        orderDetails:{},
        orderStatus:'',
        subTotal:0,
        selectDriver:''
    }

    componentWillMount() {
    
          var data = {
            id:this.props.match.params.id
        }
        this.props.getWarehouseOrderDetails(data);
     
    }
    
    componentDidMount(){
        this.props.getOrderStatus()
            var data = {
                isActive:true,
                startRange:0,
                // count:10
            }
        this.props.getDriverList(data)
    }
      
    componentWillReceiveProps(nextProps) {
      this.setState({ orderDetails: nextProps?.home?.orderDetailsByCustomers })
      this.setState({ orderStatusList: nextProps?.home?.orderStatus})
      this.setState({ driverList: nextProps?.home?.driverList})
      this.setState({orderStatus:nextProps?.home?.orderDetailsByCustomers?.deliveryStatusId})
      this.setState({selectDriver:nextProps?.home?.orderDetailsByCustomers?.driverId})
    }


      onSaveData = ()=> {
        if(localStorage.getItem('role')=='admin'){
          Swal.fire({
            title: "You Are Not Authorised",
            type: "warning",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
          return false
        }
        var Ids = this?.state?.orderDetails?.orderDetails.map(data=>data.orderId)
        var data = {
          orderIds:Ids,
          deliveryStatusId:this?.state?.orderStatus,
        }
        axios.post(Constant.getAPI()+'/warehouse/changeDeliveryStatus',data, config).then(res=>{
         
          if(res.data.status){
            Swal.fire({
              title: "Status has been Changed",
              type: "success",
              imageUrl: "./assets/zeed-logo.png",
              imageHeight: 70,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }else{
            Swal.fire({
              title: "You Are Not Authorised",
              type: "error",
              imageUrl: "./assets/zeed-logo.png",
              imageHeight: 70,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        }).catch(err=>{
          console.log('err',err?.response?.data?.error);
          if(err){
            Swal.fire({
              title: err?.response?.data?.error,
              type: "error",
              imageUrl: "./assets/zeed-logo.png",
              imageHeight: 70,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        })
      }

      onDriverAssign = ()=> {
        var data = {
          driverId:this.state.selectDriver,
          orderMasterId:this.props.match.params.id,
        }
        axios.post(Constant.getAPI()+'/delivery/assignDriver',data, config).then(res=>{
         
          if(res.data.status){
            Swal.fire({
              title: "Driver has been Assigned",
              type: "success",
              imageUrl: "./assets/images/test.png",
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }else{
            Swal.fire({
              title: "You Are Not Authorised",
              type: "error",
              imageUrl: "./assets/images/test.png",
              imageHeight: 100,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        }).catch(err=>{
          console.log('err',err.response.data.error);
          if(err){
            Swal.fire({
              title: err.response.data.error,
              type: "error",
              imageUrl: "./assets/images/test.png",
              imageHeight: 100,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        })
      }
  
    

    render() {
        console.log('orderDetails', this.state.orderDetails);
        var OrderDetails = this?.state?.orderDetails
        return (
          this.state.orderDetails!==undefined && this.state.orderDetails!==null?<>
            <div className="pcoded-inner-content" >
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                        <div className='assignDriverModule'>
                            <div>
                                <label htmlFor="assignDriver">Assign Driver</label>
                                <select onChange={(e)=>this.setState({selectDriver:e.target.value})} value={this.state.selectDriver} name="" id="assignDriver">
                                    <option value="">- Select Driver -</option>
                                    {this.state.driverList!==undefined?this.state.driverList.map((driver,index)=>(
                                        <option key={index} value={driver.id}>{driver.firstName+" "+driver.lastName}</option>
                                    )):null}
                                </select>
                            </div>
                            <button onClick={this.onDriverAssign} className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger">Assign</button>
                        </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5>WAREHOUSE ORDER DETAILS</h5>
                        <Link to="/orders"
                          className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13">Back
                        </Link>
                      </div>
                      <div className="card-block">
                          <div className="row">
                              <div style={{marginTop:'2rem'}} className="col-md-12 row" ref={el => (this.componentRef = el)}>
                              <div className="col-sm-12 USER_DETAILS">
                                <div>
                                    <span><b>Customer Name :</b> {OrderDetails?.cusotmerDetails?.firstName+' '+OrderDetails?.cusotmerDetails?.lastName}</span>
                                    <span><b>E-Mail :</b> {OrderDetails?.cusotmerDetails?.email}</span>
                                    <span><b>Mobile No. :</b>  {OrderDetails?.cusotmerDetails?.mobileNumber}</span>

                                    <span><b>Address Type :</b> {OrderDetails?.addressDetails?.label}</span>
                                    <span><b>Address Line :</b> {OrderDetails?.addressDetails?.address_line_1}, {OrderDetails?.addressDetails?.address_line_2}</span>
                                    <span><b>Address Type :</b> {OrderDetails?.addressDetails?.label}</span>
                                    <span><b>Avenue :</b> {OrderDetails?.addressDetails?.avenue}</span>
                                    <span><b>Block :</b> {OrderDetails?.addressDetails?.block}</span>
                                    <span><b>Flat :</b> {OrderDetails?.addressDetails?.flat}</span>
                                    <span><b>Floor :</b> {OrderDetails?.addressDetails?.floor}</span>
                                    <span><b>Area :</b> {OrderDetails?.addressDetails?.City?.name}</span>
                                </div>
                                {/* <div>
                                    <span>
                                        <b>INVOICE NUMBER :</b> <b> </b>
                                    </span>
                                    <span>
                                        <b>Payment Type :</b> 
                                    </span>
                                    <span>
                                        <b>Refund Status :</b> 
                                    </span>
                                    <span>
                                        <b>Payment Status :</b>
                                         {true=='Pending'?
                                         <b style={{marginLeft:'0.5rem',background:'#ffc93c',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}></b>
                                         :<b style={{marginLeft:'0.5rem',background:'#00bbf0',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}></b>}
                                    </span>
                                    <span>
                                        <b>Order Status :</b>
                                         {true=='Placed'?
                                         <b style={{marginLeft:'0.5rem',background:'#42b883',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}></b>
                                         :<b style={{marginLeft:'0.5rem',background:'#dc2f2f',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}></b>}
                                    </span>
                                    
                                </div> */}
                              </div>
                              
                             
                              <div className="col-sm-12">
                              <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Products</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Shop Name</th>
                                            <th scope="col">Payment Type</th>
                                            <th scope="col">Payment Status</th>
                                            <th scope="col">Refund Status</th>
                                            <th scope="col">Sub Total</th>
                                            <th scope="col">Delivery Charge</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Grand Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderDetails?.orderDetails!==undefined && OrderDetails?.orderDetails!==[]?OrderDetails?.orderDetails.map(data=>(
                                            <tr>
                                                <td><img height='40px' src={data?.products[0]?.Media[0]?.url} alt="" /></td>
                                                <td>{data?.products[0]?.title}</td>
                                                <td>{data?.products[0]?.shopName}</td>

                                                <td><>{data?.paymentType}</></td>
                                                <td>
                                                  {data?.paymentStatus=='Pending'?
                                         <b style={{marginLeft:'0.5rem',background:'#ffc93c',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{data?.paymentStatus}</b>
                                         :<b style={{marginLeft:'0.5rem',background:'#00bbf0',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{data?.paymentStatus}</b>}
                                                </td>
                                                <td><>{data?.refundStatus}</></td>

                                                <td><b>{data?.subTotal} KWD</b></td>
                                                <td><b>{data?.deliveryCharge} KWD</b></td>
                                                <td><b>{data?.discountAmount} KWD</b></td>
                                                <td><b>{data?.grandTotal} KWD</b></td>
                                            </tr>
                                        )):null}
                                    
                                    </tbody>
                                </table>
                              </div>
                               <div className="col-sm-8 OrdrStatus">
                                {/* <h2>Order Status</h2>
                                {!localStorage.getItem('role')=="admin"?null: <select 
                                  name="orderStatus" 
                                  value={this.state.orderStatus}
                                  id=""
                                  onChange={(e)=>this.setState({orderStatus:e.target.value})}
                                  >
                                   <option value=""> - Select Status - </option>
                                   {this.state.orderStatusList!==undefined?this.state.orderStatusList.map(data=>(
                                     <option value={data.id}>{data.displayStatus}</option>
                                   )):null}
                                 </select>} */}
                              </div>           
                              <div className="col-sm-4 TOTAL">
                                      <div>

                                      </div>
                                      <div>
                                        <span>
                                            <b>Sub Total :</b> <b>{OrderDetails?.paymentDetails?.subTotal} KWD</b>
                                        </span>
                                        <span>
                                            <b>Delivery Charge :</b> <b> {OrderDetails?.paymentDetails?.deliveryCharge} KWD</b>
                                        </span>

                                        <span>
                                            <b>Discount :</b> <b> {OrderDetails?.paymentDetails?.discountAmount} KWD</b>
                                        </span>
                                        
                                        <span>
                                            <b>Grand Total :</b> <b>{OrderDetails?.paymentDetails?.grandTotal} KWD</b>
                                        </span>
                                      </div>
                                      
                              </div>
                              </div>
                             <div className="card-footer col-sm-12">
                                <div className="row float-right p-1">
                                    <ReactToPrint content={() => this.componentRef}>
                                            <PrintContextConsumer>
                                              {({ handlePrint }) => (
                                                <button className="btn btn-grd-disabled mr-2" onClick={handlePrint}>Print this out!</button>
                                              )}
                                            </PrintContextConsumer>
                                    </ReactToPrint>
                                   {!localStorage.getItem('role')=="admin"?null:<>
                                    <button
                                      onClick={this.onSaveData}
                                      className="btn btn-grd-disabled mr-2"
                                    >
                                      <i className="icofont icofont-save"></i> Save
                                    </button>
                              
                                  <Link to={"/orders"} className="btn btn-outline-dark">
                                    Cancel
                                  </Link></>}
                                </div>
                            </div>
                          </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>:null
         
        )
    }
}

OrderDetailsByCutomer.propsTypes = {
    getWarehouseOrderDetails: PropTypes.func.isRequired,
    getDriverList: PropTypes.func.isRequired,
    getOrderStatus: PropTypes.func.isRequired
  };
  
  var mapStateToProps = (state) => ({
    home: state,
  });
  
  export default connect(mapStateToProps, { getWarehouseOrderDetails, getOrderStatus , getDriverList})(OrderDetailsByCutomer);