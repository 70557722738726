import React from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Constant from '../../../Constant'
import Swal from 'sweetalert2'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Toggle from 'react-toggle'
import axios from 'axios';
import { GET_DRIVER_LIST } from '../../../actions/types';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class DriverList extends React.Component {
  state = {
    user_list: []
  };

  handleStatusChange = (sid) => {
    let isChecked = document.getElementById('cattogBtn_' + sid);
    isChecked.checked = !isChecked.checked;
    console.log(isChecked.checked, !isChecked.checked);
    let status = isChecked.checked ? 'inactive' : 'active';

    let newArray = [...this.state.user_list];
    let a = newArray.find((element) => element.id === sid);
    if (a) {
      a.status = status;
    }
    
    this.setState({ user_list: newArray });
    Swal.fire("Update Status!", "Status has been updated.", "success");
  };

  componentDidMount() {
    axios
      .post(Constant.getAPI() + "/delivery/driver/list", {}, config)
      .then((res) => {
        this.setState({ user_list: res.data.data });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const columns = [
      {
        name: "firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile Number",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };

    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Driver List</h5>
                    <Link
                      to="/driver/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                      data-modal="modal-13"
                    >
                      <i className="icofont icofont-plus m-r-5"></i> Add Driver
                    </Link>
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Driver List"}
                        className="table-responsive"
                        data={this.state.user_list}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


DriverList.propsTypes = {
 
};

var mapStateToProps = (state) => ({
  home: state.home,
});

export default connect(mapStateToProps, {  })(DriverList);