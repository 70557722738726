import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCategory, getCategory, updateCategory } from "../../actions/homeAction";

class CategoryAdd extends React.Component {
  state = {
    category_id: "",
    name: "",
    name_ar: "",
    isLoading: false,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onSaveData = () => {
    var that =this;
    // debugger
    if(that.props.category_id !== undefined){
      that.updateCategoryData()
    }
    else{
      if (this.props?.language_id === "2") {
        var Data = {
          name: this.state.name_ar,
          languageCode: "ar"
        }
      }
      else {
        Data={
          name: this.state.name
        }
      }
      this.props.addCategory(Data);
    }
  
  };
  updateCategoryData = () => {
 
    if (this.props?.language_id === "2") {
      var Data = {
        categoryId: this.props.category_id,
        name: this.state.name_ar,
        languageCode: "ar"
      }
    }
    else {
      Data={
        categoryId: this.props.category_id,
        name: this.state.name
      }
    }
    this.props.updateCategory(Data);
  }
  componentWillMount() {
  
      this.props.getCategory();
    
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.category_id) {
      // debugger
      const matchItem = nextProps?.home?.categoryList?.filter(itm => itm.id === this.props?.category_id);

      if (matchItem?.length) {
        const itm = matchItem[0];
        this.setState({
          name:itm.name,
          name_ar:itm.name_ar,
        })
      }
    }
  }
  render() {
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
         {this.props?.language_id === "2" ? 

<div className="col-md-6">
<div className="form-group row">
  <label className="col-sm-3 col-form-label">
    Category Name (Arabic)
  </label>
  <div className="col-sm-9">
    <input
      type="text"
      className="form-control"
      name="name_ar"
      id="category_name"
      placeholder="Category Name: Arabic"
      onChange={this.handleChange}
      value={this.state.name_ar}
    />
  </div>
</div>
</div>
:
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Category Name (English)</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="category_name"
                    placeholder="Category Name: English"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </div>
            </div>
  }
            {/* <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Category Name: Arabic</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  className="form-control"
                  name="name_ar"
                  placeholder="Category Name: Arabic"
                  onChange={this.handleChange}
                  value={this.state.name_ar}
                />
              </div>
            </div>
          </div> */}
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Category Priority
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="priority"
                    placeholder="Category Priority"
                    onChange={this.handleChange}
                    value={this.state.priority}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Category Variants
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="variants"
                    placeholder="Category variants"
                    onChange={this.handleChange}
                    value={this.state.variants}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-sm-3">Display Image</div>
                <div className="col-sm-9">
                  <form
                    id="categoryImage"
                    name="categoryImage"
                    encType="multipart/form-data"
                    className="text-capitalize"
                  >
                    <div className="form-group">
                      <input
                        accept="image/*"
                        onChange={this.handleImageUpload}
                        id="category_image"
                        type="file"
                        className="form-control"
                        autoComplete="off"
                        name="files"
                      />
                      <span className="mt-1">( 500 x 500 )</span>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}

            {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                  <select
                    name="status"
                    className="form-control"
                    value={this.state.status}
                    onChange={this.handleChange}
                  >
                    <option>Select</option>
                    <option value={true}>Active</option>
                    <option value={false}>InActive</option>
                  </select>
                </div>
              </div>
            </div> */}
            <div sclassName="col-md-6">
              <div id="category_image_label" className="pt-2">
                {this.state.image ? (
                  this.state.image !== null ||
                    this.state.image !== undefined ||
                    this.state.image !== {} ? (
                    <img
                      src={this.state.image}
                      alt=""
                      className="img-100"
                      onError={(e) => {
                        e.target.src = "";
                      }}
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="card-footer">
            <div className="row float-right p-1">
              {this.props.category_id ? (
                this.state.isLoading ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  <button
                    onClick={this.updateCategoryData}
                    className="btn btn-grd-disabled mr-2 "
                  >
                    Update
                  </button>
                )
              ) : this.props.isLoading ? (
                <button className="btn btn-grd-disabled mr-2" disabled>
                  Saving...!
                </button>
              ) : (
                <button
                  onClick={this.onSaveData}
                  className="btn btn-grd-disabled mr-2"
                >
                  <i className="icofont icofont-save"></i> Save
                </button>
              )}
              <Link to={"/category"} className="btn btn-outline-dark">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CategoryAdd.propsTypes = {
  addCategory: PropTypes.func.isRequired,
  getCategory: PropTypes.func.isRequired,
  home: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { addCategory, getCategory, updateCategory })(CategoryAdd);