import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getallUsers, SendNotification} from '../../actions/homeAction'


export class NotificationSEND extends Component {
    state = {
        type:'',
        text:'',
        userId:['','','']
    }

    componentWillMount() {
        var data={
            startRange:'0',
            count:"1000"
          }
          this.props.getallUsers(data);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({userList:nextProps?.home?.userList})
        console.log('userList',nextProps?.home?.userList);
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    optionHandleChange = (e,index) => {
        const updatedOption = this.state.userId.map((data,i)=> index==i?data=e.target.value:data)
        this.setState({userId:updatedOption})
     } 

     onAddOption = () => {
        var options = ''
        this.setState({userId:[...this.state.userId,options]})
    }

    removeOptions = (index) => {
        const filteredOptions = [...this.state.userId]

          filteredOptions.splice(index,1)
          
        this.setState({userId:filteredOptions})
    }

    onSaveData = (e) => {
        e.preventDefault()
        var data = {
            type:this.state.type,
            text:this.state.text,
            userId:this.state.userId,
        }
       
        this.props.SendNotification(data)
        
      } 

  render() {
      console.log(this.state.userList);
      console.log(this.state.userId);
    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Notification Send</h4>
                    </div>
                  </div>
                 
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/attribute-add">Notification Send</Link>
                      </li>
                      <li className="breadcrumb-item active">Push Notification</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <form onSubmit={(e)=>this.onSaveData(e)} className="row">
                       
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='attributeName' className="col-sm-4 col-form-label FONT_SIZE">Notification Type</label>
                                <div className="col-sm-8">
                                    <select className="form-control"
                                        name="type"
                                        id="type"
                                        onChange={this.handleChange}
                                        value={this.state.type}
                                        required>

                                        <option value=""> - Select Type - </option>
                                        <option value="general">General</option>
                                        <option value="orderPlaced">Order Placed</option>
                                        <option value="liveStart">Live Start</option>
                                        <option value="follow">Follow</option>
                                        <option value="postApproved">Post Approved</option>
                                        <option value="postLike">Post Like</option>
                                        <option value="postComment">Post Comment</option>
                                        <option value="commentLike">Comment Like</option>
                                        <option value="biddingAlert">Bidding Alert</option>
                                        
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group row">
                                <label htmlFor='attributeName' className="col-sm-4 col-form-label FONT_SIZE">Notification Text</label>
                                <div className="col-sm-8">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="text"
                                        id="text"
                                        placeholder="Notification Text"
                                        onChange={this.handleChange}
                                        value={this.state.text}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12">
                            <button onClick={this.onAddOption}
                            className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                            >
                            <i className="icofont icofont-plus m-r-5"></i>Add Option{" "}
                            </button>
                        </div>
                        <div className="col-md-12 row">
                            {this.state.userId.map((usrID,index)=>{
                               return  <div className="ATTRIBUTE_OPTIONS row" key={index}>
                               <div className="col-md-9">
                                   <div className="form-group row">
                                       <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">User Name</label>
                                       <div className="col-sm-8">
                                           <select
                                            className="form-control"
                                            name="usrID"
                                            id="usrID"
                                            onChange={e=> this.optionHandleChange(e,index)}
                                            value={usrID}
                                            required
                                           >
                                               <option value=""> - Select User - </option>
                                            {this.state.userList!==[]&&this.state.userList!==undefined?this.state.userList.map(user=>(
                                                <option value={user.id}>{user.userName}</option>
                                            )):null}
                                           </select>
                                       </div>
                                   </div>
                               </div>
                               <div className="col-md-3 ATT_REM_BTN">
                                    <button onClick={()=> this.removeOptions(index)}>Remove</button>
                                </div>
                           </div>
                            })}
                            
                        </div>
                        <div className="col-md-12 DATA_SAVE">
                            <div>
                                  <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> SEND</button>
                             
                              <Link to={"/pushNotifications"} className="btn btn-outline-dark">
                                Cancel
                              </Link>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


NotificationSEND.propsTypes = {
    getallUsers: PropTypes.func.isRequired,
    SendNotification: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
   home: state
});
export default connect(mapStateToProps, {

  getallUsers,SendNotification
  
  })(NotificationSEND);