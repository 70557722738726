import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPackage, } from "../../actions/homeAction";

class PackageAdd extends React.Component {
  state = {
    name: "",
    amount: "",
    currency: "",
    type: "fixed",
    isLoading: false,
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onSaveData = () => {
    const data = {
      name: this.state.name,
      amount: this.state.amount,
      currency: this.state.currency,
      type: "fixed",
    };
    this.props.addPackage(data);
  };
  // updateCategoryData = () => {
  //   const data = {
  //     name: this.state.name,
  //     amount: this.state.amount,
  //     currency: this.state.currency,
  //     type: "fixed",
  //   };
  //   this.props.updateCategory(data);
  // }
  render() {
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Amount</label>
                <div className="col-sm-9">
                  <input
                    type="number"
                    className="form-control"
                    name="amount"
                    placeholder="Amount"
                    onChange={this.handleChange}
                    value={this.state.amount}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Currency</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="currency"
                    placeholder="currency"
                    onChange={this.handleChange}
                    value={this.state.currency}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Type
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    name="type"
                    onChange={this.handleChange} >
                    <option value="fixed">Fixed</option>
                  </select>
                </div>
              </div>
            </div>

          </div>

          <div className="card-footer">
            <div className="row float-right p-1">
              {this.props.category_id ? (
                this.state.isLoading ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (
                  <button
                    onClick={this.updateCategoryData}
                    className="btn btn-grd-disabled mr-2 "
                  >
                    Update
                  </button>
                )
              ) : this.props.isLoading ? (
                <button className="btn btn-grd-disabled mr-2" disabled>
                  Saving...!
                </button>
              ) : (
                <button
                  onClick={this.onSaveData}
                  className="btn btn-grd-disabled mr-2"
                >
                  <i className="icofont icofont-save"></i> Save
                </button>
              )}
              <Link to={"/package"} className="btn btn-outline-dark">
                Cancel
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PackageAdd.propsTypes = {
  addPackage: PropTypes.func.isRequired,
  home: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { addPackage })(PackageAdd);
