import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getOrder } from '../../actions/homeAction'
class OrderList extends React.Component {
  state = {
    orderList: []
  }
  // handleStatusChange = (sid) => {
  //   var isChecked = $('#cattogBtn_' + sid);
  //   isChecked.prop("checked", !isChecked.prop("checked"));
  //   console.log(isChecked.prop('checked'), !isChecked.prop("checked"));
  //   if (!isChecked.prop("checked") === true) {
  //     var status = 'active'
  //   } else {
  //     var status = 'inactive'
  //   }
  //   let newArray = this.state.user_list;
  //   var a = newArray.find((element) => {
  //     return element.id === sid
  //   })
  //   a.status = status;
  //   console.log(newArray)
  //   this.setState({ user_list: newArray })
  //   Swal.fire("Update Status!", "Status has been updated.", "success");
  // }
  componentWillMount() {
    var data = {
      startRange: '0',
      count: "1000"
    }
    this.props.getOrder(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ orderList: nextProps?.home?.orderList })
  }

  render() {
    const columns = [
      {
        name: "invoice_number",
        label: 'Invoice Number',
        options: {
          filter: false,
          sort: true,
          customBodyRender:(invoice_number)=>{
            return <b>{invoice_number}</b>
          }
        }
      },
      {
      name: "User",
      label: "Customer Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(User)=>{
          return <span>{User.userName}</span>
        }
      }
    },
    // {
    //   name: "addressDetails.mobile",
    //   label: "Mobile",
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },  
    // {
    //   name: "alternate_mobile",
    //   label: "Alternate Mobile",
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },
    {
      name: "items",
      label: 'Price',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(items)=>{
            var total = 0
            items.forEach((value) => {
              total = total + parseInt(value.price);
            });            
          return(
            <div>
              <span style={{display:'flex',flexDirection:'row'}}>{total} KWD</span>
            </div>
          )
        }
      }
    },
    {
      name: "addressDetails.name",
      label: 'Area',
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "createdAt",
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (createdAt, tableMeta) => {

          return <div>
            {moment(createdAt).format('MM/DD/YYYY')}
          </div>
        }
      }
    },
    {
      name: "deliveryTimeSlot",
      label: "Time Slot",
      options: {
        filter: false,
        sort: true
      }
    },
    // {
    //   name: "addressDetails.road",
    //   label: "Road",
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },
    // {
    //   name: "addressDetails.street",
    //   label: "Street",
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },
    // {
    //   name: "deliveryCharge",
    //   label: 'Delivery Charge',
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },
    // {
    //   name: "deliveryTimeSlot",
    //   label: 'Time Slot ',
    //   options: {
    //     filter: false,
    //     sort: true
    //   }
    // },
    
    {
      name: "orderStatus",
      label: 'Order Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(orderStatus)=>{
          return <>{orderStatus=="Placed"?<b style={{color:'green'}}>{orderStatus}</b>:<b style={{color:'red'}}>{orderStatus}</b>}</>
        }
      }
    },{
      name: "paymentStatus",
      label: 'Payment Status',
      options: {
        filter: false,
        sort: true
      }
    },
  
    {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return <div>
              <Link
                  to={"/order/details/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top" title=""
                  data-original-title="Edit">
                <i className="f-20 icofont icofont-eye-alt text-custom"></i>
              </Link>
            </div>
          }
        }
    }

    ];

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Orders Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Order {this.props.match.params.type} List</h5>
                    {/* <Link to="/order/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Order
                       </Link> */}
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Order List"}
                        className="table-responsive"
                        data={this.state.orderList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderList.propsTypes = {
  getOrder: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getOrder })(OrderList);
