import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getOrderByShop} from '.././actions/homeAction'

class Header extends React.Component {

  state = {
    Orders:[]
  }

  Signout = () => {
    localStorage.clear();
    window.location.href = "#/";
    window.location.reload();
  };

  componentWillMount() {
    var data = {
      isMultiVendorDelivery:1,
      // startRange: 0,
      // count: 10,
    }
  this.props.getOrderByShop(data);
}

  componentWillReceiveProps(nextProps) {
    this.setState({ Orders: nextProps?.home?.orderByCustomers })
  }

  render() {
    var logo =localStorage.getItem("logo")
    // console.log('Orders',);
    return (
      <nav className="navbar header-navbar pcoded-header noprint">
        <div className="navbar-wrapper">
          <div className="navbar-logo text-center">
            <a
              className="mobile-menu"
              id="mobile-collapse"
              href="javascript:void(0)"
            >
              <i className="feather icon-menu"></i>
            </a>
            <Link to="#">
             <img
                style={{ height: "40px", width: "100px" }}
                src="./assets/newlogo.png"                        
                alt="logo"
              />
            </Link>
            <a className="mobile-options">
              <i className="feather icon-more-horizontal"></i>
            </a>
          </div>
          <div className="navbar-container container-fluid">
            <ul className="nav-right">
              {/* {
                localStorage.getItem('loged_In_role') === "shop"
                  ?
                  <Link to={"/shops/add/" + localStorage.getItem('loged_In_uid')}>
                    <li className="user-profile header-notification">
                      <img src="./assets/images/user.png" className="img-radius" alt="User-Profile-Image" />
                      <span>{localStorage.getItem("ad_name")}</span>
                    </li>
                  </Link>
                  : */}
              {/* <li className="user-profile header-notification">
                <img
                  src={logo}
                  className="img-radius"
                  style={{ height: "40px", width: "50px" }}
                  alt="User-Profile-Image"
                />
                <span>{this?.state?.Orders[0]?.shopName}</span>
              </li> */}
              {/* } */}
              <li>
                <button
                  className="btn bg-transparent"
                  onClick={this.Signout}
                  dataToggle="tooltip"
                  title="Logout"
                >
                  <i className="feather icon-log-out"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Header.propsTypes = {
  getOrderByShop: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};
var mapStateToProps = (state) => ({
  home: state,
});
export default connect(mapStateToProps,{getOrderByShop})(Header);
