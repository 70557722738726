import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Toggle from 'react-toggle'
import { getReportedPOST } from '../../actions/homeAction'
import moment from 'moment'
class ReportedPOST extends React.Component {
  state = {
    ReportPostList: []
  }
  
  componentWillMount() {
    var data = {
    //   startRange: '0',
    //   count: "1000"
    }
    this.props.getReportedPOST(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ReportPostList: nextProps?.home?.ReportedPosts })
  }

  render() {
    const columns = [
    {
            name: "Post",
            label: "Image",
            options: {
              filter: false,
              sort: false,
              customBodyRender: (Post, tableMeta) => {
    
                return <img src={Post.Media?.[0] !== undefined && Post.Media?.[0] !== null && Post.Media?.[0] !== {} ?
                  Post.Media?.[0]?.url !== undefined && Post.Media?.[0]?.url !== null && Post.Media?.[0]?.url !== "" ?
                    Post.Media?.[0]?.url : "./assets/images/icon.png" : "./assets/images/icon.png"}
                  className="img-fluid img-20" alt="" onError={this.imgLoadError} />
                // return <div style={{display:'flex',gap:'0.5rem'}}>
                //   {Media!==[]&&Media!==undefined?Media.map(data=>(
                //     <img style={{height:'40px'}} src={data.url} alt="" />
                //   )):null}
                // </div>
              }
            }
    }, 
      {
        name: "Post",
        label: 'Post Name',
        options: {
          filter: false,
          sort: true,
          customBodyRender:(Post)=>{
            return <b>{Post.title}</b>
          }
        }
      },
      {
      name: "User",
      label: "User Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender:(User)=>{
          return <span>{User.userName}</span>
        }
      }
    },
    {
        name: "User",
        label: "User Email",
        options: {
          filter: false,
          sort: true,
          customBodyRender:(User)=>{
            return <span>{User.email}</span>
          }
        }
      },
    {
      name: "createdAt",
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(createdAt)=>{
          return <span>
              {moment(createdAt*1000).format('MM/DD/YYYY')}
          </span>
        }
      }
    },
    {
      name: "comment",
      label: 'Comments',
      options: {
        filter: false,
        sort: true
      }
    },
   
    ];

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Reported Posts</h5>
                    {/* <Link to="/order/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Order
                       </Link> */}
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Reported Posts"}
                        className="table-responsive"
                        data={this.state.ReportPostList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportedPOST.propsTypes = {
    getReportedPOST: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getReportedPOST })(ReportedPOST);
