import React from 'react';
import { Link } from 'react-router-dom'

import "react-toggle/style.css" // for ES6 modules
import { getCityList,deleteCity } from '../../../actions/homeAction'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MUIDataTable from 'mui-datatables';
import Toggle from 'react-toggle'

class CityList extends React.Component {
  state = {

  }
  componentWillMount() {
    this.props.getCityList()
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ cityList: nextProps?.home?.cityList })
  }
  // handleStatusChange = (sid) => {
  //   var isChecked = $('#cattogBtn_' + sid);
  //   isChecked.prop("checked", !isChecked.prop("checked"));
  //   console.log(isChecked.prop('checked'), !isChecked.prop("checked"));
  //   if (!isChecked.prop("checked") === true) {
  //     var status = 'active'
  //   } else {
  //     var status = 'inactive'
  //   }
  //   let newArray = this.state.city_list;
  //   var a = newArray.find((element) => {
  //     return element.cityID === sid
  //   })
  //   a.status = status;
  //   console.log(newArray)
  //   this.setState({ city_list: newArray })
  //   Swal.fire("Update Status!", "Status has been updated.", "success");
  // }
  deleteCity = (id) => {
    var data={
     CityId:id
    }
    this.props.deleteCity(data)
   };
    render() {
      const columns = [
        {
          name: "name",
          label: "City Name",
          options: {
            filter: true,
            sort: true,
          },
        },
        // {
        //   name: "pinCode",
        //   label: "Pin Code",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        // {
        //   name: "name_ar",
        //   label: "State Code",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
        {
          name: "status",
          label: "Status",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (status, tableMeta) => {
              var Id = tableMeta.rowData[4];
              // console.log(tableMeta.rowData[6]);
              return <Toggle
                id={"status_" + Id}
                checked={status}
                value={status}
               // onChange={this.handleStatusChange.bind(this,Id,status)}
              />
            }
          }
        },
        {
          name: "visibility",
          label: "Visibility",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (visibility, tableMeta) => {
              var Id = tableMeta.rowData[4];
              // console.log(tableMeta.rowData[6]);
              return <Toggle
                id={"status_" + Id}
                checked={visibility}
                value={visibility}
               // onChange={this.handleStatusChange.bind(this,Id,status)}
              />
            }
          }
        },
        {
          name: "id",
          label: "Actions",
          options: {
            filter: true,
            sort: true,
            customBodyRender: (id, tableMeta) => {
              return (
                <div>
                  <Link
                    to={"/city/add/" + id}
                    className="m-r-15 text-muted"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Edit"
                  >
                    <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                  </Link>
                  <span
                    onClick={this.deleteCity.bind(this, id)}
                    className="m-r-15 text-muted"
                    data-toggle="tooltip"
                    data-placement="top"
                    title=""
                    data-original-title="Delete"
                  >
                    <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                  </span>
                </div>
              );
            },
          },
        },
      ];
      const options = {
        filterType: "dropdown",
        viewColumns: false,
        print: false,
        download: false,
        selectableRows: "none",
        textLabels: {
          body: {
            noMatch: this.state.isSaving
              ? "Loading data..!"
              : "Sorry, No City Found",
            toolTip: "Sort",
            columnHeaderTooltip: (column) => `Sort for ${column.label}`,
          },
        },
      };
    return (
      <div className="pcoded-inner-content">
      <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-lg-8">
                <div className="page-header-title">
                  <div className="d-inline">
                    <h4>City List</h4>
                  </div>
                </div>
                <Link
                  to="/city/add"
                  className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                  data-modal="modal-13"
                >
                  {" "}
                  <i className="icofont icofont-plus m-r-5"></i> Add City
                </Link>
              </div>
              <div className="col-lg-4">
                <div className="page-header-breadcrumb">
                  <ul className="breadcrumb-title">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="feather icon-home"></i>{" "}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">City List</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"City List"}
                        className="table-responsive"
                        data={this.state.cityList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state
  }
}
CityList.propTypes = {
  getCityList: PropTypes.func.isRequired,
  deleteCity:PropTypes.func.isRequired,
}
export default connect(mapStateToProps, { getCityList,deleteCity })(CityList)