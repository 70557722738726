/* eslint-disable import/no-anonymous-default-export */
import {

  GET_CATEGORY,
  GET_COUPON,
  GET_COUNTRY,
  GET_GOVERNORATE,
  SET_USER,
  GET_LANGUAGE_LIST,
  GET_TERMS,
  GET_STATE,
  CITY_LIST,
  GET_ORDER_STATUS,
  GET_ALL_SELLER,
  GET_PACKAGE,
  GET_ALL_USER,
  GET_ORDER,
  GET_PRODUCT,
  GET_REPORTED_POSTS,
  GET_ATTRIBUTE,
  GET_NOTIFICATIONS,
  GET_WINNERS_LIST,
  GET_WALLET_LIST,
  GET_TIME_SLOT,
  GET_ORDER_BY_CUSTMER,
  GET_ORDER_DETAILS_CUSTOMER,
  GET_ORDER_DETAILS_SHOP,
  GET_DRIVER_LIST,
  GET_ORDERS_OF_WAREHOUSE,
  GET_ORDERS_OF_READY_FOR_PICKUP,
  GET_ORDERS_OF_OUT_FOR_DELIVERY,
  GET_NOT_DELIVERED_ORDER,
  GET_ALL_ORDERS,
  GET_VENDOR_ALL_ORDERS,
} from "../actions/types";

var initialState = {};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY:
      return {
        ...state,
        categoryList: action.payload,
      };
    case GET_TIME_SLOT:
      return {
        ...state,
        timeSlotList: action.payload,
      };
    case GET_ORDERS_OF_WAREHOUSE:
      return {
        ...state,
        warehouseOrders: action.payload,
      };
    case GET_ORDERS_OF_READY_FOR_PICKUP:
      return {
        ...state,
        readyForPickupOrders: action.payload,
      };
    case GET_ORDERS_OF_OUT_FOR_DELIVERY:
      return {
        ...state,
        OutForDeliveryOrders: action.payload,
      };
    case GET_NOT_DELIVERED_ORDER:
      return {
        ...state,
        notDeliveredOrders: action.payload,
      };
    case GET_ALL_ORDERS:
      return {
        ...state,
        getAll: action.payload,
      };
    case GET_VENDOR_ALL_ORDERS:
      return {
        ...state,
        vendorOrder: action.payload,
      };
    case GET_WALLET_LIST:
      return {
        ...state,
        walletList: action.payload,
      };
    case GET_DRIVER_LIST:
      return {
        ...state,
        driverList: action.payload,
      };
    case GET_ORDER_BY_CUSTMER:
      return {
        ...state,
        orderByCustomers: action.payload,
      };
    case GET_ORDER_DETAILS_SHOP:
      return {
        ...state,
        orderByShops: action.payload,
      };
    case GET_ORDER_DETAILS_CUSTOMER:
      return {
        ...state,
        orderDetailsByCustomers: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        Notification: action.payload,
      };
    case GET_ATTRIBUTE:
      return {
        ...state,
        attributeList: action.payload,
      };
    case GET_WINNERS_LIST:
      return {
        ...state,
        winnersList: action.payload,
      };
    case GET_COUPON:
      return {
        ...state,
        couponList: action.payload,
      };
    case GET_REPORTED_POSTS:
      return {
        ...state,
        ReportedPosts: action.payload,
      };
    case CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_PRODUCT:
      return {
        ...state,
        ProductData: action.payload,
      };
    case GET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case GET_GOVERNORATE:
      return {
        ...state,
        governorate: action.payload,
      };
    case GET_STATE:
      return {
        ...state,
        state: action.payload,
      };
    case GET_PACKAGE:
      return {
        ...state,
        packageList: action.payload,
      };

    case GET_LANGUAGE_LIST:
      return {
        ...state,
        languageList: action.payload,
      };
    case GET_TERMS:
      return {
        ...state,
        terms: action.payload,
      };
    case GET_ALL_SELLER:
      return {
        ...state,
        sellerList: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        orderList: action.payload,
      };
    case GET_ALL_USER:
      return {
        ...state,
        userList: action.payload,
      };

    case GET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: action.payload,
      };
    default:
      return state;
  }
}
export default homeReducer;