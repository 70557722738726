import axios from "axios";
import Constant from "../Constant";
import {
  SET_LOADING,
  LOGOUT,
  SET_USER,
  GET_COUNTRY,
  GET_ALL_USER,
  GET_CATEGORY,
  GET_GOVERNORATE,
  GET_STATE,
  GET_TERMS,
  GET_COUPON,
  CITY_LIST,
  GET_ORDER_STATUS,
  GET_ALL_SELLER,
  GET_PACKAGE,
  GET_ORDER,
  GET_PRODUCT,
  GET_REPORTED_POSTS,
  GET_ATTRIBUTE,
  GET_NOTIFICATIONS,
  GET_WINNERS_LIST,
  GET_WALLET_LIST,
  GET_TIME_SLOT,
  GET_ORDER_BY_CUSTMER,
  GET_ORDER_DETAILS_CUSTOMER,
  GET_ORDER_DETAILS_SHOP,
  GET_DRIVER_LIST,
  GET_ORDERS_OF_WAREHOUSE,
  GET_ORDERS_OF_READY_FOR_PICKUP,
  GET_ORDERS_OF_OUT_FOR_DELIVERY,
  GET_NOT_DELIVERED_ORDER,
  GET_ALL_ORDERS,
  GET_VENDOR_ALL_ORDERS,
} from "./types";
import Swal from "sweetalert2";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

// SET LOADING
export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: SET_LOADING,
    payload: data,
  });
};

// LOGIN
export const loginUser = (data) => async (dispatch) => {
  var Admin = '/admin/login'
  var Warehouse = data.role==='admin'?'/warehouse/admin/login':'/user/login'

  if(data.role==='admin'){
    try {
      await axios
        .post(Constant.getAPI() + '/warehouse/admin/login', {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          console.log(res);
          dispatch({
            type: SET_USER,
            payload: res.data,
          });
  
          Swal.fire({
            title: "Login Successfully",
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
  
          console.log('AUTH', res.data);
  
          if(res.data.data.userName=="Admin"){
            localStorage.setItem("role", 'admin');
          }else{
            localStorage.setItem("role", 'warehouse');
          }
  
          localStorage.setItem("loged_In_auth", res.data.auth);
          localStorage.setItem("ucs-userId", res.data.data.id);
          localStorage.setItem("ad_name", res.data.data.fullName);
          localStorage.setItem("ad_email", res.data.data.email);
          localStorage.setItem("ucs-mobile", res.data.data.contactNo);
          localStorage.setItem("loged_In", true);
          window.location.href = "#/home";
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        });
    } catch (err) {
      console.log(err);
      Swal.fire("Incorrect Credentials", "", "error");
    }
  }else{
    try {
      await axios
        .post(Constant.getAPI() + Warehouse, {
          email: data.email,
          password: data.password,
        })
        .then((res) => {
          console.log(res);
          dispatch({
            type: SET_USER,
            payload: res.data,
          });
  
          Swal.fire({
            title: "Login Successfully",
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
  
          console.log('Seller', res.data);
  
        
            localStorage.setItem("role", 'vendor');
          
  
          localStorage.setItem("loged_In_auth", res.data.auth);
          localStorage.setItem("vendorId", res.data.id);
          localStorage.setItem("firstName", res.data.firstName);
          localStorage.setItem("lastName", res.data.lastName);
          localStorage.setItem("email", res.data.email);  
          localStorage.setItem("mobileNumber", res.data.mobileNumber);
          localStorage.setItem("logo", res.data.image.url);
          localStorage.setItem("loged_In", true);
          window.location.href = "#/";
          window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: err.message,
            icon: "",
            imageUrl: "./assets/images/favicon/android-chrome-192x192.png",
            imageHeight: 100,
            text: "",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        });
    } catch (err) {
      console.log(err);
      Swal.fire("Incorrect Credentials", "", "error");
    }
  }
  
};

// LOGOUT
export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
    payload: {},
  });
  localStorage.clear();
  window.location.href = "#/";
  window.location.reload();
};

//get all users
export const getallUsers = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/user/getAll",data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_USER,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// add user
export const addUsers= (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/user/register", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "User added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/user";
      dispatch(getallUsers());
    })
    .catch((err) =>{
      debugger
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};


export const UserPasswordChange = (data) => (dispatch)=>{
  dispatch(setLoading(true))
  axios.post(Constant.getAPI()+"/admin/changeuserpassword",data ,config).then(res=>{
    if(res.data.status){
      Swal.fire({
        title: `${res.data.message}`,
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    }
    dispatch(setLoading(false));
  })
}



//get all sellers
export const getSeller = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/admin/sellerGetAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_ALL_SELLER,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//Update Seller
export const updateSeller = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/seller/update", data,config)
    .then((res) => {
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//get Package
export const getPackage = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/package/get", data,config)
    .then((res) => {
      dispatch({
        type: GET_PACKAGE,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
export const getTimeSlots = () => (dispatch) => {
  var data = {
    type:'all'
  }
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/config/getTimeSlots", data,config)
    .then((res) => {
      dispatch({
        type: GET_TIME_SLOT,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


export const TimeSlotDelete = (data) => (dispatch) => {
  //console.log(data)

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/config/deleteTimeSlots", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          dispatch(getTimeSlots());
        })
        .catch((err) => console.log(err));
    }
  });
};
//get order
export const getOrder = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/order/getAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_ORDER,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const getReportedPOST = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/post/reportList", data,config)
    .then((res) => {
      dispatch({
        type: GET_REPORTED_POSTS,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};


export const getNotifications = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/notification/getAll", data,config)
    .then((res) => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const SendNotification = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/notification/add", data,config)
    .then((res) => {
      Swal.fire({
        title: "Notification Sent",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/pushNotifications";
      dispatch(getNotifications());
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// CATEGORY LIST
export const getCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/category/get",data, config)
    .then((res) => {
      
      dispatch({
        type: GET_CATEGORY,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// ADD Package
export const addPackage = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/package/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Package added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/package";
      dispatch(getPackage());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

export const addTimeSlot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/config/setTimeSlots", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Time Slot added",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/timeSlot";
      dispatch(getTimeSlots());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

export const updateTimeSlot = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/config/editTimeSlots", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Time Slot Updated",
        icon: "",
        // imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/timeSlot";
      dispatch(getTimeSlots());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};
// ADD CATEGORY
export const addCategory = (data) => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/category/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Category added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      window.location.href = "#/category";
      dispatch(getCategory());
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//DELETE CATEGORY



export const deleteCategory = (data) => (dispatch) => {
  //console.log(data)

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .post(Constant.getAPI() + "/category/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Deleted!",
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          window.location.reload();
          dispatch(getCategory());
        })
        .catch((err) => console.log(err));
    }
  });
};

//update category
export const updateCategory = (data) => (dispatch) => {
  console.log(data);
  axios

    .post(Constant.getAPI() + `/category/update`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Category Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCategory());
      window.location.href = "#/category";
    })
    .catch((err) => console.log(err));
};

// COUNTRY LIST
export const getCountry = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + "/country/list", data,config)
    .then((res) => {
      dispatch({
        type: GET_COUNTRY,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//ADD COUNTRY
export const addCountry = (data) => (dispatch) => {
  console.log(config);
  axios
    .post(Constant.getAPI() + `/country/add`, data.payload, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Country added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "#/country";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};
//UPDATE COUNTRY
export const updateCountry = (data) => (dispatch) => {
  console.log(data);
  axios

    .patch(Constant.getAPI() + `/country/edit/${data.CountryId}`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Country Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCountry());
      window.location.href = "#/country";
    })
    .catch((err) => console.log(err));
};

//COUNTRY DELETE
export const deleteCountry = (data) => (dispatch) => {
  //console.log(data)

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((isConfirm) => {
    if (isConfirm.value) {
      axios
        .delete(Constant.getAPI() + `/country/delete/${data.id}`, config)
        .then((res) => {
          Swal.fire({
            title: "Country Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            // showCancelButton: true,
            // confirmButtonText: "Yes, delete it!",
            // cancelButtonText: "No, keep it",
          });
          dispatch(getCountry());
          window.location.href = "#/country";
        })
        .catch((err) => console.log(err));
    }
  });
};

// GOVERNORATE LIST
export const getGovernorate = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/governorate/get", data, config)
    .then((res) => {
      dispatch({
        type: GET_GOVERNORATE,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
// DELETE GOVERNORATE
export const deleteGovernorate = (data) => (dispatch) => {
  console.log(data);

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .post(Constant.getAPI() + "/governorate/delete", data, config)
        .then((res) => {
          Swal.fire({
            title: "Governorate Deleted",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            text: "You will not be able to recover this !",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            type: "warning",
          });
          dispatch(getGovernorate());
          window.location.href = "#/governorate";
        })
        .catch((err) => console.log(err));
    }
  });
};
//ADD GOVERNORATE
export const addGovernorate = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + "/governorate/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Governorate added",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getGovernorate());
      window.location.href = "#/governorate";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};
//UPDATE GOVERNORATE
export const updateGovernorate = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + "/governorate/edit", data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Governorate Updated",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getGovernorate());
      window.location.href = "#/governorate";
    })
    .catch((err) => console.log(err));
};

// STATE LIST
export const getState= (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/state/list",  config,data,)
    .then((res) => {
    
      dispatch({
        type: GET_STATE,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//DELETE STATE
export const deleteState = (data) => (dispatch) => {
  console.log(data);

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .delete(Constant.getAPI() + `/state/delete/${data.StateId}`, config)
        .then((res) => {
          Swal.fire({
            title: "State Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
          });
          dispatch(getState());
          window.location.href = "#/state";
        })
        .catch((err) => console.log(err));
    }
  });
};
//UPDATE State
export const updateState = (data) => (dispatch) => {
  console.log(data);
  axios
    .patch(Constant.getAPI() + `/state/edit/${data.StateId}`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "State Updated",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getState());
      window.location.href = "#/state";
    })
    .catch((err) => console.log(err));
};

//ADD State
export const addState = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + "/state/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "State added",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getState());
      window.location.href = "#/state";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//ADD TERMS
export const addTerms = (data) => (dispatch) => {
  console.log(config);
  axios
    .post(Constant.getAPI() + `/terms/add`, data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Terms added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch();
      window.location.href = "#/dateblock";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};
// GET TERMS
export const getTerms = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/terms/get", data)
    .then((res) => {
      dispatch({
        type: GET_TERMS,
        payload: res.data.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// GET Coupon
export const getCoupon = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/coupon/get", data,config)
    .then((res) => {
      dispatch({
        type:  GET_COUPON,
        payload: res?.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD COUPON
export const addCoupon = (data) => (dispatch) => {
  console.log(config);
  dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + `/coupon/add`, data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "Coupon added",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(setLoading(false));
      window.location.href = "#/coupon";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//UPDATE COUPON
export const updateCoupon= (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + "/coupon/edit", data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Coupon Updated",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getGovernorate());
      window.location.href = "#/coupon";
    })
    .catch((err) => console.log(err));
};

//DELETE Coupon
export const deleteCoupon = (data) => (dispatch) => {
  console.log(data);

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .delete(Constant.getAPI() + "/coupon/delete/145476141567",config, data, )
        .then((res) => {
          Swal.fire({
            title: "Coupon Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
          });
          window.location.reload();
          dispatch(getCoupon());
        })
        .catch((err) => console.log(err));
    }
  });
};

// City LIST
export const getCityList= (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .get(Constant.getAPI() + "/city/list",  config,data,)
    .then((res) => {
    
      dispatch({
        type: CITY_LIST,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};
//DELETE City
export const deleteCity = (data) => (dispatch) => {
  console.log(data);

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .delete(Constant.getAPI() + `/city/delete/${data.CityId}`, config)
        .then((res) => {
          Swal.fire({
            title: "City Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
          });
          dispatch(getCityList());
          window.location.href = "#/city";
        })
        .catch((err) => console.log(err));
    }
  });
};
//UPDATE State
export const updateCity = (data) => (dispatch) => {
  console.log(data);
  axios
    .patch(Constant.getAPI() + `/city/edit/${data.CityId}`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "City Updated",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCityList());
      window.location.href = "#/city";
    })
    .catch((err) => console.log(err));
};

//ADD City
export const addCity = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + "/city/add", data, config)
    .then((res) => {
      console.log(res);
      Swal.fire({
        title: "City added",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getCityList());
      window.location.href = "#/city";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//Order Status 
export const getOrderStatus= (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/orderStatus/get",data,config)
    .then((res) => {
    
      dispatch({
        type: GET_ORDER_STATUS,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//ADD OrderStatus
export const addOrderStatus = (data) => (dispatch) => {
  axios
    .post(Constant.getAPI() + "/orderStatus/add", data, config)
    .then((res) => {
      Swal.fire({
        title: "Order Status added",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getOrderStatus());
      window.location.href = "#/order-status";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//DELETE OrderStatus
export const deleteOrderStatus = (data) => (dispatch) => {

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .post(Constant.getAPI() + `/orderStatus/delete`,data, config)
        .then((res) => {
          Swal.fire({
            title: "OrderStatus Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
          });
          dispatch(getOrderStatus());
          window.location.href = "#/order-status";
        })
        .catch((err) => console.log(err));
    }
  });
};

//UPDATE OrderStatus
export const updateOrderStatus = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + `/orderStatus/edit`, data, config)
    .then((res) => {
      Swal.fire({
        title: "Order Status Updated",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getOrderStatus());
      window.location.href = "#/order-status";
    })
    .catch((err) => console.log(err));
};

// Product LIST
export const getProduct = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/getallpost", data,config)
    .then((res) => {
      dispatch({
        type: GET_PRODUCT,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

export const getWalletList = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/listSendMoneyToBank", data,config)
    .then((res) => {
      dispatch({
        type: GET_WALLET_LIST,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

// WIINERS LIST
export const getWinners = (data) => (dispatch) => {
  dispatch(setLoading(true));

  axios
    .post(Constant.getAPI() + "/admin/getDetailsForAuctions", data,config)
    .then((res) => {
      dispatch({
        type: GET_WINNERS_LIST,
        payload: res.data?.data,
      });
      console.log(res);
      dispatch(setLoading(false));
    })
    .catch((err) => console.log(err));
};

//Add Product
export const addProducts = (data) => (dispatch) => {
dispatch(setLoading(true));
  axios
    .post(Constant.getAPI() + "/post/add", data, config)
    .then((res) => {
      Swal.fire({
        title: "Product added",
        type: "success",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getProduct());
      window.location.href = "#/product/";
    })
    .catch((err) =>{
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//update Product
export const updateProduct = (data) => (dispatch) => {
  console.log(data);
  axios
    .post(Constant.getAPI() + `/post/update`, data, config)
    .then((res) => {
      console.log(res.data);
      Swal.fire({
        title: "Product Updated",
        icon: "",
        imageUrl: "./assets/images/test.png",
        imageHeight: 100,
        text: "",
        type: "success",
        confirmButtonColor: "#3ab1f7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      dispatch(getProduct());
      window.location.href = "#/product";
    })
    .catch((err) =>{
      debugger
      Swal.fire({
        title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message ||err?.response?.data ),
        icon: "error",
        text: "",
        type: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    });
};

//DELETE Products
export const deleteProduct = (data) => (dispatch) => {

  Swal.fire({
    title: "Are you sure?",
    text: "You will not be able to recover this !",
    type: "warning",
    imageUrl: "./assets/images/test.png",
    imageHeight: 100,
    showCancelButton: true,
    confirmButtonText: "Yes, delete it!",
    cancelButtonText: "No, keep it",
  }).then((Confirm) => {
    if (Confirm.value) {
      axios
        .post(Constant.getAPI() + `/post/delete`,data, config)
        .then((res) => {
          Swal.fire({
            title: "Products Deleted",
            text: "You will not be able to recover this !",
            type: "warning",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
          });
          dispatch(getProduct());
          window.location.href = "#/product";
        })
        .catch((err) => console.log(err));
    }
  });
};

export const addAttribute = (data) => (dispatch) => {
  dispatch(setLoading(true));
    axios
      .post(Constant.getAPI() + "/attribute/add", data, config)
      .then((res) => {
        Swal.fire({
          title: "Attribute added",
          type: "success",
          imageHeight: 100,
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        dispatch(getAttribute());
        window.location.href = "#/attribute/All";
      })
      .catch((err) =>{
        Swal.fire({
          title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  export const getAttribute = (data) => (dispatch) => {
    dispatch(setLoading(true));
      axios
        .get(Constant.getAPI() + "/attribute/get", config)
        .then((res) => {
          dispatch({
            type: GET_ATTRIBUTE,
            payload: res.data?.data,
          });
        })
        .catch((err) =>{
          Swal.fire({
            title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
            icon: "error",
            text: "",
            type: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        });
    };

    export const addVariant = (data) => (dispatch) => {
      dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/variant/add", data, config)
          .then((res) => {
            Swal.fire({
              title: "Variant Added",
              type: "success",
              imageHeight: 100,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
            // dispatch(getAttribute());
            // window.location.href = "#/attribute/All";
          })
          .catch((err) =>{
            Swal.fire({
              title: (err?.response?.data?.error?.errors?.[0]?.message || err?.response?.data?.message||err?.response?.data  ),
              icon: "error",
              text: "",
              type: "error",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          });
      };

      export const getOrderByWarehouse = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/warehouseOrders", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDERS_OF_WAREHOUSE,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getOrderByReadyForPickup = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/readyForPickupOrders", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDERS_OF_READY_FOR_PICKUP,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getAll = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/masterOrder", data,config)
          .then((res) => {
            dispatch({
              type: GET_ALL_ORDERS,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      }; 

      export const vendorOrder = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/vendorOrder", data,config)
          .then((res) => {
            dispatch({
              type: GET_VENDOR_ALL_ORDERS,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      }; 

      export const getOrderByOutForDelivery = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/outForDeliveryOrders", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDERS_OF_OUT_FOR_DELIVERY,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getNotDeliveredOrder = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/notDeliveredOrders", data,config)
          .then((res) => {
            dispatch({
              type: GET_NOT_DELIVERED_ORDER,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getWarehouseOrderDetails = (data) => (dispatch) => {
        dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/warehouse/orderByCustomersDetails", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_DETAILS_CUSTOMER,
              payload: res.data.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getOrderByCutomers = (data) => (dispatch) => {
        dispatch(setLoading(true));
      
        axios
          .post(Constant.getAPI() + "/warehouse/orderByCustomers", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_BY_CUSTMER,
              payload: res.data?.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getOrderDetailsByCustomer = (data) => (dispatch) => {
        dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/warehouse/orderByCustomersDetails", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_DETAILS_CUSTOMER,
              payload: res.data.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getOrderByShop = (data) => (dispatch) => {
        dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/warehouse/orderByShops", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_BY_CUSTMER,
              payload: res.data.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getOrderDetailsByShop = (data) => (dispatch) => {
       
        axios
          .post(Constant.getAPI() + "/warehouse/orderByShopsDetails", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_DETAILS_SHOP,
              payload: res.data.data,
            });
            
          })
          .catch((err) => console.log(err));
      };

      export const getExpressOrderByCustomers = (data) => (dispatch) => {
        dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/warehouse/orderByCustomers", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_BY_CUSTMER,
              payload: res.data.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getExpressOrderByShop = (data) => (dispatch) => {
        dispatch(setLoading(true));
        axios
          .post(Constant.getAPI() + "/warehouse/orderByShops", data,config)
          .then((res) => {
            dispatch({
              type: GET_ORDER_BY_CUSTMER,
              payload: res.data.data,
            });
            console.log(res);
            dispatch(setLoading(false));
          })
          .catch((err) => console.log(err));
      };

      export const getDriverList = (data) => (dispatch) => {
       
        axios
          .post(Constant.getAPI() + "/delivery/driver/list", data,config)
          .then((res) => {
            dispatch({
              type: GET_DRIVER_LIST,
              payload: res.data.data,
            });
            
          })
          .catch((err) => console.log(err));
      };