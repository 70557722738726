module.exports = {
  getAPI: function () {
    // // return "https://zeed.infoware.xyz";
    // return "https://api.zeedco.co";
    // // return "http://dev.infoware.xyz:8042"
    let hostName = window.location.hostname;
    let devString = "-dev.zeedco.co";
    let localStr = 'localhost:3000'
    let url = "https://api.zeedco.co"
    if (hostName.includes(devString)) {
      url = 'https://stg-api.zeedco.co';
    }
    else if (hostName.includes(localStr)) {
      url = 'http://localhost:5000';
    }
    return url;
  },
  getDefaultCurrrency: function () {
    return "KWD";
  },
  getLanguageList: function () {
    var languages = [
      {
        id: "en",
        name: "English",
      },
      {
        id: "ar",
        name: "Arabic",
      },
    ];
    return languages;
  },
};
