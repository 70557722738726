import React from 'react';
import { Link } from 'react-router-dom'

import "react-toggle/style.css" // for ES6 modules
import { getOrderStatus, deleteOrderStatus } from '../../actions/homeAction'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MUIDataTable from 'mui-datatables';
import moment from 'moment'
import Toggle from 'react-toggle'


class OrderStatusList extends React.Component {
  state = {

  }
  // handleStatusChange = (sid) => {
  //   var isChecked = $('#cattogBtn_' + sid);
  //   isChecked.prop("checked", !isChecked.prop("checked"));
  //   console.log(isChecked.prop('checked'), !isChecked.prop("checked"));
  //   if (!isChecked.prop("checked") === true) {
  //     var status = 'active'
  //   } else {
  //     var status = 'inactive'
  //   }
  //   let newArray = this.state.user_list;
  //   var a = newArray.find((element) => {
  //     return element.id === sid
  //   })
  //   a.status = status;
  //   console.log(newArray)
  //   this.setState({ user_list: newArray })
  //   Swal.fire("Update Status!", "Status has been updated.", "success");
  // }

  deleteOrder = (id) => {
    var data = {
      orderStatusId:id
    }
    this.props.deleteOrderStatus(data)
  }
  componentDidMount() {
    this.props.getOrderStatus()
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ orderStatus: nextProps?.home?.orderStatus })
  }
  render() {
    const columns = [
      //   {
      //   name: "name",
      //   label: "Title",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
      {
        name: "displayStatus",
        label: "Display Status",
        options: {
          filter: false,
          sort: true
        }
      }, 
       {
        name: "createdAt",
        label: 'Date',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (createdAt, tableMeta) => {
            debugger
            return <div>
              {moment(createdAt).format('MM/DD/YYYY')}
            </div>
          }
        }
      },{
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
        }
      }, {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return <div>
              <Link to={"/order-status/add/" + id}
                className="m-r-15 text-muted"
                data-toggle="tooltip"
                data-placement="top" title=""
                data-original-title="Edit">
                <i className="f-20 icofont icofont-ui-edit text-custom"></i>
              </Link>
              <span
                onClick={this.deleteOrder.bind(this, id)}
                className="m-r-15 text-muted"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Delete">
                <i className="f-20 icofont icofont-delete-alt text-danger"></i>  </span>
            </div>
          }
        }
      }
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No OrderStatus Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Order Status List</h5>
                    <Link to="/order-status/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Status
                    </Link>
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Order Status List"}
                        className="table-responsive"
                        data={this.state.orderStatus}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    home: state
  }
}
OrderStatusList.propTypes = {
  getOrderStatus: PropTypes.func.isRequired,
  deleteOrderStatus: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, { getOrderStatus, deleteOrderStatus })(OrderStatusList);
