import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getOrderByShop, deleteProduct } from '../../actions/homeAction'

class OrdersByShop extends React.Component {
  state = {
    Orders: [],
    orderDate:'',
    shopId:'',
  }
  componentWillMount() {
  
      var data = {
        isMultiVendorDelivery:1,
        startRange: 0,
        count: 10,
      }
    this.props.getOrderByShop(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ Orders: nextProps?.home?.orderByCustomers })
  }

  OnSearch = () =>{
    var orderDate = this.state.orderDate
    var shopId = this.state.shopId
    var data = {
        isMultiVendorDelivery:1,
        startRange: 0,
        // count: 10,
        shopId: shopId,
        date: orderDate,
    }
    this.props.getOrderByShop(data);
  }

//   componentDidUpdate(prevProps,prevState) {
//     if (this.state.shopName !== prevState.shopName) {
//       console.log("Do something");
//       var Product = prevProps.home.ProductData
//       var Filtered = Product.filter(data=>data.shop.shopName.toUpperCase().includes(this.state.shopName.toUpperCase()))
//       console.log('Filtered',Filtered);
//       this.setState({ProductList: Filtered})
//     }
//   }


//   handleStatusChange = (id,status)=> {
//     var data = {
//       id:id,
//       status:!status,
//     }
//     axios.post(Constant.getAPI()+'/admin/changeStatusSendMoneyToBank',data, config).then(res=>{
//       if(res.data.status){
//         var data = {
//             startRange: '0',
//             count: "10",
//           }
//         this.props.getWalletList(data);
//       }
//     })
//   }

  render() {
    const role = localStorage.getItem('role')
    console.log('Orders',this.state.Orders);
    const columns = [
        {
            name: "shopId",
            label: "No.",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (shopId, tableMeta) => {
                var tab = tableMeta.rowIndex
                return <div>
                  <span>{tab+1}</span>
                </div>
              }
            }
          },
      {
        name: "date",
        label: "Date-Time",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (date, tableMeta) => {

            return <div>
              {/* <span>{moment(date).format('DD-MMM-YYYY | hh:mm A')}</span> */}
              <span>{date}</span>
            </div>
          }
        }
      },

      
      {
        name: "shopName",
        label: "Shop Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "orderTotal",
        label: "Order Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderTotal, tableMeta) => {
            return <div>
              <span>{orderTotal} KWD</span>
            </div>
          }
        }
      },
     
      {
        name: "reachedAtWarehouseProductCount",
        label: "Product Count",
        options: {
          filter: false,
          sort: true,
          display: false
        }
      },
      {
        name: "productCount",
        label: "Product Count",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (productCount, tableMeta) => {
            var data = tableMeta.rowData[4]
            // console.log("DAAT" , data);
            return <div>
              <span>{data + "/" + productCount}</span>
            </div>
            }
        }
      },
      {
        name: "deliveryStatus",
        label: "Status",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (deliveryStatus, tableMeta) => {
            return <div>
              <span className='Status'>{deliveryStatus}</span>
            </div>
          }
        }
      },
      {
        name: "shopId",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (shopId, tableMeta) => {
            var date = tableMeta.rowData[1]
            return <div>
              <Link
                  to={`/warehouse/orderByShop/details/${date}/${shopId}`}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top" title=""
                  data-original-title="Edit">
                <i className="f-20 icofont icofont-eye-alt text-custom"></i>
              </Link>
            </div>
          }
        }
      },
      
    ];
    const options = {
      filterType: "dropdown",
      filter:false,
      search:false,
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Orders Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };

    console.log(this.props)
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="Filters">
                    <div>
                      <input onChange={(e)=>this.setState({orderDate:e.target.value})} type="date" name="" id="" />
                      <input onChange={(e)=>this.setState({shopId:e.target.value})} type="text" name="" id="" placeholder='Shop ID'/>
                    </div>
                      <div>
                        <button onClick={this.OnSearch}>GO</button>
                      </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h5>{role === "warehouse"?"Ordres By Suppliers": "Bulk Orders"}</h5>
                    {/* <Link to={`/products/${this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"}/add`}
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Product
                    </Link> */}
                  </div>
                  <div className="card-block">
                    {/* <div className='ShopName'>
                      <input placeholder='Enter Shop Name' type="text" onChange={(e)=>this.setState({shopName:e.target.value})} value={this.state.shopName}/>
                    </div> */}
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={role === "warehouse"?"Ordres By Suppliers": "Bulk Orders"}
                        className="table-responsive"
                        data={this.state.Orders}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrdersByShop.propsTypes = {
    getOrderByShop: PropTypes.func.isRequire,
  deleteProduct: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getOrderByShop, deleteProduct })(OrdersByShop);
