import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from 'axios';
import Swal from "sweetalert2";
import Constant from "../../Constant";
import {addVariant, getAttribute} from '../../actions/homeAction'

export class VariantADD extends Component {
  state = {
    title:'',
    title_ar:'',
    about:'',
    bids:null,
    isHomePagePost:false,
    price:null,
    quantity:null,
    variants:[],
    imageArray: [],
    imageData: [],
    attValues:[],
    attributesStore:[],
    maxNumberOfAttribute:0,
    ProductID:'',
    postList:[],
    Edit_POST:null,
    imageData2: [],
  }
  
  onNextStep = (e) => {
    e.preventDefault()
   
        var data = {
            postId:this.props.match.params.ProductID,
            variants:this.state.variants
          };
      this.props.addVariant(data)
    
  };

  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  onAddAttribute=(variantsIndex)=>{
    let temp=this.state.variants
    let variants=temp[variantsIndex]
    let attributesStore=this.state.attributesStore
        attributesStore[variantsIndex].push({index:null})
    variants.attributes.push({attributeId:null,attributeOptionId:null})
    this.setState({variants:temp,attributesStore})
  }

  VariantHandleChange = (e,index,i) => {
    e.preventDefault()
    let temp=this.state.variants
    let attributesStore=this.state.attributesStore
    let variants=temp[index]
    if(e.target.name == 'attributeId'){
        
        variants.attributes[i].attributeId=e.target.value.split(' ')[0]

        let temp2=attributesStore[index][i]
        temp2.index=parseInt(e.target.value.split(' ')[1])
    }
    else if(e.target.name == 'attributeOptionId'){
      variants.attributes[i].attributeOptionId=e.target.value

    }
    else{

    if(e.target.value<0){variants[e.target.name]=0}
    else{variants[e.target.name]=e.target.value }
  }
    this.setState({variants:temp})
 } 


 onAddVariant = () => {
   let variants=this.state.variants
     var variant = { price:null, quantity:null,attributes:[{attributeId:'',attributeOptionId:'',}], }
     variants.push(variant)
   
   let attributesStore =this.state.attributesStore
     attributesStore.push([{index:null}])
     this.setState({variants})
 }

removeVariant = (index) => {
     let variants = this.state.variants
     let attributesStore=this.state.attributesStore
     attributesStore.splice(index,1)
     variants.splice(index,1)
       
     this.setState({variants,attributesStore})
 }
 
removeAttri = (INDEX,index) => {
  let variants = this.state.variants
    variants[index].attributes.splice(INDEX,1)
  let attributesStore=this.state.attributesStore
    attributesStore[index].splice(INDEX,1)
  this.setState({variants,attributesStore})
 }


  componentDidMount(){
  
    if( this.state.attributeList == undefined || 
       this.state.attributeList == null || 
        this.state.attributeList == 0){
             this.props.getAttribute()
     }

     this.setState({ProductID:this.props.match.params.ProductID})

     var data={
      bids:'', 
      price:'', 
      type:'Fixed', 
      status:'', 
      startRange:'', 
      recordLimit:'',
    }
    // this.props.getPost(data);
  }

 componentWillReceiveProps(nextProps){
     this.setState({attributeList:nextProps?.home?.attributeList})
   let arr=[]
   nextProps?.home?.attributeList.forEach(attribute=>{
      arr.push(attribute.AttributeOptions)
   })

   this.setState({ postList: nextProps.home.postList });
   var POST_LIST= nextProps.home.postList
//    this.GET_SELECTD_POST(POST_LIST)
 }

//  GET_SELECTD_POST = (POST_LIST) => {
   
//    if(this.props.match.params.ProductID){
//     var pID = this.props.match.params.ProductID
//     var FilteredPost = POST_LIST.filter(post=>post.id == pID)
//     var POST = FilteredPost[0]
//     console.log('POST',POST);
//     this.setState({
//       title:POST.title,
//       title_ar:POST.title_ar,
//       about:POST.about,
//       price:POST.price,
//       bids:POST.bids,
//       quantity:POST.quantity,
//       isHomePagePost:POST.isHomePagePost,
//       // variants:POST.Variants,
//     })
//     this.setState({imageData2:POST.Media})
//     this.setState({Edit_POST:POST})
//    }
    
//  }

render() {
//   console.log('ProductID',this.state.ProductID);
//   console.log('postList',this.state.postList);
//   console.log('imageData',this.state.imageData);
//   console.log('imageArray',this.state);
  console.log('this.props.attributesList',this.state.attributeList);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Variant {this.state.ProductID?'Edit':'Add'}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="page-header-breadcrumb">
                    <ul className="breadcrumb-title">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="feather icon-home"></i>{" "}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/post/add">Variant {this.state.ProductID?'Edit':'Add'}</Link>
                      </li>
                      <li className="breadcrumb-item active">Product Master</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <form onSubmit={this.onNextStep} className="row">
                        <div className="col-md-12">
                            <button onClick={()=>this.onAddVariant()}
                            className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                            >
                            <i className="icofont icofont-plus m-r-5"></i>Add Variant{" "}
                            </button>
                         </div>
                        <div className="col-md-12 row">
                            { 
                            this.state.variants && 
                              this.state.variants.length >0 ?
                                 this.state.variants.map((variant,index)=>{
                              return  <div className="ATTRIBUTE_OPTIONS row" key={index}>

                              <div className="col-md-6">
                                  <div className="form-group row">
                                      <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">Variant Price</label>
                                      <div className="col-sm-8">
                                          <input
                                             type="number"
                                              className="form-control"
                                              name="price"
                                              id="price"
                                              placeholder="Variant Price"
                                              onChange={e=> this.VariantHandleChange(e,index)}
                                              value={variant.price}
                                              required
                                          />
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="form-group row">
                                      <label htmlFor='hexCode'className="col-sm-4 col-form-label FONT_WEIGHT">Variant Quantity</label>
                                      <div className="col-sm-8">
                                          <input
                                              type="number"
                                              className="form-control"
                                              name="quantity"
                                              id="quantity"
                                              placeholder="Variant Quantity"
                                              onChange={e=> this.VariantHandleChange(e,index)}
                                              value={variant.quantity}
                                              required
                                          />
                                      </div>
                                  </div>
                              </div>

                          <div className="col-md-12">
                            <button onClick={()=>this.onAddAttribute(index)}
                            className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                            >
                            <i className="icofont icofont-plus m-r-5"></i>Add Attribute{" "}
                            </button>
                         </div>
                          <div className="col-md-12 row">
                            {variant.attributes.map((attribute,i)=>{
                              return  <div className="ATTRIBUTE_OPTIONS row" key={i}>
                              <div className="col-md-6">
                               <div className="form-group row">
                                 <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Name</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="attributeId" 
                                           className="form-control" 
                                           onChange={e=> this.VariantHandleChange(e,index,i)}
                                           value={`${attribute.attributeId+" "+this.state.attributesStore[index][i].index}`}
                                       >
                                           <option value="" name="select" >- Name -</option>
                                          {
                                            this.state.attributeList.map((attribute,key)=>{
                                              return <option value={`${attribute.id +" "+key}`} id={key}>
                                                {attribute.name}
                                              </option>
                                            })
                                          }
                                       </select>
                                       
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-6">
                               <div className="form-group row">
                                   <label htmlFor='valueType' className="col-sm-4 col-form-label FONT_WEIGHT">Attribute Option</label>
                                   <div className="col-sm-8">
                                       <select 
                                           name="attributeOptionId" 
                                           className="form-control" 
                                           onChange={e=> this.VariantHandleChange(e,index,i)}
                                           value={attribute.attributeOptionId}
                                      >
                                           <option value="" name="select" >- Option -</option>
                                           
                                           {this.state.attributesStore[index][i].index  &&  this.state.attributeList[this.state.attributesStore[index][i].index] ?
                                            
                                             this.state.attributeList[this.state.attributesStore[index][i].index].AttributeOptions?.map((attribute,key)=>{
                                              return <option value={attribute.AttributeId}>
                                                {attribute.name}
                                              </option>
                                            })
                                            :null
                                          }
                                       </select>
                                   </div>
                               </div>
                           </div>
                              <div className="col-md-12 ATT_REM_BTN2">
                                    <button onClick={()=> this.removeAttri(i,index)}>x</button>
                                </div>
                                
                          </div>
                            })}
                            
                        </div>


                              <div className="col-md-12 ATT_REM_BTN"  style={{marginTop:'1rem'}}>
                                    <button onClick={()=> this.removeVariant(index)}>Remove Variant</button>
                                </div>
                              
                          </div>
                            })
                            :null
                          }
                            
                        </div>
                         <div className="col-md-12 DATA_SAVE">
                            <div>
                                  <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                            
                              <Link to={"/"} className="btn btn-outline-dark">
                                Cancel
                              </Link>
                            </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{
          console.log(this.state.variants,this.state.attributesStore)
        }
      </div>
    );
  }
}

VariantADD.propsTypes = {
  addVariant: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state
});
export default connect(mapStateToProps, {

    addVariant,
  getAttribute
  })(VariantADD);
