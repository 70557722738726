import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import Constant from "../../../Constant";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import { getallUsers } from "../../../actions/homeAction";
import axios from "axios";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class UserList extends React.Component {
  state = {
  };

  handleStatusChange = (id)=> {
    var data = {
      UserId:id
    }
    axios.post(Constant.getAPI()+'/admin/toggleuserstatus',data, config).then(res=>{
      if(res.data.status){
        var DATA={
          startRange:'0',
          count:"1000"
        }
        this.props.getallUsers(DATA);
      }
    })
  }

  componentWillMount() {
    var data={
      startRange:'0',
      count:"1000"
    }
    this.props.getallUsers(data);
  }
  componentWillReceiveProps(nextProps){
    this.setState({userList:nextProps?.home?.userList})
  }
  render() {
    const columns = [
      {
        name: "image",
        label: "User Image",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (image, tableMeta) => {
            return (
              <img
                src={
                  image !== undefined && image !== null && image !== {}
                    ? image.url !== undefined &&
                    image.url !== null &&
                    image.url !== ""
                      ? image.url
                      : "./assets/images/icon.png"
                    : "./assets/images/icon.png"
                }
                className="img-fluid img-20"
                alt=""
                onError={this.imgLoadError}
              />
            );
          },
        },
      },
      {
        name: "userName",
        label: "Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Mobile No",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "email",
        label: "Email",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "deviceName",
        label: "Device Name",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "countryCode",
        label: "Country Code",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (status, tableMeta) => {
            var Id = tableMeta.rowData[7];
            // console.log(tableMeta.rowData[6]);
            return (
              <Toggle
                id={"status_" + Id}
                checked={status}
                value={status}
                onChange={this.handleStatusChange.bind(this, Id)}
              />
            );
          },
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                   to={"/user/add/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
                {/* <span
                 // onClick={this.deleteUser.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span> */}
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>User List</h5>
                    {/* <Link
                      to="/user/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                      data-modal="modal-13"
                    >
                      {" "}
                      <i className="icofont icofont-plus m-r-5"></i> Add User
                    </Link> */}
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"User List"}
                        className="table-responsive"
                        data={this.state.userList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserList.propsTypes = {
  getallUsers: PropTypes.func.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getallUsers })(UserList);
