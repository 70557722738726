import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getWalletList, deleteProduct } from '../../actions/homeAction'
import Constant from "../../Constant";
import axios from 'axios';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class WalletList extends React.Component {
  state = {
    ProductList: [],
    shopName:'',
  }
  componentWillMount() {
  
      var data = {
        startRange: '0',
        count: "10",
      }
    this.props.getWalletList(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ walletList: nextProps?.home?.walletList })
  }

//   componentDidUpdate(prevProps,prevState) {
//     if (this.state.shopName !== prevState.shopName) {
//       console.log("Do something");
//       var Product = prevProps.home.ProductData
//       var Filtered = Product.filter(data=>data.shop.shopName.toUpperCase().includes(this.state.shopName.toUpperCase()))
//       console.log('Filtered',Filtered);
//       this.setState({ProductList: Filtered})
//     }
//   }


  handleStatusChange = (id,status)=> {
    var data = {
      id:id,
      status:!status,
    }
    axios.post(Constant.getAPI()+'/admin/changeStatusSendMoneyToBank',data, config).then(res=>{
      if(res.data.status){
        var data = {
            startRange: '0',
            count: "10",
          }
        this.props.getWalletList(data);
      }
    })
  }

  render() {
    const columns = [
      {
        name: "User.firstName",
        label: "Firstname",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "User.lastName",
        label: "Lastname",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "User.email",
        label: "Email",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "User.mobileNumber",
        label: "Mobile No.",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "IBAN",
        label: "IBAN No.",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "bankName",
        label: "Bank Name",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "serviceCharge",
        label: "Service Charge",
        options: {
          filter: false,
          sort: true
        }
      },
     
      {
        name: "subTotal",
        label: "Sub Total",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "total",
        label: "Total",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "id",
        label: "id",
        options: {
          filter: false,
          sort: true,
          display:false
        }
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (status, tableMeta) => {
            var Id = tableMeta.rowData[9];
            // console.log(tableMeta.rowData[6]);
            return (
              <Toggle
                id={"status_" + Id}
                checked={status}
                value={status}
                onChange={this.handleStatusChange.bind(this, Id,status)}
              />
            );
          },
        },
      },
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };

    console.log(this.props)
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Wallet List</h5>
                    {/* <Link to={`/products/${this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"}/add`}
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Product
                    </Link> */}
                  </div>
                  <div className="card-block">
                    {/* <div className='ShopName'>
                      <input placeholder='Enter Shop Name' type="text" onChange={(e)=>this.setState({shopName:e.target.value})} value={this.state.shopName}/>
                    </div> */}
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title='Wallet List'
                        className="table-responsive"
                        data={this.state.walletList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WalletList.propsTypes = {
  getWalletList: PropTypes.func.isRequire,
  deleteProduct: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getWalletList, deleteProduct })(WalletList);
