import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import $ from "jquery";
import Constant from "../../../Constant";
import { getSeller, updateSeller } from "../../../actions/homeAction";

class SellerAdd extends React.Component {
  state = {
    status: "active",
    shop_name:'',
    seller_name:'',
    bussinessType:'',
    phoneNo:'',
    email:'',
    civilId:'',
    address:'',
    licence:'',
    bank:'',
    iban_number:'',
    about:'',
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  componentWillMount() {
    var data={
      startRange:'0',
      count:"1000"
    }
    this.props.getSeller(data);
  }

  componentWillReceiveProps(nextProps){
    this.setState({sellerList:nextProps?.home?.sellerList})
    this.getSellerDetails(nextProps?.home?.sellerList)
  }

  getSellerDetails = (Data) =>{
    var SELLER = Data?Data.filter(data=>data.id==this.props.seller_id)[0]:[]
    console.log('SELLER',SELLER);
    if(SELLER){
      this.setState({
        about: SELLER.about,
        address:SELLER.storeDetails.address,
        shop_name:SELLER.storeDetails.shopName,
        bank:SELLER.storeDetails.bankName,
        iban_number:SELLER.storeDetails.IBAN,
        civilId:SELLER.storeDetails.civilId,
        type:SELLER.storeDetails.type,
      })
    }
  }

  onSaveData = () => {
    if(this.props.seller_id){
      var data = {
          shopName:this.state.shop_name,
          type:this.state.shop_name,
          address:this.state.address,
          aboutBusiness:this.state.about,
          bankName:this.state.bank,
          IBAN:this.state.iban_number,
          licence:this.state.shop_name,
          civilId:this.state.civilId,
      }
      this.props.updateSeller(data)
    }
  }

  render() {
    console.log('this.props.seller_id',this.props.seller_id);
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
           
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Shop Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="shop_name"
                    id="shop_name"
                    placeholder="Shop Name"
                    onChange={this.handleChange}
                    value={this.state.shop_name}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Seller Type</label>
                <div className="col-sm-9">
                  <select className="form-control"
                    name="type"
                    id="type"
                    placeholder="Seller Type"
                    onChange={this.handleChange}
                    value={this.state.type}>
                    <option value="Store">Store</option>
                    <option value="Home">Home</option>
                  </select>
                </div>
              </div>
            </div>
           
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Civil Id</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="civilId"
                    id="civilId"
                    placeholder="Civil Id Number "
                    onChange={this.handleChange}
                    value={this.state.civilId}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Licence No </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="licence"
                    id="licence"
                    placeholder="Licence No "
                    onChange={this.handleChange}
                    value={this.state.licence}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Address</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    id="address"
                    placeholder="Address"
                    onChange={this.handleChange}
                    value={this.state.address}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="form-group row">
              <label className="col-sm-3 col-form-label">Bank</label>
              <div className="col-sm-9">
                <input
                    type="text"
                    className="form-control"
                    name="bank"
                    id="bank"
                    placeholder="Bank Name"
                    onChange={this.handleChange}
                    value={this.state.bank}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">IBAN Number</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="iban_number"
                    id="iban_number"
                    placeholder="IBAN Number"
                    onChange={this.handleChange}
                    value={this.state.iban_number}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">About Business</label>
                <div className="col-sm-9">
                  <textarea
                    type="text"
                    className="form-control"
                    name="about"
                    id="about"
                    placeholder="About Business"
                    onChange={this.handleChange}
                    value={this.state.about}
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="row">
                <div className="col-sm-3">
                  User Image
                </div>
                <div className="col-sm-9">
                  <form id="userImage" name="userImage" encType="multipart/form-data" className="text-capitalize">

                    <div className="form-group">

                      <input accept="image/*" onChange={this.handleImageUpload} id="user_Image" type="file" className="form-control" autoComplete="off" name="media[]"
                        data-toggle="tooltip" title="Click To Upload Photo"
                      />
                      <div id="id_image_section_lable" className="pt-2">
                        {
                          this.state.image_url
                            ?
                            this.state.image_url !== null || this.state.image_url !== undefined || this.state.image_url !== {}
                              ?
                              <img src={this.state.image_url} alt=""
                                className="img-100" onError={e => {
                                  e.target.src = ""
                                }} />
                              :
                              ''
                            :
                            ''
                        }
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-addon" id="basic-addon4">Status</span>
                <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                  <option value="active" name="active">Active</option>
                  <option value="inactive" name="inactive">Inactive</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="row float-right p-3">
            {
              this.state.isSaving
                ?
                <button className="btn btn-info mr-2" disabled>Saving...!</button>
                :
                <button onClick={this.onSaveData} className="btn btn-info mr-2">Save</button>
            }
            <Link to={"/seller"} className="btn btn-outline-dark">
              Cancel
        </Link>
          </div>
        </div>
      </div >
    );
  }
}

SellerAdd.propsTypes = {
  getSeller: PropTypes.func.isRequired,
  updateSeller: PropTypes.func.isRequired,
};


var mapStateToProps = (state) => ({
  home: state,
});


export default connect(mapStateToProps, { getSeller, updateSeller })(SellerAdd);

