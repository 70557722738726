import React from "react";
import { Link } from "react-router-dom";
import Constant from "../../Constant";
import Swal from "sweetalert2";
import MUIDataTable from "mui-datatables";
import axios from 'axios'
import {getAttribute} from '../../actions/homeAction'
import PropTypes from "prop-types";
import { connect } from "react-redux";

class AttributeLIST extends React.Component {
  state = {
    attributeList: [],
  };

  deleteAttribute = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this !",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        var that = this;
        var data = {
        attributeId:id
        }
        fetch(Constant.getAPI() + "/attribute/deleteattribute", {
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("loged_In_auth"),
          },
          body:JSON.stringify(data)
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (json) {
            if (json.status === true) {
              Swal.fire("Deleted!", "Attribute Deleted.", "success");
              that.props.getAttribute();
            } else {
              Swal.fire({
                title: "Something went wrong. Try again after some Time.!",
                icon: "error",
                text: "",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    });
  };


  componentWillMount() {
        this.props.getAttribute();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ attributeList: nextProps?.home?.attributeList });
        console.log('attributeList',nextProps?.home?.attributeList);
    }

  render() {
    const columns = [
      {
        name: "name",
        label: "Attribute Name (En)",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "name_ar",
        label: "Attribute Name (Ar)",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "AttributeOptions",
        label: "Attribute Options",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (AttributeOptions, tableMeta) => {
            return (
              <div>
                {AttributeOptions !== null &&
                AttributeOptions !== [] &&
                AttributeOptions !== undefined &&
                AttributeOptions.length > 0 ? (
                  <ul>
                    {AttributeOptions.map((Options,i) => (
                      <li key={Options.id}><b>{i+1} - </b> {Options.name} / {Options.name_ar}</li>
                    ))}
                  </ul>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <span
                  onClick={this.deleteAttribute.bind(this, id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                  style={{cursor:'pointer'}}
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Attribute Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };
    console.log('this.props.attributes_list',this.state.attributeList);
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="page-header">
              <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="page-header-title">
                    <div className="d-inline">
                      <h4>Attribute List</h4>
                    </div>
                  </div>
                </div>
                <Link
                    to="/attribute/Add"
                    className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-plus m-r-5"></i> Add Attribute{" "}
                  </Link>
                  <Link
                    to="/"
                    className="btn btn-sm btn-outline-dark waves-effect waves-light f-right d-inline-block md-trigger mx-3"
                    data-modal="modal-13"
                  >
                    {" "}
                    <i className="icofont icofont-arrow-left m-r-5"></i> Back{" "}
                  </Link>
              </div>
            </div>
            <div className="page-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-block">
                      <div className="dt-responsive table-responsive">
                        <MUIDataTable
                          title={"Attribute List"}
                          className="table-responsive"
                          data={this.state.attributeList}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
    );
  }
}

AttributeLIST.propsTypes = {
    getAttribute: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
   home: state
});
export default connect(mapStateToProps, {

    getAttribute
  
  })(AttributeLIST);
