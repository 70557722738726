import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOrderStatus, addOrderStatus, updateOrderStatus } from "../../actions/homeAction";

class OrderStatusAdd extends React.Component {
  state = {
    status: '',
    displayStatus: '',
  };

  componentWillMount() {
    if (this.props?.orderstatus_id) {
      this.props.getOrderStatus();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props?.orderstatus_id) {
      const matchItem = nextProps?.home?.orderStatus?.filter(itm => itm.id === this.props?.orderstatus_id);
      if (matchItem?.length) {
        const itm = matchItem[0];
        this.setState({
          ...itm
        })
      }
    }
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  updateOrderStatusData = () => {
    var data = {
      orderStatusId: this.props.orderstatus_id,
      status: this.state.status,
      displayStatus: this.state.displayStatus,
    }
    this.props.updateOrderStatus(data)
  };

  addOrder = () => {
    var data = {
      status: this.state.status,
      displayStatus: this.state.displayStatus,
    }
    this.props.addOrderStatus(data);
  };
  onSaveData = () => {
    var that = this;
    if (that.props.orderstatus_id !== undefined) {

      that.updateOrderStatusData();
    }
    else {
      that.addOrder();
    }
  };
  render() {
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Status
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="status"
                      id=""
                      placeholder="Status Name"
                      onChange={this.handleChange}
                      value={this.state.status}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Display Status
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="displayStatus"
                      id="displayStatus"
                      placeholder="displayStatus"
                      onChange={this.handleChange}
                      value={this.state.displayStatus}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                  <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                    <option value="true" name="active">Active</option>
                    <option value="false" name="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div> */}
            </div>
            <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (

                  <button
                    onClick={this.onSaveData}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={"/order-status"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

OrderStatusAdd.propsTypes = {
  getOrderStatus: PropTypes.func.isRequired,
  addOrderStatus: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired,
};

var mapStateToProps = (state) => ({
  home: state
});

export default connect(mapStateToProps, { getOrderStatus, addOrderStatus, updateOrderStatus })(
  OrderStatusAdd
);
