import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import Axios from "axios";
import Constant from "../../Constant";
import axios from "axios";
import { GET_DRIVER_LIST } from "../../actions/types";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

const OrderMasterDetails = () => {
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [driverList, setDriver] = useState([]);
  const role = localStorage.getItem("role");
  useEffect(() => {
    getRolList();
  }, []);

  const getRolList = () => {
    getOrderDetails();
  };

  useEffect(() => {
    const fetchDriverList = async () => {
      try {
        const data = {
          isActive: true,
          startRange: 0,
        };
        const res = await axios.post(
          Constant.getAPI() + "/delivery/driver/list",
          data,
          config
        );
        dispatch({
          type: GET_DRIVER_LIST,
          payload: res.data.data,
        });
        setDriver(res.data.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDriverList();
  }, []);

  const changeStatus = (e) => {
    const { value, name } = e.target;
    var data = {
      orderIds: params?.id,
      deliveryStatusId: value,
    };
    Axios.post(
      Constant.getAPI() + "/warehouse/changeDeliveryStatus",
      data,
      config
    )
      .then((res) => {
        if (res.data.status) {
          getOrderDetails();
          Swal.fire({
            title: "Order master status has been Changed",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while changing status.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  const assignedDriver = (e) => {
    const { value, name } = e.target;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
      },
    };
    var data = {
      driverId: value,
      orderId: params?.id,
    };
    Axios.post(Constant.getAPI() + "/delivery/assignDriverV2", data, config)
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Driver has been Assigned",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while assigning driver.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  const vendorAssignedDriver = (e, itemId) => {
    const { value } = e.target;
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
      },
    };
    var data = {
      driverId: value,
      orderId: itemId,
    };
    Axios.post(
      Constant.getAPI() + "/delivery/vendorAssignDriverV2",
      data,
      config
    )
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Driver has been Assigned",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while assigning driver.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  const getOrderDetails = () => {
    let setUrl = "";
    let adminUrl = "/warehouse/backend-orderDetails";
    let vendorUrl = "/warehouse/backend-vendor-orderDetails";
    let data = {};
    if (type == "M") {
      setUrl = adminUrl;
      data = {
        orderMasterId: params?.id,
      };
    } else {
      setUrl = vendorUrl;
      data = {
        orderId: params?.id,
      };
    }
    Axios.post(Constant.getAPI() + setUrl, data, config)
      .then((res) => {
        setOrderDetails(res.data.data ? res.data.data : {});
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "An error occurred while fetching order details.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  const groupedItems = orderDetails?.items?.reduce((acc, item) => {
    const sellerId = item.storeDetails?.id;
    const shopName = item.storeDetails?.shopName;
    if (!acc[shopName]) {
      acc[shopName] = {
        items: [],
        sellerId: sellerId,
      };
    }
    acc[shopName].items.push(item);
    return acc;
  }, {});

  const [shopStatus, setShopStatus] = useState(
    Object.keys(groupedItems || {}).reduce((acc, shopName) => {
      acc[shopName] = "Pending";
      return acc;
    }, {})
  );

  const handleStatusChange = (shopName, newStatusId, newStatusName) => {
    let orderId = groupedItems[shopName].items[0].orderId;
    let sellerId = groupedItems[shopName].sellerId;
    const DATA = {
      sellerId: sellerId,
      orderId: orderId,
      deliveryStatusId: newStatusId,
    };
    Axios.post(
      Constant.getAPI() + "/warehouse/changeSellerDeliveryStatus",
      DATA,
      config
    )
      .then((res) => {
        setOrderDetails(res.data.data ? res.data.data : {});
        if (res.data.status) {
          getOrderDetails();
          Swal.fire({
            title: "Order status has been Changed",
            type: "success",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/images/test.png",
            imageHeight: 100,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log("Error:", err);
        Swal.fire({
          title: "Error",
          text: "An error occurred while changing status.",
          icon: "error",
          confirmButtonColor: "#3ab1f7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });

    setShopStatus((prevStatus) => ({
      ...prevStatus,
      [shopName]: newStatusName,
    }));
  };

  const handleAssignChange = () => {};

  useEffect(() => {
    var subTotal = 0;
    if (orderDetails?.items !== undefined) {
      orderDetails?.items?.forEach((value) => {
        subTotal = subTotal + parseInt(value?.totalPrice);
      });
      setSubTotal(subTotal); // Set the state with the calculated subTotal
    }
  }, [orderDetails]);

  // const generatePDF = () => {
  //   // Function to generate PDF
  // };

  return (
    <div className="page-content-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="invoice-title">
                      <h3 className="m-0">
                        <img
                          src="/assets/images/newlogo.png"
                          alt="logo"
                          height="40"
                        />
                      </h3>
                      <h4 className="pull-right font-16">
                        <strong>OrderID #{orderDetails?.invoiceNumber}</strong>
                      </h4>
                      <h4 className="pull-right font-16">
                        <strong>Order Date: </strong>
                        {moment(orderDetails?.created).format("MMMM Do YYYY")}
                      </h4>
                    </div>
                    <hr></hr>
                    <div className="invoice-main">
                      <div>
                        <address>
                          <strong>Customer Details</strong>
                          <br></br>
                          <b>Username: </b> {orderDetails?.User?.userName}
                          <br></br>
                          <b>Email: </b> {orderDetails?.User?.email}
                          <br></br>
                          <b>Mobile No: </b> {orderDetails?.User?.mobileNumber}
                          <br></br>
                        </address>
                      </div>

                      <div>
                        <address>
                          <strong>Customer Address</strong>
                          <br></br>
                          <b>Country: </b>
                          {
                            orderDetails?.addressDetails?.City?.State?.Country
                              ?.name
                          }
                          <br></br>
                          <b>State: </b>
                          {orderDetails?.addressDetails?.City?.State?.name}
                          <br></br>
                          <b>Area: </b>
                          {orderDetails?.addressDetails?.name}
                          <br></br>
                          <b>Type: </b>
                          {orderDetails?.addressDetails?.label}
                          <br></br>
                          <b>Avenue: </b>
                          {orderDetails?.addressDetails?.avenue}
                          <br></br>
                          <b>Block: </b>
                          {orderDetails?.addressDetails?.block}
                          <br></br>
                          <b>Flat: </b>
                          {orderDetails?.addressDetails?.flat}
                          <br></br>
                          <b>Additional direction: </b>
                          {orderDetails?.addressDetails?.direction}
                          <br></br>
                        </address>
                      </div>

                      <div>
                        <address>
                          <strong>Payment Details</strong>
                          <br></br>
                          <b>Payment Type :</b> {orderDetails?.paymentType}
                          <br></br>
                          <b>Refund Status :</b> {orderDetails?.refundStatus}
                          <br></br>
                          <b>Payment Status :</b> {orderDetails?.paymentStatus}
                          <br></br>
                          <b>Order Status :</b> {orderDetails?.orderStatus}
                          <br></br>
                        </address>
                      </div>
                      <div>
                        <address>
                          <strong>Driver Details</strong>
                          <br></br>
                          <b>Master Driver :</b>{" "}
                          {orderDetails?.masterDriverInfo
                            ? `${orderDetails.masterDriverInfo.firstName} ${orderDetails.masterDriverInfo.lastName}`
                            : "N/A"}
                          <br></br>
                          {orderDetails?.vendorDriverInfo ? (
                            orderDetails?.vendorDriverInfo.map(
                              (driver, index) => (
                                <div key={index}>
                                  <b>Vendor Driver {index + 1} :</b>{" "}
                                  {driver
                                    ? driver?.firstName +
                                        " " +
                                        driver?.lastName || "N/A"
                                    : "N/A"}
                                  <br></br>
                                </div>
                              )
                            )
                          ) : (
                            <b>Vendor Driver : N/A</b>
                          )}
                        </address>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 m-t-30 text-right"></div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                <div className="col-12">
                  <div className="panel panel-default">
                    <div className="p-2">
                      <h3 className="panel-title font-20">
                        <strong>Order summary</strong>
                      </h3>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <td>
                                <strong>Products</strong>
                              </td>
                              <td className="text-center">
                                <strong>Name</strong>
                              </td>
                              <td className="text-center">
                                <strong>Shop Name</strong>
                              </td>
                              <td className="text-right">
                                <strong>Price</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {orderDetails?.items !== undefined
                              ? orderDetails?.items.map((data) => (
                                  <tr>
                                    <td className="no-line">
                                      <img
                                        height="40px"
                                        src={data.Media[0].url}
                                        alt=""
                                      />
                                    </td>
                                    <td className="no-line text-center">
                                      {data.title}
                                    </td>
                                    <td className="no-line text-center">
                                      {data.storeDetails.shopName}
                                    </td>
                                    <td className="no-line text-right">
                                      {data.totalPrice} KWD
                                    </td>
                                  </tr>
                                ))
                              : null}
                            <tr>
                              <td className="thick-line"></td>
                              <td className="thick-line"></td>
                              <td className="thick-line text-center">
                                <strong>SUB TOTAL</strong>
                              </td>
                              <td className="thick-line text-right">
                                {subTotal} KWD
                              </td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>DELIVERY CHARGE</strong>
                              </td>
                              <td className="no-line text-right">
                                {orderDetails?.deliveryCharge}
                              </td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>GRAND TOTAL </strong>
                              </td>
                              <td className="no-line text-right">
                                <h4 className="m-0">
                                  {isNaN(
                                    subTotal + orderDetails?.deliveryCharge
                                  )
                                    ? "NaN"
                                    : subTotal + orderDetails?.deliveryCharge}
                                </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="d-print-none">
                        <div className="pull-right g-1">
                          <a
                            onClick={generatePDF}
                            className="btn btn-success waves-effect waves-light"
                          >
                            <i className="fa fa-print"></i>
                          </a>
                          <Link
                            to="/orders"
                            className="btn btn-primary waves-effect waves-light"
                            style={{ marginLeft: "5px" }}
                          >
                            Cancel
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

                <div className="row">
                  <div className="col-12">
                    <div className="panel panel-default">
                      <div className="p-2">
                        <h3 className="panel-title font-20">
                          <strong>Order summary</strong>
                        </h3>
                      </div>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <td>
                                <strong>Products</strong>
                              </td>
                              <td className="text-center">
                                <strong>Name</strong>
                              </td>
                              <td className="text-center">
                                <strong>Shop Name</strong>
                              </td>
                              <td className="text-right">
                                <strong>Price</strong>
                              </td>
                              <td className="text-right">
                                <strong>Status</strong>
                              </td>
                              {/* {orderDetails.orderStatusId ===
                                "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" && (
                                <td className="text-right">
                                  <strong>Assignee</strong>
                                </td>
                              )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {groupedItems &&
                              Object.keys(groupedItems).map(
                                (shopName, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td
                                        colSpan="6"
                                        className="text-center font-weight-bold"
                                      >
                                        {/* {shopName} */}
                                      </td>
                                    </tr>
                                    {groupedItems[shopName].items.map(
                                      (item, idx) => (
                                        <tr key={idx}>
                                          <td className="no-line">
                                            <img
                                              height="40px"
                                              src={item.Media[0].url}
                                              alt=""
                                            />
                                          </td>
                                          <td className="no-line text-center">
                                            {item.title}
                                          </td>
                                          <td className="no-line text-center">
                                            {shopName}
                                          </td>
                                          <td className="no-line text-right">
                                            {item.totalPrice} KWD
                                          </td>
                                          {idx === 0 && (
                                            <>
                                              <td
                                                rowSpan={
                                                  groupedItems[shopName].items
                                                    .length
                                                }
                                                className="no-line text-right"
                                              >
                                                <select
                                                  onChange={(e) =>
                                                    handleStatusChange(
                                                      shopName,
                                                      e.target.value,
                                                      e.target[
                                                        e.target.selectedIndex
                                                      ].text
                                                    )
                                                  }
                                                  value={item.statusId}
                                                >
                                                  <option value="">
                                                    - Select Status -
                                                  </option>
                                                  {orderDetails.vendorStatusList.map(
                                                    (status, id) => (
                                                      <option
                                                        key={id}
                                                        value={status.id}
                                                      >
                                                        {status.id ===
                                                        "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                                                          ? "At store"
                                                          : status.displayStatus}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </td>
                                              <td
                                                rowSpan={
                                                  groupedItems[shopName].items
                                                    .length
                                                }
                                                className="no-line text-right"
                                              >
                                                {item.statusId ===
                                                  "54f95aa1-e90d-4eac-a0e0-eeb8640f535b" && (
                                                  <select
                                                    className="orderInvoiceSelect"
                                                    id="driver-select"
                                                    onChange={(e) =>
                                                      vendorAssignedDriver(
                                                        e,
                                                        item.orderId
                                                      )
                                                    }
                                                    value={item.driverId}
                                                  >
                                                    <option value="">
                                                      - Select Driver -
                                                    </option>
                                                    {driverList &&
                                                      driverList.map(
                                                        (driver) => (
                                                          <option
                                                            key={driver.id}
                                                            value={driver.id}
                                                          >
                                                            {driver.firstName}{" "}
                                                            {driver.lastName}
                                                          </option>
                                                        )
                                                      )}
                                                  </select>
                                                )}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            <tr>
                              <td className="thick-line"></td>
                              <td className="thick-line"></td>
                              <td className="thick-line text-center">
                                <strong>SUB TOTAL</strong>
                              </td>
                              <td className="thick-line text-right">
                                {subTotal} KWD
                              </td>
                              <td className="thick-line"></td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>DELIVERY CHARGE</strong> KWD
                              </td>
                              <td className="no-line text-right">
                                {orderDetails?.deliveryCharge}
                              </td>
                              <td className="no-line"></td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>DISCOUNT AMOUNT</strong>
                              </td>
                              <td className="no-line text-right">
                                {orderDetails?.discountAmount} KWD
                              </td>
                              <td className="no-line"></td>
                            </tr>
                            <tr>
                              <td className="no-line"></td>
                              <td className="no-line"></td>
                              <td className="no-line text-center">
                                <strong>GRAND TOTAL</strong>
                              </td>
                              <td className="no-line text-right">
                                <h4 className="m-0">
                                  {orderDetails?.grandTotal} KWD
                                </h4>
                              </td>
                              <td className="no-line"></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {role !== "vendor" && type == "M" ? (
                  <div className="order-status-container d-flex printHideDiv">
                    <div className="pr-3">
                      <strong>Order Status</strong>
                      <select
                        className="orderInvoiceSelect"
                        onChange={changeStatus}
                        value={orderDetails.orderStatusId}
                      >
                        <option value="">- Select Status -</option>
                        {orderDetails.orderStatusList &&
                          orderDetails.orderStatusList.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.id ===
                              "b6b76fe2-1081-4ae4-ae0d-55f11aa716af"
                                ? "At store"
                                : data.displayStatus}
                            </option>
                          ))}
                      </select>
                    </div>
                    {orderDetails.orderStatusId ==
                    "4cc48c4c-baed-426d-9a6d-a913b7f7719f" ? (
                      <div>
                        <strong>Assign Driver</strong>
                        <select
                          className="orderInvoiceSelect"
                          id="driver-select"
                          onChange={assignedDriver}
                          value={orderDetails.masterDriverId}
                        >
                          <option value="">- Select Driver -</option>
                          {driverList &&
                            driverList.map((driver) => (
                              <option key={driver.id} value={driver.id}>
                                {/* <pre>{JSON.stringify(driver)}</pre> */}
                                {driver.firstName} {driver.lastName}
                              </option>
                            ))}
                        </select>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderMasterDetails;
