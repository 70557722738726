/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Toggle from "react-toggle";
import { getTimeSlots ,TimeSlotDelete, updateTimeSlot} from "../../actions/homeAction";

class TimeSlotList extends React.Component {

  state = {
    timeSlotList: []
  };

  componentWillMount() {
    var data = {
      type:'all'
    }
    this.props.getTimeSlots(data);
  }


  componentWillReceiveProps(nextProps) {
    
    this.setState({ timeSlotList: nextProps?.home?.timeSlotList });
  }

  deleteTimeslot = (id) => {
    
    var data={
      slotId:id
    }
    this.props.TimeSlotDelete(data)
  };

  handleStatusChange=(id, type)=>{
    var data={
      slotId:id,
      isActive:!type,
    }
    this.props.updateTimeSlot(data)
  }

  render() {
    const columns = [
      {
        name: "slot",
        label: "Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "minHour",
        label: "Min Hour",
        options: {
          filter: true,
          sort: true,
        },
      },
      
      {
        name: "isActive",
        label: "Status",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (isActive, tableMeta) => {
            var Id = tableMeta.rowData[6];
            // console.log(tableMeta.rowData[6]);
            return (
              <Toggle
                id={"status_" + isActive}
                checked={isActive}
                value={isActive}
                // onChange={()=>this.handleStatusChange(Id,isActive)}
              />
            );
          },
        },
      },
      {
        name: "id",
        label: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/timeSlot/add/" + id}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-ui-edit text-custom"></i>
                </Link>
                {/* <span
                  onClick={()=>this.deleteTimeslot(id)}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                >
                  <i className="f-20 icofont icofont-delete-alt text-danger"></i>{" "}
                </span> */}
              </div>
            );
          },
        },
      },
    ];
    const options = {
      filter: false,
      viewColumns: false,
      responsive: "scrollMaxHeight",
      search: false,
      print: false,
      pagination: true,
      selectableRows: "none",
      download: false,
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Time Slots List</h5>
                    <Link
                      to="/timeSlot/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                      data-modal="modal-13"
                    >
                      {" "}
                      <i className="icofont icofont-plus m-r-5"></i> Add
                      Time Slot
                    </Link>
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Time Slots List"}
                        className="table-responsive"
                        data={this.state.timeSlotList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TimeSlotList.propsTypes = {
    getTimeSlots: PropTypes.func.isRequired,
    TimeSlotDelete: PropTypes.func.isRequired,
    updateTimeSlot: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  
  return {
    home: state
  }
}

export default connect(mapStateToProps, { getTimeSlots, TimeSlotDelete,updateTimeSlot})(TimeSlotList);
