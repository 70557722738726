import React from "react";
import Constant from "../Constant.js";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};
class ShopAdminDashboard extends React.Component {
  state = {
    todaysTotalOrders: 0,
    todaySales: 0,
    productCount: 0,
    totalProductPending: 0,
  };

  componentWillMount() {
    var DateStamp = new Date().getTime();
    var data = {
      startDate: DateStamp,
      endDate: DateStamp,
    };
    this.getShopAdminDashboardStats(data);
  }

  getShopAdminDashboardStats = async (data) => {
    var that = this;
    await axios
      .post(Constant.getAPI() + "/warehouse/warehouseDashboard", data, config)
      .then((res) => {
        that.setState({ DashBoard: res?.data?.data });
      })
      .catch((err) => {
        Swal.fire({
          title:
            err?.response?.data?.data?.details?.[0]?.message ||
            err?.response?.data?.message,
          icon: "error",
          text: "",
          type: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  };

  render() {
    return (
      // <div className="page-content-wrapper px-3">
      //   <div className="container-fluid">
      //     <div className="page-wrapper">

      //       <div className="page-header">
      //         <div className="row align-items-end">
      //           <div className="col-lg-8">
      //             <div className="page-header-title">
      //               <div className="d-inline">
      //                 <h4 className="text-center">Dashboard</h4>
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //       <div className="page-body">
      //         <div className="row">
      //           <div className="col-xl-3 col-md-6">

      //             <div class="mini-stat clearfix bg-white">
      //               <span class="mini-stat-icon bg-purple mr-0 float-right" style={{ color: '#000' }}>
      //                 <i className="icofont icofont-coins f-28"></i>
      //               </span>
      //               <div class="mini-stat-info">
      //                 <span class="counter text-purple">{this.state.DashBoard?.todaySales} KWD</span> Today's Sales</div>
      //               <div class="clearfix"></div>
      //               <p class=" mb-0 m-t-20 text-muted"> Total Sales: KWD {this.state.DashBoard?.todaySales} </p>
      //             </div>
      //           </div>

      //           <div className="col-xl-3 col-md-6">
      //             <div class="mini-stat clearfix bg-white">
      //               <span class="mini-stat-icon bg-purple mr-0 float-right" style={{ color: '#000' }}>
      //                 <i className="icofont icofont-shopping-cart f-28"></i>
      //               </span>
      //               <div class="mini-stat-info">
      //                 <span class="counter text-purple">{this.state.DashBoard?.todaysTotalOrders}</span> Total Orders Today</div>
      //               <div class="clearfix"></div>
      //               <p class=" mb-0 m-t-20 text-muted"> Total Orders Today {this.state.DashBoard?.todaysTotalOrders} </p>
      //             </div>
      //           </div>
      //           <div className="col-xl-3 col-md-6">
      //             <div className="card">
      //               <div className="card-block">
      //                 <div className="row align-items-center">
      //                   <div className="col-8">
      //                     <h4 className="text-c-pink f-w-600">{this.state.totalProductPending}</h4>
      //                     <h6 className="text-muted m-b-0">Pending Products</h6>
      //                   </div>
      //                   <div className="col-4 text-right">
      //                     <i className="feather icon-alert-triangle f-28"></i>
      //                   </div>
      //                 </div>
      //               </div>
      //               <div className="card-footer bg-c-pink">
      //               </div>
      //             </div>
      //           </div>
      //           <div className="col-xl-3 col-md-6">
      //             <div class="mini-stat clearfix bg-white">
      //               <span class="mini-stat-icon bg-purple mr-0 float-right" style={{ color: '#000' }}>
      //                 <i className="feather icon-award f-28"></i>
      //               </span>
      //               <div class="mini-stat-info">
      //                 <span class="counter text-purple">{this.state.DashBoard?.totalVendorOrderCount}</span> Total vendor orders</div>
      //               <div class="clearfix"></div>
      //               <p class=" mb-0 m-t-20 text-muted"> Total vendor orders {this.state.DashBoard?.totalVendorOrderCount} </p>
      //             </div>
      //           </div>
      //           <div className="col-xl-3 col-md-6">
      //             <div class="mini-stat clearfix bg-white">
      //               <span class="mini-stat-icon bg-purple mr-0 float-right" style={{ color: '#000' }}>
      //                 <i className="feather icon-award f-28"></i>
      //               </span>
      //               <div class="mini-stat-info">
      //                 <span class="counter text-purple">{this.state.DashBoard?.totalMasterOrderCount}</span> Total master orders</div>
      //               <div class="clearfix"></div>
      //               <p class=" mb-0 m-t-20 text-muted"> Total master orders {this.state.DashBoard?.totalMasterOrderCount} </p>
      //             </div>
      //           </div>
      //           {/* <div className="col-xl-3 col-md-6">
      //             <Link to={"/warehouse/orderByCustomers"}>
      //               <div className="card">
      //                 <div className="card-block">
      //                   <div className="row align-items-center">
      //                     <div className="col-8">
      //                       <h6 className="text-amazon m-b-0">Order List</h6>
      //                     </div>
      //                     <div className="col-4 text-right">
      //                       <i className="icofont icofont-cart-alt f-28"></i>
      //                     </div>
      //                   </div>
      //                 </div>
      //               </div>
      //             </Link>
      //           </div> */}
      //         </div>
      //         <div className="row">
      //           <div className="col-xl-3 col-md-6">
      //             <Link to={"/category"}>
      //               <div className="card">
      //                 <div className="card-block">
      //                   <div className="row align-items-center">
      //                     <div className="col-8">
      //                       <h6 className="text-amazon m-b-0">Category List</h6>
      //                     </div>
      //                     <div className="col-4 text-right">
      //                       <i className="feather icon-jfi-view-grid f-28"></i>
      //                     </div>
      //                   </div>
      //                 </div>
      //               </div>
      //             </Link>
      //           </div>
      //           <div className="col-xl-3 col-md-6">
      //             <Link to={"/warehouse/orderByCustomers"}>
      //               <div className="card">
      //                 <div className="card-block">
      //                   <div className="row align-items-center">
      //                     <div className="col-8">
      //                       <h6 className="text-amazon m-b-0">Order List</h6>
      //                     </div>
      //                     <div className="col-4 text-right">
      //                       <i className="icofont icofont-cart-alt f-28"></i>
      //                     </div>
      //                   </div>
      //                 </div>
      //               </div>
      //             </Link>
      //           </div>
      //         </div>
      //       </div>
      //     </div>

      //     <div id="styleSelector">
      //     </div>

      //   </div>
      // </div>

      <div className="page-content-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center">
                <h3 className="text-blue">Warehouse Dashboard Panel</h3>
              </div>
            </div>
          </div>
          <div className="row mr-2 ml-2 mt-4 mb-4">
            <div className="col-md-12">
              <div className="clearfix bg-white text-center">
                <div
                  className="d-flex justify-content-center align-items-center p-3"
                  style={{ background: "#d0d8e3" }}
                >
                  <h5 className="text-blue">Master Order By Status</h5>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Total Order</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/warehouse/allOrder?type='M'" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {12}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Ready for Delivery (Unassigned)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/readyForDeliveryUnassigned" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Ready for Delivery (Assigned)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/readyForDeliveryAssigned" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Out For Delivery</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/outForDelivery" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Not Delivered</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/notDelivered" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Cancelled</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/cancelled" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mr-2 ml-2 mt-4 mb-4">
            <div className="col-md-12">
              <div className="clearfix bg-white text-center">
                <div
                  className="d-flex justify-content-center align-items-center p-3"
                  style={{ background: "#d0d8e3" }}
                >
                  <h5 className="text-blue">Vendor Order Status</h5>
                </div>
                <div className="d-flex flex-wrap justify-content-between">
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Total Vendor</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/warehouse/allOrder?type='S'" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {10}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Ready For Pickup (Unassigned)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/readyForPickupUnassignedVendor" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Ready for Pickup (Assigned)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/readyForPickupAssignedVendor" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Enroute to Warehouse</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/enrouteWarehouse" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Reached at Warehouse (Not Delivered)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{
                          pathname: "/reachedAtWarehouseUnDeliveredVendor",
                        }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                  <div
                    className="mini-stat widget-chart-sm bg-white text-center shadow d-flex flex-column justify-content-between align-items-center"
                    style={{ width: "16%" }}
                  >
                    <p className="mb-0">Reached at Warehouse (Delivered)</p>
                    <h3 className="mt-0" style={{ fontSize: "2rem", fontWeight: "bold" }}>
                      <Link
                        to={{ pathname: "/reachedAtWarehouseDeliveredVendor" }}
                        className="text-dark"
                        style={{ textDecoration: "underline" }}
                      >
                        {0}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShopAdminDashboard;
