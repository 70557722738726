import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getWinners } from '../../actions/homeAction'
import Constant from "../../Constant";
import axios from 'axios';

class Winners extends React.Component {
  state = {
    ProductList: [],
    shopName:'',
  }
  componentWillMount() {
      var data = {
        postId:this.props.match.params.postId
      }
    
    this.props.getWinners(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ winnersList: nextProps?.home?.winnersList})
  }


  deleteProducts = (id) => {

    var data = {
      postId: id
    }
    this.props.deleteProduct(data)
  };


  render() {
    const columns = [
      
      {
        name: "isWinner",
        label: "Result",
        options: {
          filter: false,
          sort: true,
          customBodyRender:(isWinner)=>{
            return <div>
              {isWinner?<span style={{color:'green'}}><b>WINNER</b></span>:<span style={{color:'red'}}>LOSE</span>}
            </div>
          }
        }
      },
      {
        name: "User.userName",
        label: "User Name",
        options: {
          filter: false,
          sort: true
        }
      },

      {
        name: "User.firstName",
        label: "First Name",
        options: {
          filter: false,
          sort: true
        }
      },

      {
        name: "User.lastName",
        label: "Last Name",
        options: {
          filter: false,
          sort: true
        }
      },

      {
        name: "User.mobileNumber",
        label: "Mobile",
        options: {
          filter: false,
          sort: true
        }
      },

      {
        name: "User.email",
        label: "E-Mail",
        options: {
          filter: false,
          sort: true
        }
      },
      
      
     
      
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };

    console.log(this.props)
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Auction Participants</h5>
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title="Auction Participants"
                        className="table-responsive"
                        data={this.state.winnersList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Winners.propsTypes = {
getWinners: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getWinners })(Winners);
