import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getProduct} from '../../actions/homeAction'

export class VariantLIST extends Component {
    state = {
        ProductID:'',
        VariantARR:[],
    }

    componentWillMount() {
        this.setState({
            ProductID:this.props.match.params.ProductID
        })
      
            var data = {
              startRange: '0',
              count: "10000",
              type: "Fixed",
              all: "1"
            }
          
          this.props.getProduct(data);
    }
        
    componentWillReceiveProps(nextProps) {
        var data = nextProps?.home?.ProductData.filter(d=>d.id==this.state.ProductID)[0].Variants
        var name = nextProps?.home?.ProductData.filter(d=>d.id==this.state.ProductID)[0].title
        this.setState({VariantARR:data,Title:name})
        console.log('data',data);
      }

  render() {
    const columns = [
        {
            name: "price",
            label: "Price",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (price, tableMeta) => {

                    return <div>
                        <p>{this.state.Title}</p>
                    </div>
                }
            }
        },
        {
            name: "VariantAttributes",
            label: "Variant Combination",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (VariantAttributes, tableMeta) => {

                    return <div>
                        {VariantAttributes?.map((itm,i1) => {
                            var Data = itm?.Attribute?.AttributeOptions.filter(d=>d.id==itm.AttributeOptionId)[0].name
                            // console.log('Data', Data);
                         return(
                            <div>
                                <span>{itm?.Attribute?.name}:- <b>{Data}</b></span>
                            </div>
                            )}
                        )}
                    </div>
                }
            }
        },
        {
            name: "price",
            label: "Price",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (price, tableMeta) => {

                    return <div>
                        <b>{price.toFixed(3)} KWD</b>
                    </div>
                }
            }
        },
        {
            name: "quantity",
            label: "Quantity",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (quantity, tableMeta) => {

                    return <div>
                        <b>{quantity}</b>
                    </div>
                }
            }
        },
    ]
    const options = {
        filterType: "dropdown",
        viewColumns: false,
        print: false,
        download: false,
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: this.state.isSaving ?
                    "Loading data..!" :
                    "Sorry, No Variants Found",
                toolTip: "Sort",
                columnHeaderTooltip: column => `Sort for ${column.label}`
            }
        }
    };
    return (
        <div className="pcoded-inner-content">
        <div className="main-body">
            <div className="page-wrapper">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Variants List</h5>
                                <Link to={`/variantDetails/${this.state.ProductID}`} className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13">
                                     <i className="icofont icofont-plus m-r-5"></i> Add Variant
                                </Link>
                            </div>
                            <div className="card-block">
                                <div className="dt-responsive table-responsive">
                                    <MUIDataTable
                                        title={`Variants List`}
                                        className="table-responsive"
                                        data={this.state.VariantARR}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
  }
}
VariantLIST.propsTypes = {
    getProduct: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
    
  };
  
  var mapStateToProps = (state) => ({
    home: state
  });
  export default connect(mapStateToProps, {
  
    getProduct,
    })(VariantLIST);
  
