import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  getAll,
  getOrderStatus,
  getOrderByReadyForPickup,
} from "../../actions/homeAction";
import axios from "axios";
import Swal from "sweetalert2";
import Constant from "../../Constant";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class ReadyForPickupOrders extends React.Component {
  state = {
    Orders: [],
    orderStatusList: [],
    orderStatus: "",
    orderDate: "",
    orderId: "",
    type: "",
    invoiceNumber: "",
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchData();
    }
  }

  getTypeFromURL() {
    const currentUrl = window.location.href;
    const hashIndex = currentUrl.indexOf("#");
    let queryParams = "";

    if (hashIndex !== -1) {
      const hashPart = currentUrl.substring(hashIndex + 1);
      const queryIndex = hashPart.indexOf("?");
      if (queryIndex !== -1) {
        queryParams = hashPart.substring(queryIndex);
      }
    }

    const params = new URLSearchParams(queryParams);
    return params.get("type");
  }

  fetchData() {
    this.props.getOrderStatus();

    const currentUrl = window.location.href;
    const hashIndex = currentUrl.indexOf("#");
    let queryParams = "";

    if (hashIndex !== -1) {
      const hashPart = currentUrl.substring(hashIndex + 1);
      const queryIndex = hashPart.indexOf("?");
      if (queryIndex !== -1) {
        queryParams = hashPart.substring(queryIndex);
      }
    }

    const params = new URLSearchParams(queryParams);
    const type = params.get("type");
    this.state.type = type;

    const data = {
      isMultiVendorDelivery: 1,
      startRange: 0,
    };

    if (type === "M") {
      data.deliveryStatusId = "54f95aa1-e90d-4eac-a0e0-eeb8640f535b";
      this.props.getAll(data);
    } else {
      this.props.getOrderByReadyForPickup(data);
    }
  }

  OnSearch = () => {
    let type = this.state.type;
    var invoiceNumber = this.state.invoiceNumber
    var data = {
      isMultiVendorDelivery: 0,
      startRange: 0,
      invoiceNumber: invoiceNumber,
    }
    if (type === "M") {
      data.deliveryStatusId = "54f95aa1-e90d-4eac-a0e0-eeb8640f535b";
      this.props.getAll(data);
    } else {
      this.props.getOrderByReadyForPickup(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    let type = this.state.type;
    this.setState({
      Orders:
        type == "M"
          ? nextProps?.home?.getAll
          : nextProps?.home?.readyForPickupOrders,
      orderStatusList: nextProps?.home?.orderStatus,
    });
  }

  onChangeStatus = (orderId, statusId) => {
    const data = {
      orderIds: orderId,
      deliveryStatusId: statusId,
    };
    axios
      .post(
        `${Constant.getAPI()}/warehouse/changeSellerDeliveryStatus`,
        data,
        config
      )
      .then((res) => {
        let type = this.state.type;
        if (type == "M") {
          this.props.getAll();
        } else {
          this.props.getOrderByReadyForPickup();
        }
        if (res.data.status) {
          Swal.fire({
            title: "Status has been Changed",
            type: "success",
            imageUrl: "./assets/zeed-logo.png",
            imageHeight: 70,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: "You Are Not Authorised",
            type: "error",
            imageUrl: "./assets/zeed-logo.png",
            imageHeight: 70,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        if (err) {
          Swal.fire({
            title: err?.response?.data?.error,
            type: "error",
            imageUrl: "./assets/zeed-logo.png",
            imageHeight: 70,
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  render() {
    const columns = [
      // {
      //   name: "dateTime",
      //   label: "No.",
      //   options: {
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (dateTime, tableMeta) => {
      //       return <span>{tableMeta.rowIndex + 1}</span>;
      //     },
      //   },
      // },
      {
        name: "invoiceNumber",
        label: "OrderID",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "dateTime",
        label: "Date-Time",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (dateTime) => {
            return (
              <span>{moment(dateTime).format("DD-MMM-YYYY | hh:mm A")}</span>
            );
          },
        },
      },
      {
        name: "firstName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },
      {
        name: "lastName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (lastName, tableMeta) => {
            const fName = tableMeta.rowData[2];
            return <span>{`${fName} ${lastName || ""}`}</span>;
          },
        },
      },
      {
        name: "customerEmail",
        label: "Customer Email",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "mobileNumber",
        label: "Phone",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "orderTotal",
        label: "Order Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderTotal) => {
            return <span>{`${orderTotal} KWD`}</span>;
          },
        },
      },
      // {
      //   name: "paymentMode",
      //   label: "Payment Mode",
      //   options: {
      //     filter: false,
      //     sort: true,
      //   },
      // },
      {
        name: "status",
        label: "Order Status",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderStatus) => {
            let color = "red";
            let displayStatus = orderStatus;

            if (orderStatus === "Placed") {
              color = "green";
            } else if (orderStatus === "Reached at warehouse") {
              color = "blue";
              displayStatus = "At store";
            }

            return (
              <b style={{ color, marginTop: "0", marginBottom: 0, fontWeight: "600" }}>
                {displayStatus}
              </b>
            );
          },
        },
      },
      // {
      //   name: "id",
      //   label: "Action",
      //   options: {
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (id, tableMeta) => {
      //       return (
      //         <div>
      //           <Link
      //             to={"/warehouse/orderMasterDetails/" + id}
      //             className="m-r-15 text-muted"
      //             data-toggle="tooltip"
      //             data-placement="top"
      //             title=""
      //             data-original-title="Edit"
      //           >
      //             <i className="f-20 icofont icofont-eye-alt text-custom"></i>
      //           </Link>
      //         </div>
      //       );
      //     },
      //   },
      // },
    ];
    if (this.state.type === "M") {
      columns.push({
        name: "id",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return (
              <div>
                <Link
                  to={`/warehouse/orderMasterDetails/${id}?type=${this.state.type}`}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-eye-alt text-custom"></i>
                </Link>
              </div>
            );
          },
        },
      });
    } else {
      columns.push({
        name: "orderId",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderId, tableMeta) => {
            return (
              <div>
                <Link
                  to={`/warehouse/orderMasterDetails/${orderId}?type=${this.state.type}`}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-eye-alt text-custom"></i>
                </Link>
              </div>
            );
          },
        },
      });
    }

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      filter: false,
      search: false,
      print: false,
      download: false,
      selectableRows: "none",
      textLabels: {
        body: {
          noMatch: this.state.isSaving
            ? "Loading data..!"
            : "Sorry, No Orders Found",
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };

    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                {/* <div className="card">
                  <div className="Filters">
                    <div>
                      <input
                        onChange={(e) =>
                          this.setState({ invoiceNumber: e.target.value })
                        }
                        type="text"
                        placeholder="Order ID"
                      />
                    </div>
                    <div>
                      <button onClick={this.OnSearch}>GO</button>
                    </div>
                  </div>
                </div> */}
                {/* <div className="card">
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        className="table-responsive"
                        data={this.state.Orders}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div> */}
                <div className="card">
                  <div className="card-body">
                    <div className="Filters w-100" style={{ justifyContent: "end" }}>
                      <div style={{ width: "auto" }}>
                        <input
                          onChange={(e) =>
                            this.setState({ invoiceNumber: e.target.value })
                          }
                          type="text"
                          name=""
                          id=""
                          placeholder="Search by OrderID"
                        />
                      </div>
                      <div>
                        <button onClick={this.OnSearch}>GO</button>
                      </div>
                    </div>
                    <table className="table  table-hover">
                      <thead>
                        <tr>
                          <th style={{ border: "0", padding: "0" }}>
                            <div className="dt-responsive table-responsive table-bordered">
                              <MUIDataTable
                                // title="All"
                                className="table-responsive"
                                data={this.state.Orders}
                                columns={columns}
                                options={options}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReadyForPickupOrders.propsTypes = {
  getAll: PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  getOrderStatus: PropTypes.func.isRequired,
  getOrderByReadyForPickup: PropTypes.func.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, {
  getAll,
  getOrderStatus,
  getOrderByReadyForPickup,
})(ReadyForPickupOrders);
