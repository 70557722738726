import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addTimeSlot, getTimeSlots ,updateTimeSlot} from "../../actions/homeAction";

class TimeSlotAdd extends React.Component {
  state = {
    slot: "",
    isActive:'',
    timeSlotList:[]
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillMount() {
    if(this.props.match.params.slotId){
      var data = {
        type:'all'
      }
      this.props.getTimeSlots(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    var Data = nextProps?.home?.timeSlotList
    var edit = Data.filter(d=>d.id==this.props.match.params.slotId)
    console.log('Data',edit);
    this.setState({
      slot:edit[0].slot,
      isActive:edit[0].isActive==true?'true':'false'
    })
  }


  onSaveData = () => {
    if(this.props.match.params.slotId){
      const data = {
        slotId:this.props.match.params.slotId,
        slot: this.state.slot,
        isActive: this.state.isActive=='true'?true:false,
      };
      this.props.updateTimeSlot(data);
    }else{
      const data = {
        slot: this.state.slot,
        isActive: this.state.isActive=='true'?true:false,
      };
      this.props.addTimeSlot(data);
    }
   
  };

  
 
  render() {
    return (<>
        <div className="main-body">
        <div className="page-wrapper">
          <div className="page-header">
            <div className="row align-items-end">
              <div className="col-lg-8">
                <div className="page-header-title">
                  <div className="d-inline">
                    <h4>
                      {this.props.match.params.package_id ? "Edit" : "Add"}{" "}
                      Time Slot 
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="page-header-breadcrumb">
                  <ul className="breadcrumb-title">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="feather icon-home"></i>{" "}
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/timeSlot">Time Slot List</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      {this.props.match.params.package_id ? "Edit" : "Add"}{" "}
                      Time Slot  
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-body">
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-border-default">
                  <div className="card-block">
                    <div className="tab-content tabs">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Slot Timing</label>
                                <div className="col-sm-9">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="slot"
                                    placeholder="Time Slot"
                                    onChange={this.handleChange}
                                    value={this.state.slot}
                                />
                                </div>
                            </div>
                            </div>

                            <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label">Status</label>
                                <div className="col-sm-9">
                                  <select 
                                  className="form-control"
                                  name="isActive" 
                                  id="isActive"
                                  onChange={this.handleChange}
                                  value={this.state.isActive}
                                  >
                                    <option value=''>- Select -</option>
                                    <option value={true}>Active</option>
                                    <option value={false}>Not Active</option>
                                  </select>
                                </div>
                            </div>
                            </div>

                        </div>

                        <div className="card-footer">
                            <div className="row float-right p-1">
                            {this.props.category_id ? (
                                this.state.isLoading ? (
                                <button className="btn btn-grd-disabled mr-2" disabled>
                                    Saving...!
                                </button>
                                ) : (
                                <button
                                    onClick={this.updateCategoryData}
                                    className="btn btn-grd-disabled mr-2 "
                                >
                                    Update
                                </button>
                                )
                            ) : this.props.isLoading ? (
                                <button className="btn btn-grd-disabled mr-2" disabled>
                                Saving...!
                                </button>
                            ) : (
                                <button
                                onClick={this.onSaveData}
                                className="btn btn-grd-disabled mr-2"
                                >
                                <i className="icofont icofont-save"></i> Save
                                </button>
                            )}
                            <Link to={"/timeSlot"} className="btn btn-outline-dark">
                                Cancel
                            </Link>
                            </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    
    );
  }
}

TimeSlotAdd.propsTypes = {
    addTimeSlot: PropTypes.func.isRequired,
    getTimeSlots: PropTypes.func.isRequired,
    updateTimeSlot: PropTypes.func.isRequired,
  home: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { addTimeSlot, getTimeSlots, updateTimeSlot })(TimeSlotAdd);
