import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../Loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addProducts, getCategory, getProduct, updateProduct } from "../../actions/homeAction";
import $ from "jquery";
import Constant from "../../Constant";


class ProductAdd extends React.Component {
  state = {
    categoryList: [],
    categoryId:'',
    postBaseType: "liveBidding",
    title: "",
    price: "",
    quantity: "",
    about: "",
    type: "Fixed",
    location: "",
    media: "",
    imageurl: "",
    Edit: false,
    image:[],
    Data:[]
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ categoryList: nextProps?.home?.categoryList });

    if (this.props.product_id) {
      const matchItem = nextProps?.home?.ProductData?.filter(itm => itm.id === this.props?.product_id);
      
      if (matchItem?.length) {
        var Data = matchItem[0]
        const itm = matchItem[0].Media;
        
        console.log('itm',itm);
        var Data2 = itm.map(data=>data.url)
            Data2 = new Set(Data2)
        var media2 = [...Data2]
        console.log('media2',media2)
        this.setState({
          categoryId:Data.CategoryId,
          postBaseType: Data.postBaseType,
          title: Data.title,
          price: Data.price,
          quantity: Data.quantity,
          about: Data.about,
          type: Data.type,
          location: Data.location,
          Edit: true,
          image: media2,
        })
      }
    }
    
    
  };
  componentWillMount() {
    this.props.getCategory();
    if (this.props?.location?.pathname === "/products/Fixed" ? "Fixed" : false) {
      var data = {
        startRange: '0',
        count: "10000",
        type: "Fixed",
        all: "1"
      }
    }
    else {
      data = {
        startRange: 0,
        count: 10000,
        type: "Auction",
        all: "1"
      }
    }
    this.props.getProduct(data);
  }
  componentDidMount() {
    console.log(this.props)
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleImageUpload = (event) => {
    document.getElementById('banner_image_lable').innerHTML = "";
    let element = $("#banner_image").get(0);
    $("#banner_image").empty();
    this.setState({ accepted: element });
    var proof_img = [];
    let obj = {};
    console.log(element.files);
    this.setState({ banner_image: element.files });
    for (var i = 0; i < element.files.length; i++) {
      var file1 = element.files[i];
      var img = document.createElement("img");
      img.className = "img-100";
      var filePath = URL.createObjectURL(file1);
      img.src = filePath;
      $("#banner_image_lable").append(img);
    }
  }

  onSave = () => {
    var that = this;
    that.setState({ isSaving: true });
    if (that.state.accepted) {
      that.uploadMedia();
    } else {
      if (that.props.product_id !== undefined) {
        that.updateProductData();
      } else {
        that.addProduct();
      }
    }
  }

  uploadMedia = () => {
    var that = this;

    var form = $('#userImage')[0];
    var data = new FormData(form);

    fetch(Constant.getAPI() + "/upload/image", {
      method: "post",
      body: data
    }).then(function (response) {
      return response.json();
    }).then(function (json) {

      if (json.status === true) {
        console.log('KSPS',json.data);
        if (that.props.user_id !== undefined) {
          that.updateUserData(json.data[0].id);
        } else {
          that.addProduct(json.data[0].id);
        }
      } else {
        // that.setState({ category_data: [] });
        console.log(json.error);
      }
    });
  }
  updateProductData = (images) => {
    var details = {
      postId: this.props.product_id,
      media: images,
      postBaseType: this.state.postBaseType,
      title: this.state.title,
      price: this.state.price,
      quantity: this.state.quantity,
      about: this.state.about,
      type: this.state.type,
      location: this.state.location
    }
    this.props.updateProduct(details)
  }

  addProduct = (images) => {
    var details = {
      media: images,
      postBaseType: this.state.postBaseType,
      title: this.state.title,
      price: this.state.price,
      quantity: this.state.quantity,
      about: this.state.about,
      type: this.props.type,
      categoryId:this.state.categoryId,
      location: this.state.location

    }
    this.props.addProducts(details)
  }
  render() {
    // debugger
    console.log('image',this.state.image);
    return (
      <div className="">
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Title
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      id="title"
                      placeholder="title"
                      onChange={this.handleChange}
                      value={this.state.title}
                    />
                  </div>
                </div>
              </div>
              {/* {this.props.type ==='auction' ?
              <>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Auction Live 
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      name="auctionLive"
                      id="auctionLive"
                      placeholder="Time auction is Live"
                      onChange={this.handleChange}
                      value={this.state.auctionLive}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Auction Live Date
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      id="date"
                      placeholder="Date for auction"
                      onChange={this.handleChange}
                      value={this.state.date}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                  Auction Live Time
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="time"
                      className="form-control"
                      name="time"
                      id="time"
                      placeholder="Time for auction"
                      onChange={this.handleChange}
                      value={this.state.time}
                    />
                  </div>
                </div>
              </div>
              </>
              :''}   */}
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Category
                  </label>
                  <div className="col-sm-9">
                    <select
                      name="categoryId"
                      className="form-control form-control-inverse"
                      onChange={this.handleChange}
                      value={this.state.categoryId}
                    >
                      <option value="">Select Category</option>
                      {this.state.categoryList !== undefined &&
                        this.state.categoryList !== null &&
                        this.state.categoryList !== [] &&
                        this.state.categoryList.length > 0
                        ? this.state.categoryList.map((itm) => (
                          <option
                            value={itm.id}
                            key={itm.id}
                          >
                            {itm.name}
                          </option>
                        ))
                        : null}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    About
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="about"
                      id="about"
                      placeholder="about"
                      onChange={this.handleChange}
                      value={this.state.about}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Location
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      name="location"
                      id="location"
                      placeholder="location"
                      onChange={this.handleChange}
                      value={this.state.location}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Post Base type
                  </label>
                  <div className="col-sm-9">
                    <select 
                    name="postBaseType" 
                    id="postBaseType"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.postBaseType}
                    >
                      <option value="liveBidding">Live Bidding</option>
                      <option value="normalBidding">Normal Bidding</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Price
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      name="price"
                      id="price"
                      placeholder="Price"
                      onChange={this.handleChange}
                      value={this.state.price}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">
                    Quantity
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      id="quantity"
                      placeholder="quantity"
                      onChange={this.handleChange}
                      value={this.state.quantity}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Sell Type</label>
                  <div className="col-sm-9">
                    <select name="type" className="form-control" value={this.props.type} onChange={this.handleChange}>
                      <option value={"Fixed"} name="active">Fixed</option>
                      <option value={"Auction"} name="inactive">Auction</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Display Image</label>
                <UploadImage id={"customFile"} uploadGalleryImage={this.uploadImage}></UploadImage>
                <label class="custom-file-label" for="customFile">
                    {this.state.label}
                  </label>
              </div>
            </div> */}

              {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Status</label>
                <div className="col-sm-9">
                  <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                    <option value="true" name="active">Active</option>
                    <option value="false" name="inactive">Inactive</option>
                  </select>
                </div>
              </div>
            </div> */}
              {/* <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">Details</label>
                <div className="col-sm-9">
              <textarea className="form-control" name="details" id="address" placeholder="Details" onChange={this.handleChange} value={this.state.address} rows={3} />
              </div>
              </div>
              </div> */}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-sm-3">
                    Image
                  </div>
                  <div className="col-sm-9">
                    <form id="userImage" name="userImage" encType="multipart/form-data" className="text-capitalize">

                      <div className="form-group">

                        <input accept="image/*" onChange={this.handleImageUpload} id="banner_image" type="file" className="form-control" autoComplete="off" name="images"
                          data-toggle="tooltip" title="Click To Upload Photo"
                        />
                        <div id="banner_image_lable" className="pt-2">
                          {
                            this.state.image2
                              ?
                              this.state.image2 !== null || this.state.image2 !== undefined || this.state.image2 !== {}
                                ?
                                <img src={this.state.image2} alt=""
                                  className="img-100" onError={e => {
                                    e.target.src = ""
                                  }} />
                                :
                                ''
                              :
                              ''
                          }
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div id="ProductIMG" className="ProductIMG">
                  {this.state.image!==[]&&this.state.image!=undefined&&this.state.image!==null?this.state.image.map(media=>(
                    <>
                      <img src={media}  className="img-100" alt="" />
                    </>
                  )):null}
                </div>
              </div>

            </div>
            <div className="card-footer">
              <div className="row float-right p-3">
                {this.state.isSaving ? (
                  <button className="btn btn-grd-disabled mr-2" disabled>
                    Saving...!
                  </button>
                ) : (

                  <button
                    onClick={this.onSave}
                    className="btn btn-grd-disabled mr-2"
                  >
                    <i className="icofont icofont-save"></i> Save
                  </button>
                )}
                <Link to={this.props?.location && this.props?.location.includes('Fixed')? "/product/Fixed" : "/product/Auction"} className="btn btn-outline-dark">
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ProductAdd.propsTypes = {
  addProducts: PropTypes.func.isRequired,
  getProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  getCategory: PropTypes.func.isRequired,
  home: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { addProducts, updateProduct, getCategory, getProduct })(
  ProductAdd
);
