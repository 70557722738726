import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Toggle from 'react-toggle'
import { getNotifications } from '../../actions/homeAction'
import moment from 'moment'

class PushNotificationsList extends React.Component {
  state = {
    ReportPostList: []
  }
  
  componentWillMount() {
   this.props.getNotifications({ xPast:10})
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ Notification_list: nextProps?.home?.Notification })
  }

  render() {
    const columns = [
      {
        name: "type",
        label: 'Type',
        options: {
          filter: false,
          sort: true,
         
        }
      },
      {
      name: "text",
      label: "Text",
      options: {
        filter: false,
        sort: true,
       
      }
    },
    {
      name: "createdAt",
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender:(createdAt)=>{
          return <span>
              {moment(createdAt*1000).format('MM/DD/YYYY')}
          </span>
        }
      }
    },
   
    ];

    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card ">
                  <div className="card-header row">
                    <h5 className='col-lg-10'>Push Notifications</h5>
                    <Link
                      to="/sendNotifications"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger"
                      data-modal="modal-13"
                    >
                      {" "}
                      <i className="icofont icofont-plus m-r-5"></i>Send Notification{" "}
                    </Link>
                  </div>
                  
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Push Notifications"}
                        className="table-responsive"
                        data={this.state.Notification_list}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PushNotificationsList.propsTypes = {
  getNotifications: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getNotifications, })(PushNotificationsList);
