import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { getOrderDetailsByShop, getDriverList, getOrderStatus } from '../../actions/homeAction'
import Swal from "sweetalert2";
import Constant from "../../Constant";
import axios from 'axios';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { Modal } from 'react-responsive-modal';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

export class ExpressOrderDetailsByShop extends Component {

    state = {
        orderStatusList:[],
        orderByShops:[],
        orderStatus:'',
        AddressDetails:{},
        // selectDriver:'',
        open:false,
    }

    onOpenModal = () => this.setState({open:true});
    onCloseModal = () => this.setState({open:false});

    componentWillMount() {
      this.getOrderDetails()
    }

    getOrderDetails=()=>{
      var data = {
        shopId:this.props.match.params.id,
        isMultiVendorDelivery:0,
        date:this.props.match.params.date
      }
      this.props.getOrderDetailsByShop(data);
    }
    
    
    componentDidMount(){
      this.props.getOrderStatus()
        var data = {
            isActive:true,
            startRange:0,
            // count:10
        }
      this.props.getDriverList(data)
      }
      
    componentWillReceiveProps(nextProps) {
      this.setState({ orderByShops: nextProps?.home?.orderByShops })
      this.setState({ orderStatusList: nextProps?.home?.orderStatus})
      // this.setState({ driverList: nextProps?.home?.driverList})
      this.setState({selectDriver:nextProps?.home?.orderByShops?.driverId})
    }


      // onSaveData = ()=> {
      //   var data = {
      //     // driverId:this.state.selectDriver,
      //     shopId:this.props.match.params.id,
      //     date:this.props.match.params.date,
      //   }
      //   axios.post(Constant.getAPI()+'/delivery/assignDriver',data, config).then(res=>{
         
      //     if(res.data.status){
      //       Swal.fire({
      //         title: "Driver has been Assigned",
      //         type: "success",
      //         confirmButtonColor: "#3ab1f7",
      //         cancelButtonColor: "#d33",
      //         confirmButtonText: "Ok",
      //       });
      //     }else{
      //       Swal.fire({
      //         title: "You Are Not Authorised",
      //         type: "error",
      //         imageUrl: "./assets/images/test.png",
      //         imageHeight: 100,
      //         confirmButtonColor: "#3ab1f7",
      //         cancelButtonColor: "#d33",
      //         confirmButtonText: "Ok",
      //       });
      //     }
      //   }).then(err=>{
      //     console.log('err',err);
      //     if(err){
      //       Swal.fire({
      //         title: "You Are Not Authorised",
      //         type: "error",
      //         imageUrl: "./assets/images/test.png",
      //         imageHeight: 100,
      //         confirmButtonColor: "#3ab1f7",
      //         cancelButtonColor: "#d33",
      //         confirmButtonText: "Ok",
      //       });
      //     }
      //   })
      // }
    

    render() {
        console.log('driverList', this.state.driverList);

        const columns = [
            {
                name: "orderId",
                label: "No.",
                options: {
                  filter: false,
                  sort: true,
                  customBodyRender: (status, tableMeta) => {
                    var tab = tableMeta.rowIndex
                    return <div>
                      <span>{tab+1}</span>
                    </div>
                  }
                }
              },
          {
            name: "productDetails",
            label: "Product Image",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (productDetails, tableMeta) => {
                var media = productDetails.Media[0]
                // console.log('MMM',media);
                return <div className='ProductImage'>
                   
                        <img src={media?.url} alt="" />
                  
                    
                </div>
              }
            }
          },
    
          
          {
            name: "productDetails.title",
            label: "Product Name",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "productDetails.shopName",
            label: "Suppler Name",
            options: {
              filter: false,
              sort: true,
            }
          },
         
          {
            name: "",
            label: "Combination",
            options: {
              filter: false,
              sort: true
            }
          },
          {
            name: "quantity",
            label: "Quantity",
            options: {
              filter: false,
              sort: true,
            }
          },
          {
            name: "price",
            label: "Order Price",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (price, tableMeta) => {
                return <div>
                  <span>{price} KWD</span>
                </div>
              }
            }
          },
          {
            name: "",
            label: "Warehouse Status",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (deliveryStatus, tableMeta) => {
                return <div>
                  <span className='Status'>{deliveryStatus}</span>
                </div>
              }
            }
          },
          {
            name: "status",
            label: "Order Status",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (status, tableMeta) => {
                var orderid = tableMeta.rowData[0]
                console.log('orderid',orderid)
                return <div>
                  <select
                    value={this?.state?.orderStatusList?this?.state?.orderStatusList.filter(data=>data.displayStatus===status)[0]?.id:''}
                    id=""
                    onChange={(e)=>this.onChangeSTATUS(orderid,e.target.value)}
                    >
                       <option value=''>- Select -</option>
                      {this.state.orderStatusList!==undefined?this.state.orderStatusList.map(data=>(
                        <option value={data.id}>{data.displayStatus}</option>
                      )):null}
                    </select>
                </div>
              }
            }
          },
          {
            name: "addressDetails",
            options: {
              filter: false,
              sort: true,
              display: false,
            }
          },
          {
            name: "orderId",
            label: "Action",
            options: {
              filter: false,
              sort: true,
              customBodyRender: (orderId, tableMeta) => {
                var date = tableMeta.rowData[1]
                var AddDetails = tableMeta.rowData[9]
                return <div >
                  <i onClick={()=>{
                    this.onOpenModal()
                    this.setState({AddressDetails:AddDetails})
                  }} style={{cursor:'pointer'}} className="f-20 icofont icofont-eye-alt text-custom"></i>
                </div>
              }
            }
          },
          
        ];
        const options = {
          filterType: "dropdown",
          filter:false,
          search:false,
          viewColumns: false,
          print: false,
          download: false,
          selectableRows: 'none',
          textLabels: {
            
          }
        };

        console.log('AddressDetails',this.state.AddressDetails);
        console.log('orderByShops',this.state.orderByShops);

        return (
          this.state.orderByShops!==undefined && this.state.orderByShops!==null?<>
            <div className="pcoded-inner-content" >
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                    <Modal open={this.state.open} onClose={this.onCloseModal} center>
                        <div className='AddressModal'>
                          <p><b>Building Number :</b> {this.state.AddressDetails.flat}</p>
                          <p><b>Block :</b> {this.state.AddressDetails.block}</p>
                          <p><b>Street Name :</b> {this.state.AddressDetails.street}</p>
                          <p><b>Area Name :</b> {this.state.AddressDetails.address_line_1}</p>
                          <p><b>Special Note :</b> {this.state.AddressDetails.address_line_2}</p>
                          <p><b>City :</b> {this.state.AddressDetails?.City?.name}</p>
                          <p><b>State :</b> {this.state.AddressDetails?.City?.State?.name}</p>
                        </div>
                      </Modal>
                       {/* <div className='assignDriverModule'>
                            <label htmlFor="assignDriver">Assign Driver</label>
                            <select onChange={(e)=>this.setState({selectDriver:e.target.value})} value={this.state.selectDriver} name="" id="assignDriver">
                                <option value="">- Select Driver -</option>
                                {this.state.driverList!==undefined?this.state.driverList.map((driver,index)=>(
                                    <option key={index} value={driver.id}>{driver.firstName+" "+driver.lastName}</option>
                                )):null}
                            </select>
                       </div> */}
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h5>EXPRESS ORDER DETAILS</h5>
                        <Link to="/warehouse/express/orderByShop"
                          className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13">Back
                        </Link>
                      </div>
                      <div className="card-block">
                          <div className="row">
                              <div className="col-md-12 row" ref={el => (this.componentRef = el)}>
                              <div className="dt-responsive table-responsive">
                                <MUIDataTable
                                    className="table-responsive"
                                    data={this.state.orderByShops?.data!==[] && this.state.orderByShops?.data!== undefined?this.state.orderByShops?.data:[]}
                                    columns={columns}
                                    options={options}
                                />
                                </div>
                              </div>
                             <div className="card-footer col-sm-12">
                                <div className="row float-right p-1">
                                    {/* <ReactToPrint content={() => this.componentRef}>
                                            <PrintContextConsumer>
                                              {({ handlePrint }) => (
                                                <button className="btn btn-grd-disabled mr-2" onClick={handlePrint}>Print this out!</button>
                                              )}
                                            </PrintContextConsumer>
                                    </ReactToPrint> */}
                                   {!localStorage.getItem('role')=="admin"?null:<>
                                    {/* <button
                                      onClick={this.onSaveData}
                                      className="btn btn-grd-disabled mr-2"
                                    >
                                      <i className="icofont icofont-save"></i> Assign
                                    </button> */}
                              
                                  <Link to={"/warehouse/express/orderByShop"} className="btn btn-outline-dark">
                                    Cancel
                                  </Link></>}
                                </div>
                            </div>
                          </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>:null
         
        )
    }
}

ExpressOrderDetailsByShop.propsTypes = {
    getOrderDetailsByShop: PropTypes.func.isRequired,
    getOrderStatus: PropTypes.func.isRequired,
    getDriverList: PropTypes.func.isRequired
  };
  
  var mapStateToProps = (state) => ({
    home: state,
  });
  
  export default connect(mapStateToProps, { getOrderDetailsByShop, getDriverList, getOrderStatus })(ExpressOrderDetailsByShop);