import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getOrder, getOrderStatus } from '../../actions/homeAction'
import Swal from "sweetalert2";
import Constant from "../../Constant";
import axios from 'axios';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

export class OrderDetails extends Component {

    state = {
        orderList: [],
        orderDetails:{},
        orderStatus:'',
        subTotal:0
    }

    componentWillMount() {
    var data = {
        startRange: '0',
        count: "1000"
    }
    this.props.getOrder(data);
    }

    componentDidMount(){
      this.props.getOrderStatus()
      }
      
    componentWillReceiveProps(nextProps) {
    this.setState({ orderList: nextProps?.home?.orderList })
    this.setState({ orderStatusList: nextProps?.home?.orderStatus})
    this.getOrder(nextProps?.home?.orderList)
    }

    getOrder = (Data) =>{
        var ORDER = Data?Data.filter(data=>data.id==this.props.match.params.orderID)[0]:[]
        console.log('ORDER',ORDER);
        if(ORDER){
          this.setState({
            orderDetails:ORDER
          })
        }
        var subtotal = 0

        if(ORDER.items!==undefined){
          ORDER.items.forEach((value) => {
            subtotal = subtotal + parseInt(value.price);
          });
          
          this.setState({subTotal:subtotal});
        }
    
      }

      onSaveData = ()=> {
        if(localStorage.getItem('role')=='admin'){
          Swal.fire({
            title: "You Are Not Authorised",
            type: "warning",
            confirmButtonColor: "#3ab1f7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
          });
          return false
        }
        var data = {
          orderId:this.state.orderDetails.id,
          statusId:this.state.orderStatus,
        }
        axios.post(Constant.getAPI()+'/order/updateStatus',data, config).then(res=>{
         
          if(res.data.status){
            Swal.fire({
              title: "Status has been Changed",
              type: "success",
              imageUrl: "./assets/images/test.png",
              imageHeight: 1000,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }else{
            Swal.fire({
              title: "You Are Not Authorised",
              type: "error",
              imageUrl: "./assets/images/test.png",
              imageHeight: 100,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        }).then(err=>{
          console.log('err',err);
          if(err){
            Swal.fire({
              title: "You Are Not Authorised",
              type: "error",
              imageUrl: "./assets/images/test.png",
              imageHeight: 100,
              confirmButtonColor: "#3ab1f7",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
          }
        })
      }
    

    render() {
        console.log('ORDER',this.state.orderDetails);
        return (
          <>
            <div className="pcoded-inner-content" >
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>ORDER DETAILS</h5>
                        <Link to="/orders"
                          className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13">Back
                        </Link>
                      </div>
                      <div className="card-block">
                          <div className="row">
                              <div style={{marginTop:'2rem'}} className="col-md-12 row" ref={el => (this.componentRef = el)}>
                              {this.state.orderDetails.User!==undefined && this.state.orderDetails.User!==null? 
                              <div className="col-sm-12 USER_DETAILS">
                                <div>
                                    <span><b>Customer Name :</b> {this.state.orderDetails.User.userName}</span>
                                    <span><b>E-Mail :</b> {this.state.orderDetails.User.email}</span>
                                    <span><b>Mobile No. :</b> {this.state.orderDetails.User.mobileNumber}</span>

                                    <span><b>Address Type :</b> {this.state.orderDetails.addressDetails.label}</span>
                                    <span><b>Avenue :</b> {this.state.orderDetails.addressDetails.avenue}</span>
                                    <span><b>Block :</b> {this.state.orderDetails.addressDetails.block}</span>
                                    <span><b>Flat :</b> {this.state.orderDetails.addressDetails.flat}</span>
                                    <span><b>Area :</b> {this.state.orderDetails.addressDetails.name}</span>
                                </div>
                                <div>
                                    <span>
                                        <b>INVOICE NUMBER :</b> <b> {this.state.orderDetails.invoice_number}</b>
                                    </span>
                                    <span>
                                        <b>Payment Type :</b> {this.state.orderDetails.paymentType}
                                    </span>
                                    <span>
                                        <b>Refund Status :</b> {this.state.orderDetails.refundStatus}
                                    </span>
                                    <span>
                                        <b>Payment Status :</b>
                                         {this.state.orderDetails.paymentStatus=='Pending'?
                                         <b style={{marginLeft:'0.5rem',background:'#ffc93c',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{this.state.orderDetails.paymentStatus}</b>
                                         :<b style={{marginLeft:'0.5rem',background:'#00bbf0',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{this.state.orderDetails.paymentStatus}</b>}
                                    </span>
                                    <span>
                                        <b>Order Status :</b>
                                         {this.state.orderDetails.orderStatus=='Placed'?
                                         <b style={{marginLeft:'0.5rem',background:'#42b883',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{this.state.orderDetails.orderStatus}</b>
                                         :<b style={{marginLeft:'0.5rem',background:'#dc2f2f',color:'#fff',fontWeight:'bold', textTransform:'uppercase',padding:'0 0.3rem', borderRadius:'3px' }}>{this.state.orderDetails.orderStatus}</b>}
                                    </span>
                                    
                                </div>
                              </div>:null}
                             
                              <div className="col-sm-12">
                              <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Products</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Shop Name</th>
                                            <th scope="col">Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orderDetails.items!==undefined && this.state.orderDetails.items!==[]?this.state.orderDetails.items.map(data=>(
                                            <tr>
                                                <td><img height='40px' src={data.Media[0].url} alt="" /></td>
                                                <td>{data.title}</td>
                                                <td>{data.shopName}</td>
                                                <td><b>{data.totalPrice} KWD</b></td>
                                            </tr>
                                        )):null}
                                    
                                    </tbody>
                                </table>
                              </div>
                               <div className="col-sm-8 OrdrStatus">
                                {!localStorage.getItem('role')=="admin"?null: <select 
                                  name="orderStatus" 
                                  value={this.state.orderStatus}
                                  id=""
                                  onChange={(e)=>this.setState({orderStatus:e.target.value})}
                                  >
                                   <option value=""> - Select Status - </option>
                                   {/* <option value="At the Store">At the Store</option>
                                   <option value="Being Wrapped Up">Being Wrapped Up</option>
                                   <option value="On the Way">On the Way</option>
                                   <option value="Delivered">Delivered</option> */}
                                   {this.state.orderStatusList!==undefined?this.state.orderStatusList.map(data=>(
                                     <option value={data.id}>{data.displayStatus}</option>
                                   )):null}
                                 </select>}
                              </div>           
                              <div className="col-sm-4 TOTAL">
                                      <div>

                                      </div>
                                      <div>
                                        <span>
                                            <b>Sub Total :</b> <b>{this.state.subTotal} KWD</b>
                                        </span>
                                        <span>
                                            <b>Delivery Charge :</b> <b> {this.state.orderDetails.deliveryCharge} KWD</b>
                                        </span>
                                        
                                        <span>
                                            <b>Grand Total :</b> <b>{this.state.orderDetails.deliveryCharge+this.state.subTotal} KWD</b>
                                        </span>
                                      </div>
                                      
                              </div>
                              </div>
                             <div className="card-footer col-sm-12">
                                <div className="row float-right p-1">
                                    <ReactToPrint content={() => this.componentRef}>
                                            <PrintContextConsumer>
                                              {({ handlePrint }) => (
                                                <button className="btn btn-grd-disabled mr-2" onClick={handlePrint}>Print this out!</button>
                                              )}
                                            </PrintContextConsumer>
                                    </ReactToPrint>
                                   {!localStorage.getItem('role')=="admin"?null:<>
                                    <button
                                      onClick={this.onSaveData}
                                      className="btn btn-grd-disabled mr-2"
                                    >
                                      <i className="icofont icofont-save"></i> Save
                                    </button>
                              
                                  <Link to={"/orders"} className="btn btn-outline-dark">
                                    Cancel
                                  </Link></>}
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
         
        )
    }
}

OrderDetails.propsTypes = {
    getOrder: PropTypes.func.isRequired,
    getOrderStatus: PropTypes.func.isRequired
  };
  
  var mapStateToProps = (state) => ({
    home: state,
  });
  
  export default connect(mapStateToProps, { getOrder, getOrderStatus })(OrderDetails);