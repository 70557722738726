import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {  updateCity, 
          getState,addCity,
          getCityList,} from "../../../actions/homeAction"
class CityAdd extends React.Component {
  state = {
   
    // city_list: [
    //   {
    //     cityID: 1,
    //     cityName: "Asimah",
    //     status: "AVAILABLE",
    //   },
    //   {
    //     cityID: 2,
    //     cityName: "baroda",
    //     status: "AVAILABLE",
    //   },
    //   {
    //     cityID: 3,
    //     cityName: "test",
    //     status: "AVAILABLE",
    //   },
    // ],
    status:true,
    name:"",
    StateId:"",
  };
  componentDidMount(){
    if(this.state.city_id !== null)
    {
      this.getCityDetails(this.state.city_id);
    }
    this.props.getCityList();
    this.props.getState();
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      state:nextProps.home?.state,
      cityList:nextProps.home?.cityList
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.city_id !== this.props.city_id) {
      this.setState({ city_id: this.props.city_id });
      this.getCityDetails();
    }
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  getCityDetails = () => {
    var a = this.state?.cityList
    if(a !== undefined){
      
      for(let i=0;i<a.length;i++)
      {
        if(a[i].id == this.props.city_id){
           this.fillInputs(a[i])
           console.log(a[i])
        }
      }}
  }

  updateCityData = () => {
    var data={
      CityId:this.props.city_id,
      name:this.state.name,
      status:this.state.status,
      // stateCode:this.state.state_short_code,
      // pinCode:this.state.pincode, 
      stateId:this.state.StateId,
      // languageCode:this.props.language_id
    }
   this.props.updateCity(data)
 };

  addCity = () => {
    var data={
      name:this.state.name,
      // stateCode:this.state.state_short_code,
      // pinCode:this.state.pincode, 
      stateId:this.state.StateId,
      status:this.state.status,
      // languageCode:this.props.language_id
    }
   this.props.addCity(data)
  };
  
  fillInputs(city){
   debugger
    this.setState({
      name:city.name,
      status:city.status,
      // state_short_code:state.stateCode,
      // pincode:state.pinCode,
      stateId:city.StateId,
    })
  };

  
  onSaveData = () => {
    var that = this;
    if (that.props.city_id !== undefined) {
      
        that.updateCityData();
      }
     else {
      that.addCity();
    }
  };

  render() {
    return (
      <div className="">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">City Name</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="City Name"
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label className="col-sm-3 col-form-label">
                  Select State
                </label>
                <div className="col-sm-9">
                  <select
                    name="StateId"
                    className="form-control form-control-inverse"
                    onChange={this.handleChange}
                    value={this.state.StateId}
                  >
                    <option value="">Select State</option>
                    {this.state.state !== undefined &&
                    this.state.state !== null &&
                    this.state.state !== [] &&
                    this.state.state.length > 0
                      ? this.state.state.map((country) => (
                          <option
                            value={country.id}
                            key={country.id}
                          >
                            {country.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label">Status</label>
                  <div className="col-sm-9">
                    <select name="status" className="form-control" value={this.state.status} onChange={this.handleChange}>
                      <option value="true" name="active">Active</option>
                      <option value="false" name="inactive">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
          </div>
          <div className="row float-right p-3">
            {this.state.isSaving ? (
              <button className="btn btn-grd-disabled mr-2" disabled>
                Saving...!
              </button>
            ) : (
              <button
                onClick={this.onSaveData}
                className="btn btn-grd-disabled mr-2"
              >
                <i className="icofont icofont-save"></i> Save
              </button>
            )}
            <Link to={"/city"} className="btn btn-outline-dark">
              Cancel
            </Link>
          </div>
        </div>
      </div >
    );
  }
}
CityAdd.propsTypes = {
 
  getCityList:PropTypes.func.isRequired,
  addCity:PropTypes.func.isRequired,
  updateCity:PropTypes.func.isRequired,
  getState:PropTypes.func.isRequired,
  home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
  home: state
});

export default connect(mapStateToProps, {
  getCityList,
  addCity,
  getState,
  updateCity
  })(CityAdd);
