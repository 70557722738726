import React from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Constant from '../../../Constant'
import Swal from 'sweetalert2'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Toggle from 'react-toggle'
import { getSeller } from '../../../actions/homeAction'
import axios from "axios";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class SellerList extends React.Component {
  state = {
    sellerList: []
  }
  
  handleStatusChange = (id)=> {
    var data = {
      SellerId:id
    }
    axios.post(Constant.getAPI()+'/admin/togglesellerstatus',data, config).then(res=>{
      if(res.data.status){
        var DATA = {
          startRange: '0',
          count: "1000"
        }
        this.props.getSeller(DATA);
      }
    })
  }
  
  handleVariFyChange = (id)=> {
    var data = {
      userId:id
    }
    axios.post(Constant.getAPI()+'/admin/verifyStore',data, config).then(res=>{
      if(res.data.status){
        var DATA = {
          startRange: '0',
          count: "1000"
        }
        this.props.getSeller(DATA);
      }
    })
  }

  componentWillMount() {
    var data = {
      startRange: '0',
      count: "1000"
    }
    this.props.getSeller(data);
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({ sellerList: nextProps?.home?.sellerList })
  }

  render() {
    const columns = [{
      name: "image",
      label: "Seller Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image, tableMeta) => {
          return <img src={image?.url !== undefined && image?.url !== null && image?.url !== {} ? image?.url !== undefined && image?.url !== null && image?.url !== "" ? image?.url : "./assets/images/icon.png" : "./assets/images/icon.png"} className="img-fluid img-20" alt="" onError={this.imgLoadError} />
        }
      }
    },

    {
      name: "mobileNumber",
      label: "Mobile No",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "numberOfAuctions",
      label: "No of Auctions",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "numberOfProducts",
      label: "No of Products",
      options: {
        filter: false,
        sort: true
      }
    }, {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "storeDetails",
      label: "Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails?.type}</div>
          )
        }
      }
    }
      ,
    {
      name: "countryCode",
      label: "Country Code",
      options: {
        filter: false,
        sort: true
      }
    },
    {
      name: "about",
      label: "About",
      options: {
        filter: false,
        sort: true
      }
    },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (status, tableMeta) => {
    //       var Id = tableMeta.rowData[6];
    //       // console.log(tableMeta.rowData[6]);
    //       return <Toggle
    //         id={"status_" + Id}
    //         checked={status}
    //         value={status}
    //        // onChange={this.handleStatusChange.bind(this,Id,status)}
    //       />
    //     }
    //   }
    // },
    {
      name: "storeDetails",
      label: "Address",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails?.address}</div>
          )
        }
      }
    },
    {
      name: "storeDetails.licence.url",
      label: "Licence",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (image, tableMeta) => {
          // debugger
          return <img src={image !== undefined && image !== null && image !== {} ? image !== undefined && image !== null && image !== "" ? image : "./assets/images/icon.png" : "./assets/images/icon.png"} className="img-fluid img-20" alt="" onError={this.imgLoadError} />
        }
      }
    },
    {
      name: "storeDetails",
      label: "Bank",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails.bankName}</div>
          )
        }
      }
    },
    {
      name: "storeDetails",
      label: "IBAN Number",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails?.IBAN}</div>
          )
        }
      }
    },
    {
      name: "storeDetails",
      label: "Shop Name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails?.shopName}</div>
          )
        }
      }
    },
    {
      name: "storeDetails",
      label: "About Business",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (storeDetails, tableMeta) => {
          return (
            <div>{storeDetails?.aboutBusiness}</div>
          )
        }
      }
    },
    {
      name: "storeDetails",
      label: "Is Verified",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (storeDetails, tableMeta) => {
          var Id = tableMeta.rowData[16];
          // console.log(tableMeta.rowData[6]);
          return (
            <Toggle
              id={"isStoreVerified" + Id}
              checked={storeDetails.isStoreVerified==1?true:false}
              value={storeDetails.isStoreVerified==1?true:false}
              onChange={this.handleVariFyChange.bind(this, Id)}
            />
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status, tableMeta) => {
          var Id = tableMeta.rowData[16];
          // console.log(tableMeta.rowData[6]);
          return (
            <Toggle
              id={"status_" + Id}
              checked={status}
              value={status}
              onChange={this.handleStatusChange.bind(this, Id)}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (id, tableMeta) => {
          return <div>
            <Link
              to={"/seller/add/" + id}
              className="m-r-15 text-muted"
              data-toggle="tooltip"
              data-placement="top" title=""
              data-original-title="Edit">
              <i className="f-20 icofont icofont-ui-edit text-custom"></i>
            </Link>
            {/* <span
              // onClick={this.deleteUser.bind(this, id)}
              className="m-r-15 text-muted"
              data-toggle="tooltip"
              data-placement="top"
              title=""
              data-original-title="Delete">
              <i className="f-20 icofont icofont-delete-alt text-danger"></i>  </span> */}
          </div>
        }
      }
    }
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Seller List</h5>
                    {/* <Link to="/seller/add"
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Seller
                    </Link> */}
                  </div>
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={"Seller List"}
                        className="table-responsive"
                        data={this.state.sellerList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SellerList.propsTypes = {
  getSeller: PropTypes.func.isRequired
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getSeller })(SellerList);;
