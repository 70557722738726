import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import moment from 'moment'
import Toggle from 'react-toggle'
class VariantsList extends React.Component {
    state = {
        VariantList: this.props?.location?.state?.variants
    }
    render() {
        console.log('VariantList',this.state.VariantList);
        const columns = [
            {
                name: "VariantAttributes",
                label: "Variant Attributes Name",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (VariantAttributes, tableMeta) => {

                        return <div>
                            {VariantAttributes?.map(itm => (
                                <div>{itm?.Attribute?.name}</div>
                            )
                            )}
                        </div>
                    }
                }
            },
            {
                name: "VariantAttributes",
                label: "Variant AttributeOption Name",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (VariantAttributes, tableMeta) => {

                        return <div>
                            {VariantAttributes?.map(itm => (
                                <div>{itm?.Attribute?.AttributeOptions.map(data=>(
                                   <>
                                     <span>{data.name}</span><br/>
                                   </>
                                ))}</div>
                            )
                            )}
                        </div>
                    }
                }
            },
            {
                name: "price",
                label: "Price",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender:(price)=>{
                        <b>{price} KWD</b>
                    }
                }
            },
            {
                name: "quantity",
                label: "Quantity",
                options: {
                    filter: false,
                    sort: true
                }
            },

        ];
        const options = {
            filterType: "dropdown",
            viewColumns: false,
            print: false,
            download: false,
            selectableRows: 'none',
            textLabels: {
                body: {
                    noMatch: this.state.isSaving ?
                        "Loading data..!" :
                        "Sorry, No Variants Found",
                    toolTip: "Sort",
                    columnHeaderTooltip: column => `Sort for ${column.label}`
                }
            }
        };
        return (
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Variants List</h5>
                                        {/* <Link to={`/products/${this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"}/add`}
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Product
                    </Link> */}
                                    </div>
                                    <div className="card-block">
                                        <div className="dt-responsive table-responsive">
                                            <MUIDataTable
                                                title={`Variants List`}
                                                className="table-responsive"
                                                data={this.state.VariantList}
                                                columns={columns}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VariantsList;
