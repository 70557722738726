export const SET_LOADING = "SET_LOADING";
export const LOGOUT = "LOGOUT";
export const SET_USER = "SET_USER";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_COUPON ="GET_COUPON";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_WALLET_LIST = "GET_WALLET_LIST";
export const GET_GOVERNORATE = "GET_GOVERNORATE";
export const GET_STATE = "GET_STATE";
export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const GET_TERMS = "GET_TERMS";
export const GET_ALL_USER = "GET_ALL_USER";
export const CITY_LIST="CITY_LIST";
export const GET_ORDER_STATUS="GET_ORDER_STATUS";
export const GET_ALL_SELLER="GET_ALL_SELLER";
export const GET_PACKAGE ="GET_PACKAGE";
export const GET_ORDER ="GET_ORDER";
export const GET_REPORTED_POSTS ="GET_REPORTED_POSTS";
export const GET_PRODUCT ="GET_PRODUCT";
export const GET_ATTRIBUTE ="GET_ATTRIBUTE";
export const GET_NOTIFICATIONS ="GET_NOTIFICATIONS";
export const GET_WINNERS_LIST ="GET_WINNERS_LIST";
export const GET_TIME_SLOT ="GET_TIME_SLOT";
export const GET_ORDER_BY_CUSTMER ="GET_ORDER_BY_CUSTMER";
export const GET_ORDER_DETAILS_CUSTOMER ="GET_ORDER_DETAILS_CUSTOMER";
export const GET_ORDER_DETAILS_SHOP ="GET_ORDER_DETAILS_SHOP";
export const GET_DRIVER_LIST ="GET_DRIVER_LIST";
export const GET_ORDERS_OF_WAREHOUSE ="GET_ORDERS_OF_WAREHOUSE";
export const GET_ORDERS_OF_READY_FOR_PICKUP ="GET_ORDERS_OF_READY_FOR_PICKUP";
export const GET_ORDERS_OF_OUT_FOR_DELIVERY ="GET_ORDERS_OF_OUT_FOR_DELIVERY";
export const GET_NOT_DELIVERED_ORDER ="GET_NOT_DELIVERED_ORDER";
export const GET_ALL_ORDERS ="GET_ALL_ORDERS";
export const GET_VENDOR_ALL_ORDERS ="GET_VENDOR_ALL_ORDERS";