import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getOrderByShop } from "../../actions/homeAction";
import Axios from 'axios';
import Constant from "../../Constant";

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class SideNavBar extends React.Component {
  state = {
    Orders: [],
    allOrderCount:0,
    atStore:0,
    beingWrapped:0,
    beingWrappedAssignDriver:0,
    beingWrappedUnAssignDriver:0,
    onTheWay:0,
    delivered:0,
    cancelled:0,
    pending: 0,
    accepted: 0,
    acceptedNotAtStore: 0,
    vendorAll:0,
    vendorPending:0,
    vendorAccepted:0,
    vendorReadyForPickUp:0,
    vendorReadyForPickUpAssignDriver:0,
    vendorReadyForPickUpUnAssignDriver:0,
    vendorEnroute:0,
    vendorAtStore:0,
    vendorCancelled:0,
  };
  async componentWillMount() {
    var data = {
      isMultiVendorDelivery: 1,
    };
    this.props.getOrderByShop(data);
    
    const DATA = {};
    try {
      const res = await Axios.post(Constant.getAPI() + "/warehouse/sideBarMasterOrderBadges", DATA, config);
      this.setState({
        allOrderCount: res.data.all,
        atStore: res.data.atStore,
        beingWrapped: res.data.beingWrapped,
        beingWrappedAssignDriver: res.data.beingWrappedAndInDeliveries,
        beingWrappedUnAssignDriver: res.data.beingWrappedAndNotInDeliveries,
        cancelled: res.data.cancelled,
        delivered: res.data.delivered,
        onTheWay: res.data.onTheWay,
        pending: res.data.pending,
        accepted: res.data.accepted,
        acceptedNotAtStore: res.data.acceptedNotAtStore,
      });
    } catch (err) {
      console.log(err);
    }
    
    try {
      const res = await Axios.post(Constant.getAPI() + "/warehouse/sideBarVendorOrderBadges", {}, config);
      this.setState({
        vendorAll: res.data.all,
        vendorAtStore: res.data.atStore,
        vendorCancelled: res.data.cancelled,
        vendorPending: res.data.pending,
        vendorAccepted: res.data.accepted,
        vendorReadyForPickUp: res.data.readyForPickup,
        vendorReadyForPickUpAssignDriver: res.data.assignedDriverReadyForPickup,
        vendorReadyForPickUpUnAssignDriver: res.data.unassignedDriverReadyForPickup,
        vendorEnroute: res.data.enRouteWarehouse,
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ Orders: nextProps?.home?.orderByCustomers });
  }
  render() {
    const role = localStorage.getItem("role");
    return (
      <nav className="pcoded-navbar noprint" id="admin_menu">
        <div className="pcoded-inner-navbar main-menu">
          <ul className="pcoded-item pcoded-left-item">
            {/* <li className="">
              <Link to="/">
                <span className="pcoded-micon">
                  <i className="feather icon-home"></i>
                </span>
                <span className="pcoded-mtext">Dashboard</span>
              </Link>
            </li>
            <li className="pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon"><i className="icofont icofont-users-alt-3"></i></span>
                <span className="pcoded-mtext">Customers</span>
              </a>
              <ul className="pcoded-submenu">
                <li className="">
                  <Link to="/user">
                    <span className="pcoded-micon">
                      <i className="icofont icofont-users-alt-3"></i></span>
                    <span className="pcoded-mtext">Users</span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/seller">
                    <span className="pcoded-micon">
                      <i className="fa fa-user-secret"></i></span>
                    <span className="pcoded-mtext">Sellers</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="">
              <Link to="/wallet">
                <span className="pcoded-micon">
                  <i className="icofont icofont-coins"></i>
                </span>
                <span className="pcoded-mtext">Wallet</span>
              </Link>
            </li>
            
            <li className="">
              <Link to="/category">
                <span className="pcoded-micon">
                  <i className="feather icon-grid"></i>
                </span>
                <span className="pcoded-mtext">Category</span>
              </Link>
            </li>
            <li className=""a>
              <Link to="/timeSlot">
                <span className="pcoded-micon">
                  <i className="feather icon-watch"></i>
                </span>
                <span className="pcoded-mtext">Time Slot</span>
              </Link>
            </li> */}

            <li>
              <Link to="/home">
                <span className="pcoded-micon">
                  <i className="feather icon-home"></i>
                </span>
                <span>Dashboard</span>
              </Link>
            </li>

            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-package"></i>
                </span>
                <span className="pcoded-mtext">Master Orders</span>
              </a>

              <ul className="pcoded-submenu">
                <li>
                  <Link to="/warehouse/allOrder?type=M">
                    <span className="pcoded-mtext">All
                      <span className="badge badge-primary float-right">{this.state.allOrderCount}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse/master-pending-order?type=M">
                    <span className="pcoded-mtext">Pending
                      <span className="badge badge-primary float-right">{this.state.pending}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse/master-accepted-order?type=M">
                    <span className="pcoded-mtext">Pending (Accepted)
                      <span className="badge badge-primary float-right">{this.state.accepted}</span>
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="/warehouse/allOrder?type=M">
                    <span className="pcoded-mtext">Accepted (Not At Store)
                      <span className="badge badge-primary float-right">{this.state.acceptedNotAtStore}</span>
                    </span>
                  </Link>
                </li> */}
                <li>
                  <Link to="/warehouse/master-accepted-at-store-order?type=M">
                    <span className="pcoded-mtext">Accepted (At Store)
                      <span className="badge badge-primary float-right">{this.state.atStore}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse/master-wrap-order?type=M">
                    <span className="pcoded-mtext">Being Wrapped
                      <span className="badge badge-primary float-right">{this.state.beingWrapped}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse/master-wrap-unassign-order?type=M">
                    <span className="pcoded-mtext">Ready for Delivery (Unassigned)
                      <span className="badge badge-primary float-right">{this.state.beingWrappedUnAssignDriver}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/warehouse/master-wrap-assign-order?type=M">
                    <span className="pcoded-mtext">Ready for Delivery (Assigned)
                      <span className="badge badge-primary float-right">{this.state.beingWrappedAssignDriver}</span>
                    </span>
                  </Link>
                </li>
                {/* <li>
                  <Link to="#">
                    <span className="pcoded-mtext">Ready for Delivery (Unassigned)
                      <span className="badge badge-primary float-right">{this.state.readyForDeliveryUnassigned}</span>
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="pcoded-mtext">Ready for Delivery (Assigned)
                      <span className="badge badge-primary float-right">{this.state.readyForDeliveryAssigned}</span>
                    </span>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="#">
                    <span className="pcoded-mtext">Out for Delivery</span>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="pcoded-mtext">Delivered</span>
                  </Link>
                </li>
                <li>
                  <Link to="">
                    <span className="pcoded-mtext">Cancelled</span>
                  </Link>
                </li> */}

                

                {/* <li>
                  <Link to="/warehouse/express/orderByCustomers">
                    <span className="pcoded-mtext">Express:- By customer</span>
                  </Link>
                </li> */}

                {/* {role == "warehouse" && (
                  <li>
                    <Link to="/warehouse/express/orderByShop">
                      <span className="pcoded-mtext">
                        Express:- By Suppliers
                      </span>
                    </Link>
                  </li>
                )} */}

                {/* <li>
                  <Link to="/warehouse/express/orderByShop">
                    <span className="pcoded-mtext">{ role!=="warehouse"? "Express Orders ": "Express Orders By Suppliers"}</span>
                  </Link>
                </li> */}

               

                <li className="">
                  <Link to="/warehouse/orderByOutForDelivery?type=M">
                    <span className="pcoded-mtext">Out For Delivery
                      <span className="badge badge-primary float-right">{this.state.onTheWay}</span>
                    </span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/warehouse/master-delivered-order?type=M">
                    <span className="pcoded-mtext">Delivered
                      <span className="badge badge-primary float-right">{this.state.delivered}</span>
                    </span>
                  </Link>
                </li>

                <li className="">
                  <Link to="/warehouse/master-cancelled-order?type=M">
                    <span className="pcoded-mtext">
                      Cancelled
                      <span className="badge badge-primary float-right">{this.state.cancelled}</span>
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-package"></i>
                </span>
                <span className="pcoded-mtext">Vendor Orders</span>
              </a>

              <ul className="pcoded-submenu">
                <li>
                  <Link to="/warehouse/vendor-all-order?type=S">
                    <span className="pcoded-mtext">All
                      <span className="badge badge-primary float-right">{this.state.vendorAll}</span>
                    </span>
                  </Link> 
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-pending-order?type=S">
                    <span className="pcoded-mtext">Pending
                      <span className="badge badge-primary float-right">{this.state.vendorPending}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-accepted-order?type=S">
                    <span className="pcoded-mtext">Accepted
                      <span className="badge badge-primary float-right">{this.state.vendorAccepted}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-ready-for-pickup-order?type=S">
                    <span className="pcoded-mtext">Ready for pick up
                      <span className="badge badge-primary float-right">{this.state.vendorReadyForPickUp}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-unassign-ready-for-pickup-order?type=S">
                    <span className="pcoded-mtext">Ready for pick up (Unassigned)
                      <span className="badge badge-primary float-right">{this.state.vendorReadyForPickUpUnAssignDriver}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-assign-ready-for-pickup-order?type=S">
                    <span className="pcoded-mtext">Ready for pick up (Assigned)
                      <span className="badge badge-primary float-right">{this.state.vendorReadyForPickUpAssignDriver}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-route-to-warehouse-order?type=S">
                    <span className="pcoded-mtext">EnRoute to Warehouse
                      <span className="badge badge-primary float-right">{this.state.vendorEnroute}</span>
                    </span>
                  </Link>
                </li>
                <li className="">
                  <Link to="/warehouse/vendor-at-store-order?type=S">
                    <span className="pcoded-mtext">Reached at Warehouse
                      <span className="badge badge-primary float-right">{this.state.vendorAtStore}</span>
                    </span>
                  </Link>
                </li>
                {/* <li className="">
                  <Link to="#">
                    <span className="pcoded-mtext">Reached at Warehouse (Delivered)
                      <span className="badge badge-primary float-right">{this.state.reachedAtWarehouseDeliveredVendor}</span>
                    </span>
                  </Link>
                </li> */}

                {/* <li>
                  <Link to="/warehouse/express/orderByCustomers">
                    <span className="pcoded-mtext">Express:- By customer</span>
                  </Link>
                </li>

                {role == "warehouse" && (
                  <li>
                    <Link to="/warehouse/express/orderByShop">
                      <span className="pcoded-mtext">
                        Express:- By Suppliers
                      </span>
                    </Link>
                  </li>
                )} */}

                {/* <li className="">
                  <Link to="/warehouse/orderByReadyForPickup?type=S">
                    <span className="pcoded-mtext">Ready for pick up</span>
                  </Link>
                </li> */}

                {/* <li className="">
                  <Link to="/warehouse/orderByOutForDelivery?type=S">
                    <span className="pcoded-mtext">Out For Delivery</span>
                  </Link>
                </li> */}

                {/* <li className="">
                  <Link to="/warehouse/notDeliveredOrder?type=S">
                    <span className="pcoded-mtext">Not Delivered</span>
                  </Link>
                </li> */}
              </ul>
            </li>

            <li>
              <Link to="/driver/list">
                <span className="pcoded-micon">
                  <i className="feather icon-command"></i>
                </span>
                <span>Driver</span>
              </Link>
            </li>

            {/* <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-box"></i>
                </span>
                <span className="pcoded-mtext">Products</span>
              </a>
              <ul className="pcoded-submenu">
                <li>
                  <Link to="/product/Fixed">
                    <span className="pcoded-mtext">Fixed</span>
                  </Link>
                </li>
                <li>
                  <Link to="/product/Auction">
                    <span className="pcoded-mtext">Auction</span>
                  </Link>
                </li>
                <li>
                  <Link to="/product/AuctionCompleted">
                    <span className="pcoded-mtext">Completed Auctions</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-box"></i>
                </span>
                <span className="pcoded-mtext">Attribute Master</span>
              </a>
              <ul className="pcoded-submenu">
                <li>
                  <Link to="/attribute/Add">
                    <span className="pcoded-mtext">Add Attribute</span>
                  </Link>
                </li>
                <li>
                  <Link to="/attribute/All">
                    <span className="pcoded-mtext">Attribute List</span>
                  </Link>
                </li>
              </ul>
            </li>
            
            <li className="">
              <Link to="/orders">
                <span className="pcoded-micon">
                  <i className="feather icon-grid"></i>
                </span>
                <span className="pcoded-mtext">Orders</span>
              </Link>
            </li> */}
          </ul>

          {/* <ul className="pcoded-item pcoded-left-item">
            <li className="pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon"><i className="feather icon-command"></i></span>
                <span className="pcoded-mtext">Master</span>
              </a>
            </li>
            
            <li className="">
              <Link to="/package">
                <span className="pcoded-micon">
                <i className="feather icon-box"></i>
                </span>
                <span className="pcoded-mtext">Package Master</span>
              </Link>
            </li>
            <li className="">
              <Link to="/coupon">
                <span className="pcoded-micon">
                  <i className="feather icon-percent"></i>
                </span>
                <span className="pcoded-mtext">Coupon Master</span>
              </Link>
            </li>
          </ul>
          <ul className="pcoded-item pcoded-left-item">
            <li className=" pcoded-hasmenu">
              <a href="javascript:void(0)">
                <span className="pcoded-micon">
                  <i className="feather icon-map-pin"></i>
                </span>
                <span className="pcoded-mtext">Location Master</span>
              </a>
              <ul className="pcoded-submenu">
                <li>
                  <Link to="/country">
                    <span className="pcoded-mtext">Country</span>
                  </Link>
                </li>
                <li>
                  <Link to="/state">
                    <span className="pcoded-mtext">State</span>
                  </Link>
                </li>
                <li>
                  <Link to="/city">
                    <span className="pcoded-mtext">City</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="">
              <Link to="/reportedPosts">
                <span className="pcoded-micon">
                <i className="feather icon-box"></i>
                </span>
                <span className="pcoded-mtext">Reported Posts</span>
              </Link>
            </li>

            <li className="">
              <Link to="/pushNotifications">
                <span className="pcoded-micon">
                <i className="feather icon-grid"></i>
                </span>
                <span className="pcoded-mtext">Push Notifications</span>
              </Link>
            </li>
          </ul>
          
          <div className="pcoded-navigatio-lavel">Settings</div>
          <ul className="pcoded-item pcoded-left-item">
            <li className="">
              <Link to="/about">
                <span className="pcoded-micon">
                  <i className="icofont icofont-law-document"></i>
                </span>
                <span className="pcoded-mtext">About Us</span>
              </Link>
            </li>
            <li className="">
              <Link to="/privacyPolicy">
                <span className="pcoded-micon">
                  <i className="icofont icofont-law-document"></i>
                </span>
                <span className="pcoded-mtext">Privacy Policy</span>
              </Link>
            </li>
            <li className="">
              <Link to="/terms">
                <span className="pcoded-micon">
                  <i className="icofont icofont-law-document"></i>
                </span>
                <span className="pcoded-mtext">Terms & Conditions</span>
              </Link>
            </li>
            <li className="">
              <Link to="/contact-us">
                <span className="pcoded-micon">
                  <i className="feather icon-phone"></i>
                </span>
                <span className="pcoded-mtext">Contact Us</span>
              </Link>
            </li>
          </ul> */}
        </div>
      </nav>
    );
  }
}
SideNavBar.propsTypes = {
  getOrderByShop: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};
var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getOrderByShop })(SideNavBar);
