import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {addAttribute, getCategory} from '../../actions/homeAction'

export class ADDAttribute extends Component {
    state = {
        attributeName:'',
        attributeNameAr:'',
        categoryId:'',
        attributeOptions:[
            {
                name:'',
                nameAr:'',
            },
        ],
        categoryList:[],
    }

    componentWillMount() {
        this.props.getCategory();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ categoryList: nextProps?.home?.categoryList });
        console.log('CATEGORY',nextProps?.home?.categoryList);
    }
    

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };


    optionHandleChange = (e,index) => {
       const updatedOption = this.state.attributeOptions.map((data,i)=> index==i?Object.assign(data,{[e.target.name]:e.target.value}):data)
       this.setState({attributeOptions:updatedOption})
    } 


    onAddOption = () => {
        var options = { name:'', nameAr:'', }
        this.setState({attributeOptions:[...this.state.attributeOptions,options]})
    }

    removeOptions = (index) => {
        const filteredOptions = [...this.state.attributeOptions]

          filteredOptions.splice(index,1)
          
        this.setState({attributeOptions:filteredOptions})
    }

    onSaveData = (e) => {
      e.preventDefault()
      var attData = {
        categoryId:this.state.categoryId,
        attributeName:this.state.attributeName,
        attributeNameAr:this.state.attributeNameAr,
        attributeOptions:this.state.attributeOptions,
      }
     
      this.props.addAttribute(attData)
      
    } 

  render() {
    console.log('OPTIONS',this.state.categoryList);
    return <div className="pcoded-inner-content">
    <div className="main-body">
      <div className="page-wrapper">
        <div className="page-header">
          <div className="row align-items-end">
            <div className="col-lg-8">
              <div className="page-header-title">
                <div className="d-inline">
                  <h4>Attribute Add</h4>
                </div>
              </div>
             
            </div>
            <div className="col-lg-4">
              <div className="page-header-breadcrumb">
                <ul className="breadcrumb-title">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="feather icon-home"></i>{" "}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/attribute-add">Attribute Add</Link>
                  </li>
                  <li className="breadcrumb-item active">Attribute Master</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-block">
                  <form onSubmit={(e)=>this.onSaveData(e)} className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label htmlFor='attributeName' className="col-sm-4 col-form-label FONT_SIZE">Attribute Name (En)</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="attributeName"
                                    id="attributeName"
                                    placeholder="Attribute Name English"
                                    onChange={this.handleChange}
                                    value={this.state.attributeName}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label htmlFor='attributeName' className="col-sm-4 col-form-label FONT_SIZE">Attribute Name (Ar)</label>
                            <div className="col-sm-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="attributeNameAr"
                                    id="attributeNameAr"
                                    placeholder="Attribute Name Arabic"
                                    onChange={this.handleChange}
                                    value={this.state.attributeNameAr}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label htmlFor='categoryId' className="col-sm-4 col-form-label FONT_SIZE">Category</label>
                            <div className="col-sm-8">
                                <select className="form-control"
                                    name="categoryId"
                                    id="categoryId"
                                    onChange={this.handleChange}
                                    value={this.state.categoryId}
                                    required>
                                    <option value=""> - Select Category - </option>
                                    {this.state.categoryList!==[]&&this.state.categoryList!==undefined?this.state.categoryList.map(category=>(
                                        <option value={category.id}>{category.name}/{category.name_ar}</option>
                                    )):null}
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12">
                        <button onClick={this.onAddOption}
                        className="btn btn-sm btn-inverse waves-effect waves-light f-left d-inline-block md-trigger"
                        >
                        <i className="icofont icofont-plus m-r-5"></i>Add Option{" "}
                        </button>
                    </div>
                    <div className="col-md-12 row">
                        {this.state.attributeOptions.map((data,index)=>{
                           return  <div className="ATTRIBUTE_OPTIONS row" key={index}>
                           <div className="col-md-5">
                               <div className="form-group row">
                                   <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">Attibute Option Name (En)</label>
                                   <div className="col-sm-8">
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="name"
                                           id="name"
                                           placeholder="Attibute Option Name English"
                                           onChange={e=> this.optionHandleChange(e,index)}
                                           value={data.name}
                                           required
                                       />
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-5">
                               <div className="form-group row">
                                   <label htmlFor='value' className="col-sm-4 col-form-label FONT_WEIGHT">Attibute Option Name (Ar)</label>
                                   <div className="col-sm-8">
                                       <input
                                           type="text"
                                           className="form-control"
                                           name="nameAr"
                                           id="nameAr"
                                           placeholder="Attibute Option Name Arabic"
                                           onChange={e=> this.optionHandleChange(e,index)}
                                           value={data.nameAr}
                                           required
                                       />
                                   </div>
                               </div>
                           </div>
                           <div className="col-md-2 ATT_REM_BTN">
                                <button onClick={()=> this.removeOptions(index)}>Remove</button>
                            </div>
                       </div>
                        })}
                        
                    </div>
                    <div className="col-md-12 DATA_SAVE">
                        <div>
                              <button type='submit' className="btn btn-grd-disabled mr-2"><i className="icofont icofont-save"></i> Save</button>
                         
                          <Link to={"/"} className="btn btn-outline-dark">
                            Cancel
                          </Link>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  }
}

ADDAttribute.propsTypes = {
    getCategory: PropTypes.func.isRequired,
    addAttribute: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
  
};

var mapStateToProps = (state) => ({
   home: state
});
export default connect(mapStateToProps, {

  addAttribute,getCategory,
  
  })(ADDAttribute);