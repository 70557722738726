import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getExpressOrderByCustomers, deleteProduct } from '../../actions/homeAction'

class ExpressOrdersCustomer extends React.Component {
  state = {
    Orders: [],
    orderDate:'',
    orderId:'',
    invoiceNumber: "",
  }
  componentWillMount() {
  
      var data = {
        isMultiVendorDelivery:0,
        startRange: 0,
        // count: 10,
      }
    this.props.getExpressOrderByCustomers(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ Orders: nextProps?.home?.orderByCustomers })
  }

  OnSearch = () =>{
    var invoiceNumber = this.state.invoiceNumber
    var data = {
      isMultiVendorDelivery: 0,
      startRange: 0,
      invoiceNumber: invoiceNumber,
    }
    this.props.getExpressOrderByCustomers(data);
  }

//   componentDidUpdate(prevProps,prevState) {
//     if (this.state.shopName !== prevState.shopName) {
//       console.log("Do something");
//       var Product = prevProps.home.ProductData
//       var Filtered = Product.filter(data=>data.shop.shopName.toUpperCase().includes(this.state.shopName.toUpperCase()))
//       console.log('Filtered',Filtered);
//       this.setState({ProductList: Filtered})
//     }
//   }


//   handleStatusChange = (id,status)=> {
//     var data = {
//       id:id,
//       status:!status,
//     }
//     axios.post(Constant.getAPI()+'/admin/changeStatusSendMoneyToBank',data, config).then(res=>{
//       if(res.data.status){
//         var data = {
//             startRange: '0',
//             count: "10",
//           }
//         this.props.getWalletList(data);
//       }
//     })
//   }

  render() {
    console.log('Orders',this.state.Orders);
    const columns = [
      // {
        //   name: "dateTime",
        //   label: "No.",
        //   options: {
          //     filter: false,
          //     sort: true,
          //     display:false,
          //     customBodyRender: (dateTime, tableMeta) => {
            //       var tab = tableMeta.rowIndex
      //       return <div>
      //         <span>{tab+1}</span>
      //       </div>
      //     }
      //   }
      // },
      {
        name: "invoiceNumbers",
        label: "Invoice ID",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (invoiceNumbers) => {
            // console.log("invoiceNumbers", invoiceNumbers);
            if(invoiceNumbers) {
            return <div>
            {invoiceNumbers.map((item,idx)=> <span> {item},</span>)}
            </div>
            }
          }
        }
      },
      {
        name: "dateTime",
        label: "Date-Time",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ style: { minWidth: "200px", maxWidth: "200px" }}),
          customBodyRender: (dateTime, tableMeta) => {

            return <div>
              <span>{moment(dateTime).format('DD-MMM-YYYY | hh:mm A')}</span>
            </div>
          }
        }
      },
      {
        name: "firstName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
          display:false,
        }
      },
      {
        name: "lastName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (lastName, tableMeta) => {
            var fName = tableMeta.rowData[2]
            return <div>
              <span>{fName+' '+ (lastName===null?"":lastName) }</span>
            </div>
          }
        }
      },
      {
        name: "customerEmail",
        label: "Customer Email",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "mobileNumber",
        label: "Phone",
        options: {
          filter: false,
          sort: true
        }
      },
      // {
      //   name: "orderId",
      //   label: "Order ID",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
      {
        name: "orderTotal",
        label: "Order Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderTotal, tableMeta) => {
            return <div>
              <span>{orderTotal} KWD</span>
            </div>
          }
        }
      },
      // {
      //   name: "paymentMode",
      //   label: "Payment Mode",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
      // {
      //   name: "paymentStatus",
      //   label: "Payment status",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
     
      // {
      //   name: "productCount",
      //   label: "Product Count",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
      {
        name: "status",
        label: "Status",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderStatus) => {
            let color = "red";
            let displayStatus = orderStatus;

            if (orderStatus === "Placed") {
              color = "green";
            } else if (orderStatus === "Reached at warehouse") {
              color = "blue";
              displayStatus = "At store";
            }

            return (
              <b style={{ color, marginTop: "0", marginBottom: 0, fontWeight: "600" }}>
                {displayStatus}
              </b>
            );
          },
        }
      },
      {
        name: "orderId",
        label: "Action",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (orderId, tableMeta) => {
            return (
              <div>
                <Link
                  to={"/warehouse/orderMasterDetails/" + orderId}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                >
                  <i className="f-20 icofont icofont-eye-alt text-custom"></i>
                </Link>
              </div>
            );
          },
        },
      },
      // {
      //   name: "orderId",
      //   label: "Action",
      //   options: {
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (orderId, tableMeta) => {
      //       return <div>
      //         <Link
      //             to={"/warehouse/express/orderByCustomers/details/" + orderId}
      //             className="m-r-15 text-muted"
      //             data-toggle="tooltip"
      //             data-placement="top" title=""
      //             data-original-title="Edit">
      //           <i className="f-20 icofont icofont-eye-alt text-custom"></i>
      //         </Link>
      //       </div>
      //     }
      //   }
      // },
      
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      filter:false,
      search:false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Orders Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };

    console.log(this.props)
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                {/* <div className="card">
                  <div className="Filters">
                    <div>
                      <input onChange={(e)=>this.setState({orderId:e.target.value})} type="text" name="" id="" placeholder='Order ID'/>
                    </div>
                      <div>
                        <button onClick={this.OnSearch}>GO</button>
                      </div>
                  </div>
                </div> */}
                {/* <div className="card">
                  <div className="card-block">
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        className="table-responsive"
                        data={this.state.Orders}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div> */}
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th style={{ border: "0" }}>
                        <div className="dt-responsive table-responsive">
                          <MUIDataTable
                            // title="All"
                            className="table-responsive"
                            data={this.state.Orders}
                            columns={columns}
                            options={options}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>

                </table> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ExpressOrdersCustomer.propsTypes = {
    getExpressOrderByCustomers: PropTypes.func.isRequire,
  deleteProduct: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getExpressOrderByCustomers, deleteProduct })(ExpressOrdersCustomer);
