import React from "react";
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { getAll, vendorOrder, getOrderStatus } from "../../actions/homeAction";
import axios from "axios";
import Swal from "sweetalert2";
import Constant from "../../Constant";

var config = {
    headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("loged_In_auth"),
    },
};

class CancelledMasterOrders extends React.Component {
    state = {
        Orders: [],
        orderStatusList: [],
        orderStatus: "",
        orderDate: "",
        orderId: "",
        invoiceNumber: "",
        type: "",
        startDate: "",
        endDate: "",
        firstName: "",
        mobileNumber: "",
        showFilter: false,
    };
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.fetchData();
        }
    }

    getTypeFromURL() {
        const currentUrl = window.location.href;
        const hashIndex = currentUrl.indexOf("#");
        let queryParams = "";

        if (hashIndex !== -1) {
            const hashPart = currentUrl.substring(hashIndex + 1);
            const queryIndex = hashPart.indexOf("?");
            if (queryIndex !== -1) {
                queryParams = hashPart.substring(queryIndex);
            }
        }

        const params = new URLSearchParams(queryParams);
        return params.get("type");
    }

    fetchData() {
        this.props.getOrderStatus();
        const type = this.getTypeFromURL();
        this.state.type = type;

        const data = {
            isMultiVendorDelivery: 1,
            startRange: 0,
        };

        if (type === "M") {
            data.deliveryStatusId = "cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5";
            this.props.getAll(data);
        } else {
            this.props.vendorOrder(data);
        }
    }

    componentWillReceiveProps(nextProps) {
        let type = this.state.type;
        this.setState({
            Orders:
                type == "M" ? nextProps?.home?.getAll : nextProps?.home?.vendorOrder,
        });
        this.setState({ orderStatusList: nextProps?.home?.orderStatus });
    }

    OnSearch = () => {
        let type = this.state.type;
        const { invoiceNumber, orderId, startDate, endDate, orderStatus, firstName, mobileNumber } = this.state;
        var data = {
            isMultiVendorDelivery: 0,
            startRange: 0,
            invoiceNumber: invoiceNumber,
            orderId: orderId,
            orderStatus: orderStatus,
            startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
            endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
            firstName: firstName,
            mobileNumber: mobileNumber,
        };
        if (type === "M") {
            data.deliveryStatusId = "cee1b25e-2b10-11ef-bd7f-0ad94c5b94d5";
            this.props.getAll(data);
        } else {
            this.props.vendorOrder(data);
        }
    };

    onChangeStatus = (orderId, statusId) => {
        var data = {
            orderIds: orderId,
            deliveryStatusId: statusId,
        };
        axios
            .post(
                Constant.getAPI() + "/warehouse/changeSellerDeliveryStatus",
                data,
                config
            )
            .then((res) => {
                let type = this.state.type;
                if (type == "M") {
                    this.props.getAll();
                } else {
                    this.props.vendorOrder();
                }
                if (res.data.status) {
                    Swal.fire({
                        title: "Status has been Changed",
                        type: "success",
                        imageUrl: "./assets/zeed-logo.png",
                        imageHeight: 70,
                        confirmButtonColor: "#3ab1f7",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: "You Are Not Authorised",
                        type: "error",
                        imageUrl: "./assets/zeed-logo.png",
                        imageHeight: 70,
                        confirmButtonColor: "#3ab1f7",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                }
            })
            .catch((err) => {
                if (err) {
                    Swal.fire({
                        title: err?.response?.data?.error,
                        type: "error",
                        imageUrl: "./assets/zeed-logo.png",
                        imageHeight: 70,
                        confirmButtonColor: "#3ab1f7",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Ok",
                    });
                }
            });
    };

    toggleFilter = () => {
        this.setState((prevState) => ({ showFilter: !prevState.showFilter }));
    };

    clearFilters = () => {
        this.setState({
            invoiceNumber: "",
            orderId: "",
            orderStatus: "",
            startDate: "",
            endDate: "",
            firstName: "",
            mobileNumber: "",
        }, () => {
            this.fetchData();
        });
    };

    render() {
        const { showFilter } = this.state;
        const columns = [
            {
                name: "invoiceNumber",
                label: "OrderID",
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: "dateTime",
                label: "Date-Time",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (dateTime, tableMeta) => {
                        return (
                            <div>
                                <span>{moment(dateTime).format("DD-MMM-YYYY | hh:mm A")}</span>
                            </div>
                        );
                    },
                },
            },
            {
                name: "userName",
                label: "Customer Name",
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: "customerEmail",
                label: "Customer Email",
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: "mobileNumber",
                label: "Phone",
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'shopNames',
                label: "Vendor Name",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: 'address',
                label: "Vendor Address",
                options: {
                    filter: false,
                    sort: true,
                }
            },
            {
                name: "status",
                label: "Order Status",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (orderStatus) => {
                        let color = "red";
                        let displayStatus = orderStatus;

                        if (orderStatus === "Placed") {
                            color = "green";
                        } else if (orderStatus === "Reached at warehouse") {
                            color = "blue";
                            displayStatus = "At store";
                        }

                        return (
                            <b
                                style={{
                                    color,
                                    marginTop: "0",
                                    marginBottom: 0,
                                    fontWeight: "600",
                                }}
                            >
                                {displayStatus}
                            </b>
                        );
                    },
                },
            },
        ];
        if (this.state.type === "M") {
            columns.push({
                name: "id",
                label: "Action",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (id, tableMeta) => {
                        return (
                            <div>
                                <Link
                                    to={`/warehouse/orderMasterDetails/${id}?type=${this.state.type}`}
                                    className="m-r-15 text-muted"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Edit"
                                >
                                    <i className="f-20 icofont icofont-eye-alt text-custom"></i>
                                </Link>
                            </div>
                        );
                    },
                },
            });
        } else {
            columns.push({
                name: "orderId",
                label: "Action",
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (orderId, tableMeta) => {
                        return (
                            <div>
                                <Link
                                    to={`/warehouse/orderMasterDetails/${orderId}?type=${this.state.type}`}
                                    className="m-r-15 text-muted"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=""
                                    data-original-title="Edit"
                                >
                                    <i className="f-20 icofont icofont-eye-alt text-custom"></i>
                                </Link>
                            </div>
                        );
                    },
                },
            });
        }
        const options = {
            filterType: "dropdown",
            viewColumns: false,
            filter: false,
            search: false,
            print: false,
            download: false,
            selectableRows: "none",
            textLabels: {
                body: {
                    noMatch: this.state.isSaving
                        ? "Loading data..!"
                        : "Sorry, No Orders Found",
                    toolTip: "Sort",
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
            },
        };

        return (
            <div className="pcoded-inner-content">
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div>
                                            <button
                                                style={{ marginBottom: "10px" }}
                                                className="btn btn-info"
                                                onClick={this.toggleFilter}
                                            >
                                                {showFilter ? "Hide Filter" : "Show Filter"}
                                            </button>
                                            {showFilter && (
                                                <div style={{ marginBottom: "20px", border: "1px solid #ddd", borderRadius: "4px", padding: "20px", backgroundColor: "#fff", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", }}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <div style={{ flex: "1", marginRight: "10px" }}>
                                                            <label>Order ID:</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Order ID"
                                                                onChange={(e) =>
                                                                    this.setState({ invoiceNumber: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </div>
                                                        <div style={{ flex: "1" }}>
                                                            <label>Order Status:</label>
                                                            <select
                                                                className="order-status-filter"
                                                                onChange={(e) =>
                                                                    this.setState({ orderStatus: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            >
                                                                <option value="">Order Status</option>
                                                                <option value="Being Wrapped Up">Being Wrapped Up</option>
                                                                <option value="On the Way">On the Way</option>
                                                                <option value="Cancelled">Cancelled</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <div style={{ flex: "1", marginRight: "10px", marginBottom: "10px" }}>
                                                            <label>Start Date:</label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) =>
                                                                    this.setState({ startDate: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </div>
                                                        <div style={{ flex: "1" }}>
                                                            <label>End Date:</label>
                                                            <input
                                                                type="date"
                                                                onChange={(e) =>
                                                                    this.setState({ endDate: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}>
                                                        <div style={{ flex: "1", marginRight: "10px" }}>
                                                            <label>Customer Name:</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Customer Name"
                                                                onChange={(e) =>
                                                                    this.setState({ firstName: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </div>
                                                        <div style={{ flex: "1", }}>
                                                            <label>Customer Phone:</label>
                                                            <input
                                                                type="text"
                                                                placeholder="Customer Phone"
                                                                onChange={(e) =>
                                                                    this.setState({ mobileNumber: e.target.value })
                                                                }
                                                                style={{ width: "100%" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ textAlign: "right", marginRight: "8px" }}>
                                                            <button className="btn btn-success mt-3" onClick={this.OnSearch}>
                                                                Search
                                                            </button>
                                                        </div>
                                                        <div style={{ textAlign: "right" }}>
                                                            <button className="btn btn-light mt-3" onClick={this.clearFilters}>
                                                                Clear
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <table className="table  table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ border: "0", padding: "0" }}>
                                                        <div className="dt-responsive table-responsive table-bordered">
                                                            <MUIDataTable
                                                                // title="All"
                                                                className="table-responsive"
                                                                data={this.state.Orders}
                                                                columns={columns}
                                                                options={options}
                                                            />
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CancelledMasterOrders.propsTypes = {
    getAll: PropTypes.func.isRequire,
    vendorOrder: PropTypes.func.isRequired,
    home: PropTypes.object.isRequired,
    getOrderStatus: PropTypes.func.isRequired,
};

var mapStateToProps = (state) => ({
    home: state,
});

export default connect(mapStateToProps, {
    getAll,
    vendorOrder,
    getOrderStatus,
})(CancelledMasterOrders);
