import React, { Component } from "react";
import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Login from "./common/Login.js";
import Home from "./common/Home.js";
import Dashboard from "./common/Dashboard.js";
import CountryList from "./components/LocationMaster/CountryMaster/CountryList.js";
import GovernorateList from "./components/LocationMaster/GovernorateMaster/GovernorateList.js";
import GovernorateAddTab from "./components/LocationMaster/GovernorateMaster/GovernorateAddTab.js";
import StateList from "./components/LocationMaster/StateMaster/StateList.js";
import StateAddTab from "./components/LocationMaster/StateMaster/StateAddTab.js";
import CityList from "./components/LocationMaster/CityMaster/CityList.js";
import CityAddTab from "./components/LocationMaster/CityMaster/CityAddTab.js";
import TermsAddTab from "./components/Terms/TermsAddTab.js";
import AboutUsAddTab from "./components/AboutUs/AboutUsAddTab.js";
import ContactUsList from "./components/ContactUs/ContactUsList.js";
import CountryAddTab from "./components/LocationMaster/CountryMaster/CountryAddTab.js";
import SellerList from "./components/UserMaster/Sellers/SellersList.js";
import SellerAddTab from "./components/UserMaster/Sellers/SellersAddTab.js";
import DriverList from "./components/UserMaster/Driver/DriverList.js";
import DriverAddTab from "./components/UserMaster/Driver/DriverAddTab.js";
import UserList from "./components/UserMaster/User/UsersList.js";
import UserAddTab from "./components/UserMaster/User/UsersAddTab.js";
import PaymentList from "./components/PaymentMaster/PaymentList.js";
import PaymentAddTab from "./components/PaymentMaster/PaymentAddTab";
import BusinessList from "./components/BusinessTypeMaster/BusinessList.js";
import BusinessAddTab from "./components/BusinessTypeMaster/BusinessAddTab.js";
import CouponList from "./components/CouponMaster/CouponList.js";
import CouponAddTab from "./components/CouponMaster/CouponAddTab.js";
import RoleList from "./components/RoleMaster/RoleList.js";
import RoleAddTab from "./components/RoleMaster/RoleAddTab.js";
import CategoryList from "./components/CategoryMaster/CategoryList.js";
import CategoryAddTab from "./components/CategoryMaster/CategoryAddTab.js";
import SellTypeList from "./components/SellTypeMaster/SellTypeList.js";
import SellTypeAddTab from "./components/SellTypeMaster/SelltypeAddTab.js";
import LanguageList from "./components/LanguageMaster/LanguageList.js";
import LanguageAdd from "./components/LanguageMaster/LanguageAdd.js";
import DeliveryChargesAddTab from "./components/DeliveryCharge/DeliveryChargesAddTab.js";
import DeliveryList from "./components/DeliveryCharge/DeliveryList.js";
import DeliveryTimeList from "./components/DeliveryTime/DeliveryTimeList.js";
import DeliveryTimeAddTab from "./components/DeliveryTime/DeliveryTimeAddTab.js";
import OrderStatusList from "./components/OrderStatusMaster/OrderStatusList.js";
import OrderStatusAddTab from "./components/OrderStatusMaster/OrderStatusAddTab.js";
import VarientsList from "./components/VarientsMaster/VarientsList.js";
import VarientsAddTab from "./components/VarientsMaster/VarientsAddTab.js";
import OrderList from "./components/OrderMaster/OrderList.js";
import ProductList from "./components/ProductMaster/ProductList.js";
import ProductAddTab from "./components/ProductMaster/ProductAddTab.js";
import PackageList from "./components/PackageMaster/PackageList.js";
import PackageAddTab from "./components/PackageMaster/PackageAddTab"
import ContactDetails from "./components/ContactUs/ContactDetails.js";
import PirvacyPolicyAddTab from "./components/PrivacyPolicy/PirvacyPolicyAddTab.js";
import VariantsList from "./components/ProductMaster/variantsList.js";
import OrderDetails from "./components/OrderMaster/OrderDetails.jsx";
import ReportedPOST from "./components/ReportPOSTS/ReportedPOST.jsx";
import ADDAttribute from "./components/AttributeMaster/ADDAttribute.jsx";
import AttributeLIST from "./components/AttributeMaster/AttributeLIST.jsx";
import VariantLIST from "./components/ProductMaster/VariantLIST.jsx";
import VariantADD from "./components/ProductMaster/VariantADD.jsx";
import PushNotificationsList from "./components/PushNotifications/PushNotificationsList.jsx";
import NotificationSEND from "./components/PushNotifications/NotificationSEND.jsx";
import AuctionList from "./components/ProductMaster/AuctionList.jsx";
import Winners from "./components/ProductMaster/Winners.jsx";
import WalletList from "./components/WalletMaster/WalletList.jsx";
import TimeSLotList from "./components/TimeSlot/TimeSLotList.js";
import TimeSlotAdd from "./components/TimeSlot/TimeSlotAdd.js";
import OrdersByCustomers from "./components/WareHouse/OrdersByCustomers.js";
import OrderDetailsByCustomer from "./components/WareHouse/OrderDetailsByCustomer.js";
import OrderByShop from "./components/WareHouse/OrderByShop.js";
import ExpressOrdersCustomer from "./components/WareHouse/ExpressOrdersCustomer.jsx";
import ExpressOrdersByShop from "./components/WareHouse/ExpressOrdersByShop.js";
import OrderDetailsByShop from "./components/WareHouse/OrderDetailsByShop.js";
import ExpressOrderDetailsByShop from "./components/WareHouse/ExpressOrderDetailsByShop.js";
import ExpressOrdersDetailsByCustomer from "./components/WareHouse/ExpressOrdersDetailsByCustomer.js";
import WarehouseOrders from "./components/WareHouse/WarehouseOrders.js";
import WarehouseOrderDetails from "./components/WareHouse/WarehouseOrderDetails.js";
import ReadyForPickupOrders from "./components/WareHouse/ReadyForPickupOrders.js";
import OutForDeliveryOrders from "./components/WareHouse/OutForDeliveryOrders.js";
import NotDeliveredOrders from "./components/WareHouse/NotDeliveredOrders.js";
import AllOrders from "./components/WareHouse/AllOrders.js";
import orderMasterDetails from "./components/WareHouse/MasterOrderDetails.js";
import PendingMasterOrder from "./components/WareHouse/PendingMasterOrder.js";
import AcceptedMasterOrder from "./components/WareHouse/AcceptedMasterOrder.js";
import AcceptedAtStoreMasterOrder from "./components/WareHouse/AcceptedAtStoreMasterOrder.js";
import WrapMasterOrder from "./components/WareHouse/WrapMasterOrder.js";
import WrapWithAssignMasterOrder from "./components/WareHouse/WrapWithAssignMasterOrder.js";
import WrapUnAssignMasterOrder from "./components/WareHouse/WrapUnAssignMasterOrder.js";
import DeliveredMasterOrder from "./components/WareHouse/DeliveredMasterOrder.js";
import CancelledMasterOrder from "./components/WareHouse/CancelledMasterOrder.js";
import VendorAllOrder from "./components/WareHouse/VendorAllOrder.js";
import VendorPendingOrder from "./components/WareHouse/VendorPendingOrder.js";
import VendorAcceptedOrder from "./components/WareHouse/VendorAcceptedOrder.js";
import VedorReadyForPickUpOrder from "./components/WareHouse/VedorReadyForPickUpOrder.js";
import VendorAssignReadyForPickUpOrder from "./components/WareHouse/VendorAssignReadyForPickUpOrder.js";
import VendorUnassignReadyForPickupOrder from "./components/WareHouse/VendorUnassignReadyForPickupOrder.js";
import VendorRouteToWarehouseOrder from "./components/WareHouse/VendorRouteToWarehouseOrder.js";
import VendorReachAtWarehouseOrder from "./components/WareHouse/VendorReachAtWarehouseOrder.js";

class Routes extends Component {
  render() {
    return (
      <Router baseName={"/"}>
        {localStorage.getItem("loged_In") !== "true" ? (
          <Route exact path={"/"} component={Login} />
        ) : (
          <Home>
            <Route exact path={"/home"} component={Dashboard} />

            <Route exact path={"/user"} component={UserList} />
            <Route exact path={"/user/add/:user_id?"} component={UserAddTab} />
            <Route exact path={"/category"} component={CategoryList} />
            <Route exact path={"/category/add/:category_id?"} component={CategoryAddTab} />
            <Route exact path={"/delivery-charge/add/:charges_id?"} component={DeliveryChargesAddTab} />
            <Route exact path={"/delivery-charge"} component={DeliveryList} />
            <Route exact path={"/orders"} component={OrderList} />
            <Route exact path={"/order/details/:orderID?"} component={OrderDetails } />
            <Route exact path={"/product/Fixed"} component={ProductList} />
            <Route exact path={"/variantList/:ProductID?"} component={VariantLIST} />
            <Route exact path={"/variantDetails/:ProductID?"} component={VariantADD} />
            <Route exact path={"/products/Fixed/add/:product_id?"} component={ProductAddTab} />
            <Route exact path={"/product/Auction"} component={ProductList} />
            <Route exact path={"/product/AuctionCompleted"} component={AuctionList} />
            <Route exact path={"/product/AuctionParticipants/:postId?"} component={Winners} />
            <Route exact path={"/products/Auction/add/:product_id?"} component={ProductAddTab} />
            {/* <Route exact path={"/orders/auction"} component={OrderList} /> */}
            <Route exact path={"/seller"} component={SellerList} />
            <Route exact path={"/wallet"} component={WalletList} />
            <Route exact path={"/reportedPosts"} component={ReportedPOST} />
            <Route
              exact
              path={"/seller/add/:seller_id?"}
              component={SellerAddTab}
            />
            <Route exact path={"/attribute/Add"} component={ADDAttribute} />
            <Route exact path={"/attribute/All"} component={AttributeLIST} />
           
            <Route exact path={"/pushNotifications"} component={PushNotificationsList} />
            <Route exact path={"/sendNotifications"} component={NotificationSEND} />
            <Route
              exact
              path={"/delivery-time/add/:deliveryTime_id?"}
              component={DeliveryTimeAddTab}
            />
            <Route exact path={"/order-status"} component={OrderStatusList} />
            <Route
              exact
              path={"/order-status/add/:orderstatus_id?"}
              component={OrderStatusAddTab}
            />
            <Route exact path={"/package"} component={PackageList} />
            <Route
              exact
              path={"/package/add/:package_id?"}
              component={PackageAddTab}
            />

            <Route exact path={"/timeSlot"} component={TimeSLotList} /> 
            <Route exact path={"/timeSlot/add/:slotId?"} component={TimeSlotAdd} /> 

            <Route exact path={"/warehouse/orders"} component={WarehouseOrders} /> 
            <Route exact path={"/warehouse/orderDetails/:id?"} component={WarehouseOrderDetails} /> 

            <Route exact path={"/warehouse/allOrder"} component={AllOrders} />

            <Route exact path={"/warehouse/master-pending-order"} component={PendingMasterOrder} />

            <Route exact path={"/warehouse/master-accepted-order"} component={AcceptedMasterOrder} />

            <Route exact path={"/warehouse/master-accepted-at-store-order"} component={AcceptedAtStoreMasterOrder} />

            <Route exact path={"/warehouse/master-wrap-order"} component={WrapMasterOrder} />

            <Route exact path={"/warehouse/master-wrap-assign-order"} component={WrapWithAssignMasterOrder} />

            <Route exact path={"/warehouse/master-wrap-unassign-order"} component={WrapUnAssignMasterOrder} />

            <Route exact path={"/warehouse/master-delivered-order"} component={DeliveredMasterOrder} />

            <Route exact path={"/warehouse/master-cancelled-order"} component={CancelledMasterOrder} />

            <Route exact path={"/warehouse/vendor-all-order"} component={VendorAllOrder} />

            <Route exact path={"/warehouse/vendor-pending-order"} component={VendorPendingOrder} />

            <Route exact path={"/warehouse/vendor-ready-for-pickup-order"} component={VedorReadyForPickUpOrder} />

            <Route exact path={"/warehouse/vendor-assign-ready-for-pickup-order"} component={VendorAssignReadyForPickUpOrder} />

            <Route exact path={"/warehouse/vendor-unassign-ready-for-pickup-order"} component={VendorUnassignReadyForPickupOrder} />

            <Route exact path={"/warehouse/vendor-route-to-warehouse-order"} component={VendorRouteToWarehouseOrder} />

            <Route exact path={"/warehouse/vendor-at-store-order"} component={VendorReachAtWarehouseOrder} />

            <Route exact path={"/warehouse/vendor-accepted-order"} component={VendorAcceptedOrder} />

            <Route exact path={"/warehouse/orderMasterDetails/:id?"} component={orderMasterDetails} />

            <Route exact path={"/warehouse/orderByReadyForPickup"} component={ReadyForPickupOrders} />

            <Route exact path={"/warehouse/orderByOutForDelivery"} component={OutForDeliveryOrders} />

            <Route exact path={"/warehouse/notDeliveredOrder"} component={NotDeliveredOrders} />

            <Route exact path={"/warehouse/orderByCustomers"} component={OrdersByCustomers} /> 
            <Route exact path={"/warehouse/orderByCustomers/details/:id?"} component={OrderDetailsByCustomer} />

            <Route exact path={"/warehouse/orderByShop"} component={OrderByShop} /> 
            <Route exact path={"/warehouse/orderByShop/details/:date?/:id?"} component={OrderDetailsByShop } />

            <Route exact path={"/warehouse/express/orderByCustomers"} component={ExpressOrdersCustomer} /> 
            <Route exact path={"/warehouse/express/orderByCustomers/details/:id?"} component={ExpressOrdersDetailsByCustomer } />

            <Route exact path={"/warehouse/express/orderByShop"} component={ExpressOrdersByShop} /> 
            <Route exact path={"/warehouse/express/orderByShop/details/:date?/:id?"} component={ExpressOrderDetailsByShop } />

            <Route exact path={"/language"} component={LanguageList} />
            <Route
              exact
              path={"/language/add/:language_id?"}
              component={LanguageAdd}
            />
            <Route exact path={"/sell-type"} component={SellTypeList} />
            <Route
              exact
              path={"/sell-type/add/:seller_id?"}
              component={SellTypeAddTab}
            />

            <Route exact path={"/business-type"} component={BusinessList} />
            <Route
              exact
              path={"/business-type/add/:seller_id?"}
              component={BusinessAddTab}
            />
            <Route exact path={"/payment"} component={PaymentList} />
            <Route
              exact
              path={"/payment/add/:payment_id?"}
              component={PaymentAddTab}
            />
            <Route exact path={"/coupon"} component={CouponList} />
            <Route
              exact
              path={"/coupon/add/:coupon_id?"}
              component={CouponAddTab}
            />

            <Route exact path={"/role"} component={RoleList} />
            <Route exact path={"/role/add/:role_id?"} component={RoleAddTab} />
            <Route exact path={"/driver/list"} component={DriverList} />
            <Route
              exact
              path={"/driver/add"}
              component={DriverAddTab}
            />
            <Route
              exact
              path={"/driver/edit/:driver_id?"}
              component={DriverAddTab}
            />

            <Route exact path={"/country"} component={CountryList} />
            <Route
              exact
              path={"/country/add/:country_id?"}
              component={CountryAddTab}
            />

            <Route exact path={"/governorate"} component={GovernorateList} />
            <Route
              exact
              path={"/governorate/add/:governorate_id?"}
              component={GovernorateAddTab}
            />
            <Route exact path={"/city"} component={CityList} />
            <Route
              exact
              path={"/city/add/:city_id?"}
              component={CityAddTab}
            />
            <Route exact path={"/state"} component={StateList} />
            <Route exact path={"/state/add/:state_id?"} component={StateAddTab} />

            <Route exact path={"/terms"} component={TermsAddTab} />
            <Route exact path={"/about"} component={AboutUsAddTab} />
            <Route exact path={"/contact-us"} component={ContactDetails} />
            <Route exact path={"/privacyPolicy"} component={PirvacyPolicyAddTab} />
          </Home>
        )}
      </Router>
    );
  }
}

export default Routes;
