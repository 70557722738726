import React from 'react';
import { Link } from 'react-router-dom'
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import Toggle from 'react-toggle'
import { getProduct, deleteProduct } from '../../actions/homeAction'
import Constant from "../../Constant";
import axios from 'axios';

var config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("loged_In_auth"),
  },
};

class ProductList extends React.Component {
  state = {
    ProductList: [],
    shopName:'',
  }
  componentWillMount() {
    if (this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : false) {
      var data = {
        startRange: '0',
        count: "10000",
        type: "Fixed",
        all: "1"
      }
    }
    else {
      data = {
        startRange: 0,
        count: 20,
        type: "Auction",
        all: "1"
      }
    }
    this.props.getProduct(data);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ProductList: nextProps?.home?.ProductData })
  }

  componentDidUpdate(prevProps,prevState) {
    if (this.state.shopName !== prevState.shopName) {
      console.log("Do something");
      var Product = prevProps.home.ProductData
      var Filtered = Product.filter(data=>data.shop.shopName.toUpperCase().includes(this.state.shopName.toUpperCase()))
      console.log('Filtered',Filtered);
      this.setState({ProductList: Filtered})
    }
  }

  deleteProducts = (id) => {

    var data = {
      postId: id
    }
    this.props.deleteProduct(data)
  };

  handleStatusChange = (id)=> {
    var data = {
      PostId:id
    }
    axios.post(Constant.getAPI()+'/admin/togglepost',data, config).then(res=>{
      if(res.data.status){
        if (this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : false) {
          var data = {
            startRange: '0',
            count: "10000",
            type: "Fixed",
            all: "1"
          }
        }
        else {
          data = {
            startRange: 0,
            count: 20,
            type: "Auction",
            all: "1"
          }
        }
        this.props.getProduct(data);
      }
    })
  }

  render() {
    const columns = [
      {
        name: "Media",
        label: "Image",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (Media, tableMeta) => {

            return <img src={Media?.[0] !== undefined && Media?.[0] !== null && Media?.[0] !== {} ?
              Media?.[0]?.url !== undefined && Media?.[0]?.url !== null && Media?.[0]?.url !== "" ?
                Media?.[0]?.url : "./assets/images/icon.png" : "./assets/images/icon.png"}
              className="img-fluid img-20" alt="" onError={this.imgLoadError} />
            // return <div style={{display:'flex',gap:'0.5rem'}}>
            //   {Media!==[]&&Media!==undefined?Media.map(data=>(
            //     <img style={{height:'40px'}} src={data.url} alt="" />
            //   )):null}
            // </div>
          }
        }
      }, 
      // {
      //   name: "type",
      //   label: "Type",
      //   options: {
      //     filter: false,
      //     sort: true
      //   }
      // },
      {
        name: "title",
        label: "Title: EN",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "title_ar",
        label: "Title: AR",
        options: {
          filter: false,
          sort: true
        }
      },
      {
        name: "biddersCount",
        label: "Participants",
        options: {
          filter: true,
          sort: false,
          display:this.props?.location?.pathname?.includes("/product/Fixed")?false:true,
          customBodyRender: (biddersCount, tableMeta) => {
            var Id = tableMeta.rowData[12];
            // console.log(tableMeta.rowData[6]);
            return (
              <Link to={`/product/AuctionParticipants/${Id}`}
                className="m-r-15 text-muted"
                data-toggle="tooltip"
                data-placement="top" title=""
                data-original-title="Edit">
                <span className='ParticipantsColumn'><b>{biddersCount}</b><i className="f-20 icofont icofont-people"></i></span>
              </Link>
            );
          },
        },
      },
      {
        name: "Category",
        label: "Category Name: English",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (category, tableMeta) => {

            return <div>
              {category?.name}
            </div>
          }
        }
      },
      {
        name: "Category",
        label: "Category Name: Arabic",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (category, tableMeta) => {

            return <div>
              {category?.name_ar}
            </div>
          }
        }
      }, 
      {
        name: "shop",
        label: 'Shop Name',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (shop) => {

            return <div>
              {shop?.shopName}
            </div>
          }
        }
      },
      {
        name: "about",
        label: 'About',
        options: {
          filter: false,
          sort: true
        }
      }, {
        name: "quantity",
        label: 'Quantity',
        options: {
          filter: false,
          sort: true
        }
      },
      
      {
        name: "Variants",
        label: "Variants",
        options: {
          display:this.props?.location?.pathname === "/product/Fixed" ? true : false,
          filter: true,
          sort: false,
          customBodyRender: (Variants, tableMeta) => {
            var Id = tableMeta.rowData[12];
            return (
              <div>
                <Link
                  to={`/variantList/${Id}`}
                  className="m-r-15 text-muted"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                >
                  <i class="f-22 icofont icofont-eye text-dark"></i>
                </Link>
              </div>
            );
          },
        },
      }, {
        name: "createdAt",
        label: 'Date',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (createdAt, tableMeta) => {

            return <div>
              {moment(createdAt*1000).format('MM/DD/YYYY')}
            </div>
          }
        }
      }, {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
        }
      },
       {
        name: "id",
        label: "Action",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (id, tableMeta) => {
            return <div>

              <Link to={`/products/${this.props?.location?.pathname?.includes("/product/Fixed") ? "Fixed" : "Auction"}/add/` + id}
                className="m-r-15 text-muted"
                data-toggle="tooltip"
                data-placement="top" title=""
                data-original-title="Edit">
                <i className="f-20 icofont icofont-ui-edit text-custom"></i>
              </Link>
              {/* <span
                onClick={this.deleteProducts.bind(this, id)}
                className="m-r-15 text-muted"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title="Delete">
                <i className="f-20 icofont icofont-delete-alt text-danger"></i>  </span> */}
            </div>
          }
        }
      }
    ];
    const options = {
      filterType: "dropdown",
      viewColumns: false,
      print: false,
      download: false,
      selectableRows: 'none',
      textLabels: {
        body: {
          noMatch: this.state.isSaving ?
            "Loading data..!" :
            "Sorry, No Users Found",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        }
      }
    };

    console.log(this.props)
    return (
      <div className="pcoded-inner-content">
        <div className="main-body">
          <div className="page-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Product {this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"} List</h5>
                    {/* <Link to={`/products/${this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"}/add`}
                      className="btn btn-sm btn-inverse waves-effect waves-light f-right d-inline-block md-trigger" data-modal="modal-13"> <i className="icofont icofont-plus m-r-5"></i> Add Product
                    </Link> */}
                  </div>
                  <div className="card-block">
                    <div className='ShopName'>
                      <input placeholder='Enter Shop Name' type="text" onChange={(e)=>this.setState({shopName:e.target.value})} value={this.state.shopName}/>
                    </div>
                    <div className="dt-responsive table-responsive">
                      <MUIDataTable
                        title={`Product ${this.props?.location?.pathname === "/product/Fixed" ? "Fixed" : "Auction"} List`}
                        className="table-responsive"
                        data={this.state.ProductList}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProductList.propsTypes = {
  getProduct: PropTypes.func.isRequire,
  deleteProduct: PropTypes.func.isRequire,
  home: PropTypes.object.isRequired,
};

var mapStateToProps = (state) => ({
  home: state,
});

export default connect(mapStateToProps, { getProduct, deleteProduct })(ProductList);
